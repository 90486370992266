export default {
  tickets(state) {
    return state.tickets;
  },
  subcategories(state) {
    return state.subcategories;
  },
  loadedTicket(state) {
    return state.loadedTicket;
  },
  updateDatetime(state) {
    return state.updateDatetime;
  },
  ticketStatus(state) {
    const status = {
      textual: '',
      enum: 0
    }
    switch (state.loadedTicket.status) {
      case 'open':
        status.textual = 'Aperto';
        status.enum = 1;
        break;
      case 'progress':
        status.textual = 'In lavorazione';
        status.enum = 2;
        break;
      case 'closed':
        status.textual = 'Risolto';
        status.enum = 0;
        break;
    }
    return status;
  },
};