<template>
  <div class="hidden md:block">
    <nav
      class="flex items-center justify-between lg:justify-start"
      aria-label="Global"
    >
      <div class="flex pb-8 px-6 lg:px-10 mt-6 space-x-2">
        <router-link to="/services" class="menuButton" role="menuitem">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 mr-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"
            />
          </svg>
          Servizi
        </router-link>
        <router-link to="/payments/invoices" class="menuButton" role="menuitem">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 mr-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
              clip-rule="evenodd"
            />
          </svg>
          Pagamenti
        </router-link>
        <router-link to="/support" class="menuButton" role="menuitem">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 mr-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z"
              clip-rule="evenodd"
            />
          </svg>
          Supporto
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "DesktopMenu",
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
  methods: {
    hoverSupportLink() {
      this.isHover = !this.isHover;
    },
  },
};
</script>

<style scoped>
a:active,
a.router-link-active {
  @apply bg-gray-900;
  @apply text-gray-100;
}

.menuButton:hover:not(.router-link-active) {
  @apply bg-gray-200;
  @apply bg-opacity-70;
  @apply backdrop-blur;
  @apply text-gray-900;
}

.menuButton {
  @apply px-4;
  @apply py-2;
  @apply flex;
  @apply items-center;
  @apply rounded-md;
  @apply text-base;
  @apply font-normal;
  @apply text-gray-700;

  @apply bg-gray-100;
  @apply backdrop-blur;
}

.slide-top-blur-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-top-blur-leave-active {
  -webkit-animation: slide-top-blur 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both reverse;
  animation: slide-top-blur 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
    reverse;
}

@-webkit-keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
