<template>
  <div id="nav-icon1" @click="toggle" :class="isOpen ? 'open' : ''">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>
<script>
export default {
  name: "Menu2Times",
  props: ["isOpen"],
  emits: ["clicked"],
  methods: {
    toggle() {
      this.$emit("clicked");
    },
  },
};
</script>

<style scoped>
/* Icon 1 */

#nav-icon1 { 
  width: 60px;
  height: 54px;
  position: relative;
  border: 16px solid transparent;
  margin: 7px auto;
  right: -15px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: rgb(17, 24, 39);
  border: 1.5px solid rgba(17, 24, 39);
  border-radius: 100px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 10px;
}

#nav-icon1 span:nth-child(3) {
  top: 20px;
}

#nav-icon1.open span:nth-child(1) {
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  width: 0px;
}

#nav-icon1.open span:nth-child(3) {
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
</style>
