<template>
  <div>
    <base-dialog :show="!!error" :title="errorTitle" @close="handleError">
      <div v-html="error"></div>
    </base-dialog>
    <base-spinner v-if="isLoading"></base-spinner>
    <div
      class="
        min-h-screen
        flex
        items-center
        justify-center
        bg-gray-100
        py-12
        px-4
        sm:px-6
        lg:px-8
      "
    >
      <div class="max-w-md w-full space-y-8">
        <div :class="isLoading ? 'blur-scale-2' : ''">
          <img
            class="
              mx-auto
              h-16
              w-auto
              py-3
              px-1
              border-transparent
              rounded-2xl
              bg-gray-900
              shadow-xl
            "
            src="/assets/logos/nibble.svg"
            alt="logo"
          />
          <h2 class="mt-6 text-center text-2xl font-normal text-gray-900">
            Reimposta la password
          </h2>
          <p class="mt-2 text-center text-sm text-gray-600">
            O
            <router-link
              to="/"
              class="font-medium text-yellow-500 hover:text-yellow-400"
            >
              torna alla pagina di Accesso
            </router-link>
          </p>
        </div>
        <div v-if="!isSubmitted">
          <form class="mt-8 space-y-6" @submit.prevent="submitForm">
            <input type="hidden" name="remember" value="true" />
            <div
              :class="isLoading ? 'blur-scale-3' : ''"
              class="rounded-md shadow-sm -space-y-px"
            >
              <div>
                <label for="email-address" class="sr-only"
                  >Nuova Password</label
                >
                <input
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="password"
                  required
                  v-model.trim="password"
                  :class="
                    password.length < 6
                      ? 'focus:ring-red-500 focus:border-red-500'
                      : 'focus:ring-green-500 focus:border-green-500'
                  "
                  class="
                    appearance-none
                    relative
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    placeholder-gray-500
                    text-gray-900
                    rounded-t-md
                    focus:outline-none
                    focus:z-10
                    sm:text-sm
                  "
                  placeholder="Nuova Password"
                />
              </div>
              <div>
                <label for="email-address" class="sr-only"
                  >Conferma Password</label
                >
                <input
                  id="confirmedPassword"
                  name="confirmedPassword"
                  type="password"
                  autocomplete="confirmedPassword"
                  required
                  v-model.trim="confirmedPassword"
                  :class="
                    password != confirmedPassword
                      ? 'focus:ring-red-500 focus:border-red-500'
                      : 'focus:ring-green-500 focus:border-green-500'
                  "
                  class="
                    appearance-none
                    relative
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    placeholder-gray-500
                    text-gray-900
                    rounded-b-md
                    focus:outline-none
                    focus:z-10
                    sm:text-sm
                  "
                  placeholder="Conferma Password"
                />
              </div>
            </div>
            <p
              :class="isLoading ? 'blur-scale-3' : ''"
              class="text-sm text-gray-800"
            >
              La password deve contenere almeno 6 caratteri.
            </p>
            <div
              :class="isLoading ? 'blur-scale-3' : ''"
              v-if="!formIsValid"
              class="
                text-sm text-red-600
                border-l-4 border-red-500
                bg-red-100
                p-3
              "
            >
              La password deve contenere almeno 6 caratteri.
            </div>
            <div :class="isLoading ? 'blur-scale-4' : ''">
              <button
                type="submit"
                class="
                  group
                  relative
                  w-full
                  flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  text-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-yellow-500
                  hover:bg-yellow-700
                "
              >
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                  <!-- Heroicon name: lock-closed -->
                  <svg
                    class="h-5 w-5 text-yellow-400 group-hover:text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                Prosegui
              </button>
            </div>
          </form>
        </div>
        <div v-else class="text-center">
          <div>
            La password è stata reimpostata correttamente. <br />
            <router-link class="text-yellow-500" to="/">Clicca qui</router-link>
            per procedere al Login.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  props: ["token"],
  data() {
    return {
      password: "",
      confirmedPassword: "",
      formIsValid: true,
      isLoading: false,
      error: null,
      errorTitle: "An error occurred",
      isSubmitted: false,
    };
  },
  methods: {
    async submitForm() {
      this.formIsValid = true;
      if (
        this.password === "" ||
        this.password.length < 6 ||
        this.password != this.confirmedPassword
      ) {
        this.formIsValid = false;
        return;
      }

      this.isLoading = true;

      const actionPayload = {
        token: this.token,
        password: this.password,
        confirmedPassword: this.confirmedPassword,
      };

      try {
        // do Login
        await this.$store.dispatch("resetPassword", actionPayload);

        this.isSubmitted = true;
      } catch (err) {
        this.error = err.message || "Failed to authenticate, try later.";
        this.errorTitle = err.title || "An error occurred.";
      }

      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
.blur-scale-2 {
  opacity: 0.8;
  filter: blur(4px);
  transform: scale(0.9);
  transition: all 0.2s linear;
}
.blur-scale-3 {
  opacity: 0.8;
  filter: blur(4px);
  transform: scale(0.9);
  transition: all 0.3s linear;
}
.blur-scale-4 {
  opacity: 0.8;
  filter: blur(4px);
  transform: scale(0.9);
  transition: all 0.4s linear;
}
</style>
