<template>
    <simple-title title="Modifica il tuo metodo di pagamento" :keepTheTitle="true"></simple-title>
    <edit-payment-form></edit-payment-form>
</template>

<script>
import EditPaymentForm from "./../../../components/forms/EditPaymentForm";

export default {
    name: "EditPaymethod",
    components: { EditPaymentForm },
    mounted() {
        this.setBreadcrumbs();
    },
    methods: {
        setBreadcrumbs() {
            this.$store.dispatch("main/setBreadcrumbs", [
                { title: "Impostazioni", link: "/settings" },
                {
                    title: "Metodi di pagamento",
                    link: "/settings/payment-method",
                },
                {
                    title: "Modifica il tuo metodo",
                    link: "/settings/payment-method/edit",
                },
            ]);
        },
    },
};
</script>