<template>
  <div :class="[isLight ? 'lightSpinner' : 'darkSpinner', 'lds-ring-' + size]">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "MiniSpinner",
  props: {
    isLight: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "base",
    },
  },
};
</script>

<style scoped>
.lds-ring-base {
  display: flex;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring-base div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  /* margin: 4px; */
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.darkSpinner div {
  border: 2px solid rgb(156, 163, 175);
  border-color: rgb(156, 163, 175) transparent transparent transparent;
}
.lightSpinner div {
  border: 2px solid rgb(243, 244, 246);
  border-color: rgb(243, 244, 246) transparent transparent transparent;
}
.lds-ring-base div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-base div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-base div:nth-child(3) {
  animation-delay: -0.15s;
}

.lds-ring-sm {
  display: flex;
  position: relative;
  width: 15px;
  height: 15px;
}
.lds-ring-sm div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  /* margin: 4px; */
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.darkSpinner div {
  border: 1.5px solid rgb(156, 163, 175);
  border-color: rgb(156, 163, 175) transparent transparent transparent;
}
.lightSpinner div {
  border: 1.5px solid rgb(243, 244, 246);
  border-color: rgb(243, 244, 246) transparent transparent transparent;
}
.lds-ring-sm div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-sm div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-sm div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
