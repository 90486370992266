<template>
  <div>
    <h2 class="hidden md:block mb-1 text-3xl font-bold">I tuoi ticket</h2>
    <div class="md:hidden text-gray-800 sm:-mt-1 -mb-4 mx-auto text-md ">
      <div class="">
        Supporto
      </div>
    </div>
  </div>
</template>
