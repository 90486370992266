import TheHeader from './../../layout/TheHeader'
import TheFooter from './../../layout/TheFooter'
import FilesView from './../../views/files/FilesView'
import FilesList from './../../views/files/modules/FilesList'
import BaseFileUpload from './../../ui/BaseFileUpload'

export default [
    {
        path: '/files',
        name: 'files',
        components: {
            header: TheHeader,
            default: FilesView,
            footer: TheFooter
        },
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                redirect: '/files/list'
            },
            {
                path: 'list',
                component: FilesList
            },
            {
                path: 'upload',
                component: BaseFileUpload,
                meta: {
                    model: 'document',
                    title: 'Carica un documento'
                },
            }
        ]
    }
]