<template>
  <simple-title
    title="Aggiungi carta di credito o di debito"
    :keepTheTitle="true"
  ></simple-title>
  <new-card-form @back="goToPaymethodsPage()"></new-card-form>
</template>

<script>
import NewCardForm from "@/components/forms/NewCardForm";

export default {
  name: "NewCard",
  components: { NewCardForm },
  mounted() {
    this.setBreadcrumbs();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Impostazioni", link: "/settings" },
        {
          title: "Metodi di pagamento",
          link: "/settings/payment-method",
        },
        {
          title: "Aggiungi carta",
          link: "/settings/payment-method/newCard",
        },
      ]);
    },
    goToPaymethodsPage() {
      //   console.log("going back");
      this.$router.replace("/settings/payment-methods");
    },
  },
};
</script>
