<template>
  <div>
    <simple-title title="In Scadenza"></simple-title>

    <transition name="slide-top-blur-2">
      <div class="mt-5 md:mt-0" v-if="!isLoading">
        <div class="shadow sm:rounded-md sm:overflow-hidden">
          <div class="px-4 py-5 bg-white sm:p-6 flex flex-col" v-if="expiringServices.length > 0">
            <!-- main service riepilogue -->
            <div class="flex mb-3" v-for="service in expiringServices" :key="service.id">
              <div :class="service.subordinate == 0
        ? 'from-yellow-400 to-yellow-500'
        : 'from-gray-300 to-gray-400'
        " class="hidden sm:flex flex-shrink bg-gradient-to-r rounded-md mr-4">
                <i-phone :classes="service.subordinate == 0
        ? 'w-12 p-1 text-gray-900'
        : 'w-8 mx-2 p-1 text-gray-900'
        "></i-phone>
              </div>
              <div class="flex-grow flex flex-col justify-start">
                <div class="text-left text-gray-900">
                  <span class="uppercase font-bold text-md">
                    {{ service.item.name }}
                    <span v-if="service.subordinate != 0" class="lowercase font-normal">(servizio opzionale)</span>
                  </span>
                </div>
                <div class="text-gray-400 font-light">
                  {{ service.billing_frequency }} mesi -
                  {{ parseInvoiceNotes(service) }}
                </div>
              </div>
              <div class="flex-shrink text-gray-500 flex items-center">
                <span class="text-gray-900 text-lg">{{ price(service.item.price) }}</span>
              </div>
            </div>

            <!-- total riepilogue -->
            <div class="flex flex-col mt-8">
              <div class="flex flex-col mt-8">
                <div v-show="service.discount != 0"
                  class="flex-grow flex flex-row items-end border-t border-gray-400 pt-4">
                  <div class="text-lg flex-grow text-gray-600 ">
                    Subtotale
                  </div>
                  <div class="uppercase text-lg text-gray-600 ">
                    {{ granSubTotal }}
                  </div>
                </div>
                <div v-show="service.discount != 0" class="flex-grow flex flex-row items-end">
                  <div class="text-lg flex-grow text-gray-600 ">
                    Sconto
                  </div>
                  <div class="uppercase text-lg text-gray-600 ">
                    {{ discount }}
                  </div>
                </div>
                <div :class="{ 'border-t border-gray-400': service.discount == 0 }"
                  class="flex-grow flex flex-row items-end pt-4">
                  <div class="text-xl flex-grow text-gray-700 font-bold">
                    Totale
                  </div>
                  <div class="uppercase text-xl text-gray-900 font-bold">
                    {{ granTotal }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 py-5 bg-white sm:p-6 flex flex-col" v-else>
            Il servizio non è in scadenza questo mese.
          </div>
          <div class="px-4 py-3 bg-gray-50 sm:px-6 flex" v-if="expiringServices.length > 0">
            <!-- actions -->
            <div class="flex-grow flex justify-end">
              <base-button @click.prevent="loadCheckout()" label="Continua"></base-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "VoipExpiring",
  props: ["id"],
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    service() {
      return this.$store.getters["services/loadedService"];
    },
    services() {
      return this.$store.getters["services/services"];
    },
    client() {
      return this.$store.getters["user/loadedClient"];
    },
    vat() {
      return this.$gf.getVATbyCode(this.client.vat_code)['multiplier']
    },
    additionalServices() {
      return this.$store.getters["services/services"].filter((el) => {
        return el.main_id == this.id;
      });
    },
    additional() {
      return this.$store.getters["services/services"].filter((el) => {
        return el.main_id == this.id;
      }).length > 0
        ? true
        : false;
    },
    expiringServices() {
      let allServices = [this.service, ...this.additionalServices];
      return allServices.filter((el) => {
        return el.next_invoice_date == this.startOfNextMonth;
      });
    },
    startOfNextMonth() {
      return moment()
        .add(1, "M")
        .startOf("month")
        .format("YYYY-MM-DD");
    },
    subtotal() {
      let price = 0;

      // adding subservices price
      this.expiringServices.forEach((element) => {
        price += element.item.price * element.billing_frequency;
      });

      return price;
    },
    granSubTotal() {
      return this.price(this.subtotal);
    },

    discount() {
      if (this.service.discount == 0) return this.price(0);
      else {
        return this.price(
          parseFloat(this.subtotal) * (this.service.discount / 100)
        );
      }
    },
    granTotal() {
      if (this.service.discount == 0) return this.price(this.subtotal);
      else {
        return this.price(
          parseFloat(this.subtotal) - parseFloat(this.discount)
        );
      }
    },
  },
  mounted() {
    this.setBreadcrumbs();
    this.isLoading = false;
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Servizi", link: "/services" },
        {
          title: this.service.item.category.name,
          link: "/services/internet/" + this.id,
        },
        {
          title: "In scadenza",
          link: "/services/internet/" + this.id + "/expiring",
        },
      ]);
    },
    price(price) {
      return (this.vat * price).toFixed(2) + " €";
    },
    parseInvoiceNotes(service) {
      let notes = service.invoice_notes;
      if (notes) {
        notes = notes.replace(
          "[DATINI]",
          moment(service.next_invoice_date).format("DD/MM/YYYY")
        );
        notes = notes.replace(
          "[DATFIN]",
          moment(service.next_invoice_date)
            .add(service.billing_frequency, "M")
            .subtract(1, "days")
            .format("DD/MM/YYYY")
        );
        notes = notes.replace("[DISCOUNT]", service.discount.toString());
      }

      return notes;
    },
    loadCheckout() {
      try {
        this.$store.commit("payments/addToCart", {
          services: [...this.expiringServices],
        });
        this.$router.replace("checkout");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
};
</script>
