<template>
    <div class="flex flex-col h-screen justify-between">
        <header class="z-50 fixed top-0 w-full">
            <router-view name="header" class="relative"></router-view>
        </header>
        <main class="mb-auto mt-12 md:mt-52">
            <router-view name="default" v-slot="slotProps">
                <component :is="slotProps.Component"></component>
            </router-view>
        </main>
        <router-view name="footer"></router-view>
    </div>
</template>

<script>
export default {
    name: "App",
    computed: {
        didAutoLogout() {
            return this.$store.getters.didAutoLogout;
        },
    },
    created() {
        this.$store.dispatch("tryLogin");
    },
    watch: {
        didAutoLogout(curValue, oldValue) {
            if (curValue && curValue !== oldValue) {
                this.$router.replace("/login");
            }
        },
    },
};
</script>

<style>
body {
    @apply bg-gray-100;
}

.route-enter-from {
    opacity: 0;
}

.route-leave-to {
    opacity: 0;
}

.route-enter-active {
    transition: all 0.3s ease-out;
}

.router-leave-active {
    transition: all 0.3s ease-in;
}

.route-enter-to .route-leave-from {
    opacity: 1;
}
</style>