<template>
  <div>
    <div
      class="relative px-4 sm:px-6 lg:px-8 md:hidden shadow mb-1 border-b border-gray-700 bg-gray-800"
    >
      <nav
        class="relative flex items-center justify-between lg:justify-start"
        aria-label="Global"
      >
        <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
          <div
            class="flex items-center justify-between w-full md:w-auto text-gray-400"
          >
            <div class="flex">
              <router-link to="/"
                ><img class="h-6 w-auto sm:h-10" src="/assets/logos/nibble.svg"
              /></router-link>

              <div class="hidden sm:block text-2xl ml-2">
                <span
                  class="font-bold text-gradient bg-gradient-to-r from-yellow-500 via-gray-500 via-gray-600 to-gray-900 "
                  >myNibble</span
                >
              </div>
            </div>
            <div class="flex space-x-2">
              <div>
                <mobile-profile-button></mobile-profile-button>
              </div>

              <router-link to="/settings">
                <div class="px-2 py-3 text-lg">
                  <!-- myNibble -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    class="h-7 w-7"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </div>
              </router-link>
              <router-link to="/payments/checkout">
                <div class="px-2 py-3 text-lg">
                  <!-- myNibble -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-7 w-7"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                </div>
                <div
                  v-if="
                    cart &&
                      ((cart.services && cart.services.length > 0) ||
                        (cart.items && cart.items.length > 0))
                  "
                  class="z-40 absolute top-2.5 right-1.5 rounded-full bg-red-500 bg-opacity-70 w-3 h-3 "
                ></div>
              </router-link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import MobileProfileButton from "@/ui/buttons/MobileProfileButton";
export default {
  name: "MobileMenu",
  components: { MobileProfileButton },
  computed: {
    cart() {
      return this.$store.getters["payments/cart"];
    },
  },
};
</script>

<style scoped>
a:active,
a.router-link-active {
  @apply text-yellow-500;
}

@layer utilities {
  .text-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
