<template>
  <base-dialog
    :show="!!alertMessage"
    title="Esci"
    @close="confirmLogout"
    @cancel="this.alertMessage = null"
  >
    <div v-html="alertMessage"></div>
  </base-dialog>

  <div
    v-if="isLoading"
    class="relative z-50 inline-block text-left items-center"
  >
    <div>
      <skeleton-profile-button></skeleton-profile-button>
    </div>
  </div>
  <div
    v-else
    class="relative z-50 inline-block text-left items-center"
    @mouseenter="toggleMenu"
    @mouseleave="toggleMenu"
  >
    <div>
      <button
        type="button"
        :class="isOpen ? 'bg-gray-50 bg-opacity-25' : ''"
        class="flex items-center w-full rounded-tr-3xl rounded-br-3xl rounded-tl-3xl rounded-bl-3xl border-0 shadow-sm pl-3 font-medium text-gray-200 hover:bg-gray-50 hover:bg-opacity-25 focus:bg-gray-50"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <svg
          class="text-gray-400 h-5 w-5 mr-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
        <span class="text-gray-400 mr-2"> {{ loadedClient.heading }} </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-auto sm:h-10 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>

    <div
      style="z-index: 100000"
      class="origin-top-right absolute  right-0 pt-2"
    >
      <div
        v-show="isOpen"
        class="w-64 rounded-md shadow-lg bg-gray-100 divide-y divide-gray-300 p-0.5"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <div v-if="user && user.clients && user.clients.length > 0">
          <div class="m-2 flex flex-col">
            <div class="block px-2 py-2 text-sm text-gray-500">
              <p v-if="user.clients.length > 1">Cambia profilo:</p>
              <p v-else>Profilo:</p>
            </div>

            <div
              v-for="(client, index) in user.clients"
              :key="index"
              @click="setClient(client.id)"
              class="flex items-center justify-between px-2 py-2 rounded-lg hover:bg-gray-200 cursor-pointer"
            >
              <div class="flex space-x-2 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clip-rule="evenodd"
                  />
                </svg>
                <div class="text-sm text-gray-900 ">
                  {{ client.heading }}
                </div>
              </div>
              <svg
                v-if="loadedClient.id == client.id"
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
        <div>
          <div class="m-2">
            <router-link to="/settings/profile">
              <a
                href="#"
                class="block px-4 py-2 text-sm rounded-lg text-gray-900 hover:bg-gray-200"
                role="menuitem"
                >I tuoi dati</a
              >
            </router-link>
            <router-link to="/settings/edit-password">
              <a
                href="#"
                class="block px-4 py-2 text-sm rounded-lg text-gray-900 hover:bg-gray-200"
                role="menuitem"
                >Modifica password</a
              >
            </router-link>
            <router-link to="/settings/payment-methods">
              <a
                href="#"
                class="block px-4 py-2 text-sm rounded-lg text-gray-900 hover:bg-gray-200"
                role="menuitem"
                >Metodi di pagamento</a
              >
            </router-link>
          </div>
        </div>
        <div>
          <div class="m-2">
            <router-link to="/settings">
              <a
                href="#"
                class="block px-4 py-2 text-sm rounded-lg text-gray-900 hover:bg-gray-200"
                role="menuitem"
                >Impostazioni</a
              >
            </router-link>
          </div>
        </div>
        <div>
          <div class="m-2">
            <router-link to="/files" class="">
              <a
                href="#"
                class="flex items-center text-md px-4 py-2 text-sm rounded-lg text-gray-600 hover:bg-gray-200"
                role="menuitem"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="w-8 mr-1"
                >
                  <path
                    d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                  /></svg
                >Documenti e contratti</a
              >
            </router-link>
          </div>
        </div>
        <div>
          <div class="m-2">
            <a
              href="#"
              @click.prevent="logout()"
              class="block px-4 py-2 text-sm rounded-lg text-gray-900 hover:bg-gray-200"
              role="menuitem"
              >Esci</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonProfileButton from "./../skeleton/profile/SkeletonProfileButton";
export default {
  name: "ProfileButton",
  components: { SkeletonProfileButton },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      alertMessage: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    loadedClient() {
      return this.$store.getters["user/loadedClient"];
    },
  },
  async mounted() {
    if (this.loadedClient.heading == undefined) {
      await this.setClient(false);
    }
    if (this.user.name == undefined) {
      await this.loadUser();
    }
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    logout() {
      this.alertMessage = "Sei sicuro di voler uscire?";
    },
    async confirmLogout() {
      this.alertMessage = null;
      await this.$store.dispatch("logout");
      this.$router.replace("/login");
    },
    async loadUser() {
      this.isLoading = true;
      await this.$store.dispatch("user/getUser");
      this.isLoading = false;
    },
    async setClient(id) {
      this.isLoading = true;
      await this.$store.dispatch("user/setClient", { clientId: id });
      this.isOpen = false;
      this.isLoading = false;
    },
  },
};
</script>
