<template>
  <transition name="slide-top-blur">
    <div v-show="!isLoading">
      <div class="grid">
        <div class="mt-5 md:mt-0">
          <form @submit.prevent="submit()">
            <div class="shadow sm:rounded-md sm:overflow-hidden">
              <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div class="">
                  <div class="">
                    <label
                      for="cardholder"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Nome e cognome intestatario
                    </label>
                    <div class="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="cardholder"
                        required="required"
                        v-model.trim="cardholder"
                        id="cardholder"
                        class="focus:ring-yellow-500 focus:border-yellow-500 flex-1 block w-full rounded-none text-sm rounded-md sm:text-sm border-gray-300"
                        placeholder="Mario Rossi"
                      />
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="">
                    <label
                      for="card-element"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Dati carta
                    </label>
                    <div class="mt-1 flex rounded-md shadow-sm">
                      <div id="card-element"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex px-4 py-3 bg-gray-50 justify-between sm:px-6">
                <base-button
                  @click="cancel()"
                  label="Annulla"
                  :isLight="true"
                ></base-button>
                <base-button
                  label="Aggiungi carta"
                  v-if="!submitted"
                  type="submit"
                ></base-button>
                <base-spinner-button v-else></base-spinner-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
  <div v-if="isLoading" class="flex items-center gap-2 p-4 sm:p-3 md:p-2 lg: p-1">
    <base-spinner-mini></base-spinner-mini> Carico
  </div>
</template>
<script>
import { loadStripe } from "@stripe/stripe-js";
export default {
  data() {
    return {
      isLoading: true,
      error: null,
      success: false,
      submitted: false,
      stripe: {},
      cardElement: {},
      cardholder: "",
    };
  },
  emits: ["back"],
  computed: {
    client_secret() {
      return this.$store.getters["settings/newCardSetupIntent"].client_secret;
    },
  },
  async mounted() {
    await this.loadElement();
    await this.setupIntent();
    this.isLoading = false;
  },
  methods: {
    async loadElement() {
      this.stripe = await loadStripe("pk_live_orvuU9fj9XS9Gbzge9RIZVCI");
      const elements = this.stripe.elements();
      this.cardElement = elements.create("card", {
        classes: {
          base:
            "bg-gray-100 w-full rounded text-base outline-none text-gray-700 p-3 leading-8 transition-colors duration-200 ease-in-out",
        },
      });
      this.cardElement.mount("#card-element");
    },
    async setupIntent() {
      this.success = false;
      this.error = null;
      try {
        await this.$store.dispatch("settings/newCardSetupIntent");
        this.success = true;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async submit() {
      this.submitted = true;
      const { setupIntent, error } = await this.stripe.handleCardSetup(
        this.client_secret,
        this.cardElement,
        {
          payment_method_data: {
            billing_details: { name: this.cardholder },
          },
        }
      );
      if (error) {
        // console.log(error);
        this.submitted = false;
      } else {
        await this.storePaymentMethod(setupIntent.payment_method);
        this.$emit("back");
      }
    },
    async storePaymentMethod(payment_method) {
      try {
        await this.$store.dispatch("settings/storePaymentMethod", {
          payment_method: payment_method,
        });
        this.submitted = false;
        // this.$router.replace("/settings/payment-methods");
      } catch (error) {
        this.submitted = false;
        this.error = error.message || "Something went wrong!";
      }
    },
    cancel() {
      this.$emit("back");
    },
  },
};
</script>

<style scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffffff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.slide-top-blur-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-bottom-scale-blur-enter-active {
  -webkit-animation: slide-bottom-scale-blur 0.3s ease-in-out both;
  animation: slide-bottom-scale-blur 0.3s ease-in-out both;
}
.slide-bottom-scale-blur-leave-active {
  -webkit-animation: slide-bottom-scale-blur 0.1s ease-in-out both reverse;
  animation: slide-bottom-scale-blur 0.1s ease-in-out both reverse;
}

@-webkit-keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slide-bottom-scale-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-bottom-scale-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
