import moment from "moment";
// import icons from "./iconset.js"
import { vat_codes, paymethods } from "./constants.js";

export default {

    isURL(str) {
        const urlRegex = /^(?:https?:\/\/)?(?:www\.)?[\w-]+(?:\.[\w-]+)+[\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-]$/;
        return urlRegex.test(str);
    },

    addHttpsToUrl(url) {
        if (!url.startsWith('https://') && !url.startsWith('http://'))
            url = 'https://' + url;
        return url;
    },

    convertSecondsToDuration(seconds) {
        const duration = moment.duration(seconds, 'seconds');

        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();

        let durationString = '';

        if (days > 0) durationString += `${days}d `;
        if (hours > 0 || days > 0) durationString += `${hours}h `;
        durationString += `${minutes}m`;

        return durationString.trim();
    },

    formatPrice(number, decimalDigits = Math.max(2, Math.min(4, (number.toString().split('.')[1] || '').length))) {
        return number.toFixed(decimalDigits);
    },

    formatDate(inputDate, outputFormat, inputFormat = "") {
        return inputFormat !== "" ?
            moment(inputDate, inputFormat).format(outputFormat)
            : moment(inputDate).format(outputFormat);
    },

    sameDates(data1, data2) {
        const moment1 = moment(data1, moment.ISO_8601, true);
        const moment2 = moment(data2, moment.ISO_8601, true);

        if (!moment1.isValid() || !moment2.isValid()) {
            return false; // Formato non riconosciuto o data non valida
        }

        return moment1.isSame(moment2);
    },

    // icons() { return icons },

    getVATbyCode(code) {
        return vat_codes[code];
    },

    getPaymethod(meth) {
        return paymethods[meth]
    },

    async sha256(payload) {
        const msgBuffer = new TextEncoder().encode(payload);
        const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
        return hashHex;
    },

    async openFileInNewTab(file) {
        const hash = await this.sha256(file.path);
        window.open(`https://api.${process.env.VUE_APP_BASE_DOMAIN}/admin/storage/${file.id}?hash=${hash}`);
    },
}