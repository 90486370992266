<template>
  <div v-if="isLoaded" @click.prevent="loadTicket()" class="cursor-pointer">
    <!-- Desktop view -->
    <div
      :class="isUpdating ? 'magic-shadow-back' : 'magic-shadow'"
      class="flex flex-row p-2 pr-0 sm:pr-4 sm:p-4 rounded-md bg-gradient-to-r from-white to-gray-50 hover:from-gray-50 hover:to-white"
    >
      <div class="flex-grow text-left">
        <div class="flex flex-col">
          <div class="text-xs sm:text-md text-gray-400 pb-1">
            <span class="mr-1">
              {{ subcategory.name }}
            </span>
          </div>
          <div class="text-md sm:text-lg text-gray-800">
            <span class="mr-1">
              {{ ticket.subject }}
            </span>
          </div>
          <div class="flex flex-row items-center text-sm text-gray-700 pb-2">
            <div class="flex mr-1 font-bold">
              <div v-if="isLastMessageFromMe()">Tu:</div>
              <div v-else class="flex items-center">
                <div
                  v-if="isLastMessageUnread()"
                  class="rounded-3xl bg-blue-400 bg-opacity-75 text-gray-50 h-3 w-3 mr-1"
                ></div>
                <span> {{ ticket.client.heading }}: </span>
              </div>
            </div>
            <div class="grid">
              <p class="truncate mr-1">
                {{ ticket.messages[0].text }}
              </p>
            </div>
          </div>
          <div class="flex items-center text-gray-400 text-sm">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                class="w-4 text-gray-300"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div>
              <span class="text-xs ml-1"> Aperto il {{ formattedDate }} </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row items-center">
        <div class="hidden sm:flex items-center text-center mx-10">
          <base-tag-status :status="ticket.status"></base-tag-status>
        </div>
        <div class="flex-shrink text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="w-10 ml-2 sm:w-16"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </div>
    </div>

    <!-- Mobile view -->
    <!-- <div
            class="sm:hidden flex justify-between pl-1 bg-gray-200 rounded-lg shadow-md hover:shadow-lg bg-gradient-to-r hover:from-gray-200 hover:to-gray-300"
        >
            <div class="flex-grow flex items-center">
                <div class="flex-grow">
                    <div class="grid grid-cols-1 text-gray-400 mx-1 text-left px-1 py-1">
                        <span class="text-md font-bold text-gray-400 uppercase mr-1 pt-1">
                            {{ ticket.item.category.name }}
                        </span>
                        <span class="text-xl font-medium text-gray-800 capitalize mr-1 pt-1">
                            {{ ticket.item.name.toLowerCase() }}
                        </span>
                    </div>
                </div>
                <div class="flex-grow-0">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="text-gray-400 w-8 sm:w-12"
                        fill="currentColor"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </div>
            </div>
            
        </div> -->
  </div>
</template>

<script>
import moment from "moment";
import BaseTagStatus from "./BaseTagStatus";

export default {
  name: "BaseTicket",
  components: { BaseTagStatus },
  props: {
    ticket: {},
    subcategory: {},
    isUpdating: Boolean,
  },
  computed: {
    ticketDetailsLink() {
      return this.$route.path + "/" + this.ticket.id;
    },
    formattedDate() {
      return moment(this.ticket.created_at).format("DD/MM/YYYY");
    },
    loadedClient() {
      return this.$store.getters["user/loadedClient"];
    },
    isLoaded() {
      if (this.ticket && this.ticket.messages[0]) return true;
      else return false;
    },
  },
  methods: {
    isLastMessageFromMe() {
      if (
        this.ticket &&
        this.ticket.messages[0] &&
        this.ticket.messages[0].client_id == this.loadedClient.id
      )
        return true;
      else return false;
    },
    isLastMessageUnread() {
      if (
        this.ticket &&
        this.ticket.messages[0] &&
        this.ticket.messages[0].status == "unread"
      )
        return true;
      else return false;
    },
    async loadTicket() {
      await this.$store.commit("tickets/setLoadedTicket", {
        ticket: this.ticket,
      });

      this.$router.replace("/support/tickets/" + this.ticket.id);
    },
  },
};
</script>

<style scoped>
.magic-shadow {
  @apply shadow-lg;
  transition: 0.4s ease-out;
}

.magic-shadow-back {
  @apply shadow-sm;
  transition: 0.2s ease-in;
}
</style>
