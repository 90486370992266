<template>
  <div>
    <simple-title title="Riepilogo del servizio" :isUpdating="isUpdating"></simple-title>
    <transition name="slide-top-blur-2">
      <div v-if="show"
        class="flex flex-col space-y-4 divide-y divide-gray-200 bg-gray-50 shadow-xl rounded-md px-4 py-3 mt-3 mb-8">
        <riepilogue-table-row title="Stato del contratto" :value="status.textual">
          <path v-if="status.enum == 1" fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"></path>
          <path v-if="status.enum == 0" fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"></path>
        </riepilogue-table-row>
        <riepilogue-table-row title="Indirizzo installazione" :value="address">
          <path fill-rule="evenodd"
            d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
            clip-rule="evenodd" />
        </riepilogue-table-row>
        <riepilogue-table-row title="Stipulato in data" :value="stipulation_date">
          <path fill-rule="evenodd"
            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            clip-rule="evenodd"></path>
        </riepilogue-table-row>
        <riepilogue-table-row v-if="!isCrono" title="Scadenza vincolo" :value="bond_expiration">
          <path fill-rule="evenodd"
            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            clip-rule="evenodd"></path>
        </riepilogue-table-row>
      </div>
    </transition>
    <div :class="isCrono ? '' : 'lg:grid-cols-3 gap-8'" class="grid">
      <transition name="slide-top-blur-3">
        <div v-if="show" class="lg:col-span-2">
          <div class="flex flex-col space-y-4 divide-y divide-gray-200 bg-gray-50 shadow-xl rounded-md px-4 py-3">
            <riepilogue-table-row title="Piano" :value="service.item.name.toLowerCase()" valueClass="capitalize">
              <path
                d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
            </riepilogue-table-row>

            <riepilogue-table-row v-if="!isCrono" title="Periodo di fatturazione" :value="billing_frequency.textual">
              <path fill-rule="evenodd"
                d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                clip-rule="evenodd" />
            </riepilogue-table-row>

            <riepilogue-table-row v-if="!isCrono" title="Prossimo pagamento" :value="next_invoice">
              <path fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd"></path>
            </riepilogue-table-row>
          </div>
        </div>
      </transition>
      <transition name="slide-top-blur-4">
        <div v-if="show && !isCrono" class="lg:col-span-1">
          <div class="flex flex-col space-y-1 bg-gray-900 shadow-xl rounded-md px-4 py-3">
            <riepilogue-table-row title="DOWNLOAD" :value="downrate" :isSmall="true" :isDark="true">
              <path fill-rule="evenodd"
                d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clip-rule="evenodd" />
            </riepilogue-table-row>
            <riepilogue-table-row title="UPLOAD" :value="uprate" :isSmall="true" :isDark="true">
              <path fill-rule="evenodd"
                d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                clip-rule="evenodd" />
            </riepilogue-table-row>
            <div class="text-2xl pt-3">
              <div class="flex">
                <div class="flex-1 text-left text-gray-200">
                  Costo
                </div>
                <div class="flex-1 text-right text-gray-400">
                  <div class="flex flex-col justify-end">
                    <div>{{ price }}</div>
                    <div class="text-sm">al mese</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RiepilogueTableRow from "./../../../../../ui/services/RiepilogueTableRow";

export default {
  name: "InternetRiepilogue",
  props: ["id"],
  components: {
    RiepilogueTableRow,
  },
  data() {
    return {
      show: false,
      isUpdating: true,
    };
  },
  computed: {
    service() {
      return this.$store.getters["services/loadedService"];
    },
    client() {
      return this.$store.getters["user/loadedClient"];
    },
    status() {
      return this.$store.getters["services/serviceStatus"];
    },
    address() {
      return this.$store.getters["services/serviceAddress"];
    },
    stipulation_date() {
      return moment(this.service.stipulation_date).format("DD/MM/YYYY");
    },
    bond_expiration() {
      return moment(this.service.bond_expiration_date).format("DD/MM/YYYY");
    },
    billing_frequency() {
      return this.$store.getters["services/serviceBillingFrequency"];
    },
    next_invoice() {
      return moment(this.service.next_invoice_date).format("DD/MM/YYYY");
    },
    price() {
      return (this.$gf.getVATbyCode(this.client.vat_code)['multiplier'] * this.service.item.price).toFixed(2) + " €";
    },
    downrate() {
      return this.rateInMbps(this.service.item.details.downrate) + " Mbps";
    },
    uprate() {
      return this.rateInMbps(this.service.item.details.uprate) + " Mbps";
    },
    hasService() {
      return !!this.$store.getters["services/loadedService"];
    },
    isCrono() {
      return this.service.item.code == "CRONO" ? true : false;
    },
  },
  created() {
    if (this.hasService) {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Servizi", link: "/services" },
        {
          title: this.service.item.category.name,
          link: "/services/internet/" + this.service.id,
        },
        {
          title: "Riepilogo",
          link: "/services/internet/" + this.service.id + "/riepilogue",
        },
      ]);
    }
  },
  async mounted() {
    this.show = true;
    await this.loadService();
  },
  unmounted() {
    this.show = !this.show; // might need this.$nextTick
  },
  methods: {
    rateInMbps(entry) {
      return entry / (1024 * 1024);
    },
    async loadService() {
      try {
        await this.$store.dispatch("services/getService", {
          id: this.id,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isUpdating = false;
    },
  },
};
</script>
