<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :class="classes"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
  </svg>
</template>

<script>
export default {
  name: "Bookmark",
  props: ["classes"],
};
</script>
