import TheHeader from '@/layout/TheHeader'
import TheFooter from '@/layout/TheFooter'
import BlankView from '@/views/misc/BlankView'

import ServicesList from '@/views/services/ServicesList'
import ServiceAdditional from '@/views/services/modules/ServiceAdditional'
import ServiceTicketList from '@/views/services/modules/ServiceTicketList'

// Internet Service View and Pills
import InternetService from '@/views/services/modules/internet/InternetService'
import InternetRiepilogue from '@/views/services/modules/internet/pills/InternetRiepilogue'
import InternetExpiring from '@/views/services/modules/internet/pills/InternetExpiring'
import InternetPurchase from '@/views/services/modules/internet/pills/InternetPurchase'
import InternetOrders from '@/views/services/modules/internet/pills/InternetOrders'
import InternetCheckout from '@/views/services/modules/internet/InternetCheckout'
import InternetTicket from '@/views/services/modules/internet/pills/InternetTicket'

// Voip Service View and Pills
import VoipService from '@/views/services/modules/voip/VoipService'
import VoipRiepilogue from '@/views/services/modules/voip/pills/VoipRiepilogue'
import VoipExpiring from '@/views/services/modules/voip/pills/VoipExpiring'
import VoipRecharge from '@/views/services/modules/voip/pills/VoipRecharge'
import VoipOrders from '@/views/services/modules/voip/pills/VoipOrders'
import VoipCheckout from '@/views/services/modules/voip/VoipCheckout'
import VoipTicket from '@/views/services/modules/voip/pills/VoipTicket'
import VoipRegistry from '@/views/services/modules/voip/pills/VoipRegistry'

// Services Titles
import MainTitle from '@/views/services/pageTitles/MainTitle'
import RiepilogueSecondaryTitle from '@/views/services/pageTitles/RiepilogueSecondaryTitle'
import ExpiringSecondaryTitle from '@/views/services/pageTitles/ExpiringSecondaryTitle'
import PurchaseSecondaryTitle from '@/views/services/pageTitles/PurchaseSecondaryTitle'
import OrdersSecondaryTitle from '@/views/services/pageTitles/OrdersSecondaryTitle'
import RechargeSecondaryTitle from '@/views/services/pageTitles/RechargeSecondaryTitle'
import CheckoutSecondaryTitle from '@/views/services/pageTitles/CheckoutSecondaryTitle'
import AdditionalSecondaryTitle from '@/views/services/pageTitles/AdditionalSecondaryTitle'
import NewTicketSecondaryTitle from '@/views/services/pageTitles/NewTicketSecondaryTitle'
import TicketListSecondaryTitle from '@/views/services/pageTitles/TicketListSecondaryTitle'
import RegistrySecondaryTitle from '@/views/services/pageTitles/RegistrySecondaryTitle'


export default [
    {
        path: '/services',
        name: 'services',
        components: {
            header: TheHeader,
            default: BlankView,
            footer: TheFooter
        },
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'allServices',
                component: ServicesList
            },
            {
                path: 'internet/:id',
                props: true,
                name: 'internet-service',
                component: InternetService,
                children: [
                    {
                        path: '',
                        name: 'internet-service-default',
                        redirect: { 'name': 'internet-service-riepilogue' }
                    },
                    {
                        path: 'riepilogue',
                        props: true,
                        name: 'internet-service-riepilogue',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: RiepilogueSecondaryTitle,
                            default: InternetRiepilogue
                        }
                    },
                    {
                        path: 'expiring',
                        props: true,
                        name: 'internet-service-expiring',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: ExpiringSecondaryTitle,
                            default: InternetExpiring
                        }
                    },
                    {
                        path: 'purchase',
                        props: true,
                        name: 'internet-service-purchase',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: PurchaseSecondaryTitle,
                            default: InternetPurchase
                        }
                    },
                    {
                        path: 'orders',
                        props: true,
                        name: 'internet-service-orders',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: OrdersSecondaryTitle,
                            default: InternetOrders
                        }
                    },
                    {
                        path: 'checkout',
                        props: true,
                        name: 'internet-service-checkout',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: CheckoutSecondaryTitle,
                            default: InternetCheckout
                        }
                    },
                    {
                        path: 'additional',
                        props: true,
                        name: 'internet-service-additional',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: AdditionalSecondaryTitle,
                            default: ServiceAdditional
                        }
                    },
                    {
                        path: 'newTicket',
                        name: 'internet-service-newTicket',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: NewTicketSecondaryTitle,
                            default: InternetTicket
                        }
                    },
                    {
                        path: 'ticketList',
                        name: 'internet-service-tickets',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: TicketListSecondaryTitle,
                            default: ServiceTicketList
                        }
                    },
                ]
            },
            {
                path: 'voip/:id',
                props: true,
                name: 'voip',
                component: VoipService,
                children: [
                    {
                        path: '',
                        name: 'voip-service-default',
                        redirect: { 'name': 'voip-service-riepilogue' }
                    },
                    {
                        path: 'riepilogue',
                        props: true,
                        name: 'voip-service-riepilogue',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: RiepilogueSecondaryTitle,
                            default: VoipRiepilogue
                        }
                    },
                    {
                        path: 'expiring',
                        props: true,
                        name: 'voip-service-expiring',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: ExpiringSecondaryTitle,
                            default: VoipExpiring
                        }
                    },
                    {
                        path: 'recharge',
                        name: 'voip-service-recharge',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: RechargeSecondaryTitle,
                            default: VoipRecharge
                        }
                    },
                    {
                        path: 'orders',
                        props: true,
                        name: 'voip-service-orders',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: OrdersSecondaryTitle,
                            default: VoipOrders
                        }
                    },
                    {
                        path: 'checkout',
                        props: true,
                        name: 'voip-service-checkout',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: CheckoutSecondaryTitle,
                            default: VoipCheckout
                        }
                    },
                    {
                        path: 'additional',
                        props: true,
                        name: 'voip-service-additional',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: AdditionalSecondaryTitle,
                            default: ServiceAdditional
                        }
                    },
                    {
                        path: 'newTicket',
                        name: 'voip-service-newTicket',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: NewTicketSecondaryTitle,
                            default: VoipTicket
                        }
                    },
                    {
                        path: 'ticketList',
                        name: 'voip-service-tickets',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: TicketListSecondaryTitle,
                            default: ServiceTicketList
                        }
                    },
                    {
                        path: 'registry',
                        name: 'voip-service-registry',
                        components: {
                            pageTitle: MainTitle,
                            secondaryTitle: RegistrySecondaryTitle,
                            default: VoipRegistry
                        }
                    }
                ]
            }
        ]
    },
]