<template>
  <div>
    <div class="md:grid md:gap-6">
      <div class="mt-5 md:mt-0">
        <form
          @submit.prevent="submitForm"
          @reset.prevent="resetForm"
          enctype="multipart/form-data"
        >
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
              <slot></slot>

              <div class="grid grid-cols-3 gap-6">
                <div class="col-span-3 sm:col-span-2">
                  <label
                    for="company_website"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Nome del file
                  </label>
                  <div class="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      v-model="filename"
                      name="company_website"
                      id="company_website"
                      class="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                      placeholder="Carica un documento"
                    />
                  </div>
                </div>
              </div>

              <div>
                <label
                  for="about"
                  class="block text-sm font-medium text-gray-700"
                >
                  Descrizione
                </label>
                <div class="mt-1">
                  <textarea
                    id="about"
                    v-model="description"
                    name="about"
                    rows="3"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Contratto firmato, Documento d'identità, Fotocopia documento, ecc..."
                  ></textarea>
                </div>
                <p class="mt-2 text-sm text-gray-500">
                  Breve descrizione del documento. O motivo del caricamento.
                </p>
              </div>

              <div>
                <div
                  :class="filelist.length > 0 ? 'bg-gray-50' : ''"
                  class="w-full items-center justify-center text-center mt-2 flex px-6 pt-5 pb-6 border-2 border-dashed rounded-md"
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop"
                >
                  <input
                    type="file"
                    multiple
                    name="fields[assetsFieldHandle][]"
                    id="assetsFieldHandle"
                    class="w-px h-px opacity-0 overflow-hidden absolute"
                    @change="onChange"
                    accept="image/*,.pdf"
                  />

                  <div
                    class="space-y-1 text-center"
                    v-if="filelist.length == 0"
                  >
                    <svg
                      class="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div class="flex text-sm text-gray-600">
                      <label
                        for="assetsFieldHandle"
                        class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span>Upload a file</span>
                      </label>
                      <p class="pl-1">or drag and drop</p>
                    </div>
                    <p class="text-xs text-gray-500">
                      PNG, JPEG e PDF fino a 10MB
                    </p>
                  </div>
                  <ul class="mt-4" v-if="filelist.length > 0" v-cloak>
                    <li
                      class="text-sm p-1"
                      v-for="(file, index) in filelist"
                      :key="index"
                    >
                      {{ file.name
                      }}<button
                        class="ml-2"
                        type="button"
                        @click="remove(index)"
                        title="Remove file"
                      >
                        rimuovi
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 sm:px-6 flex justify-between">
              <base-button
                label="Annulla"
                type="reset"
                :isLight="true"
              ></base-button>
              <base-button-spinner v-if="submitted"></base-button-spinner>
              <base-button v-else type="submit" label="Carica" :hasIcon="true">
                <path
                  fill-rule="evenodd"
                  d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
                ></base-button
              >
            </div>
            <div
              v-if="temporaryAlert"
              class="px-4 py-3 bg-gray-50 flex justify-end space-x-2 text-gray-400"
            >
              <p>
                Carica (almeno) un documento per procedere
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div
              v-if="temporaryAlert"
              class="border-b-2 border-gray-400"
              :style="{ width: width }"
            ></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButtonSpinner from "@/ui/buttons/BaseButtonSpinner";
export default {
  name: "UploadFileForm",
  components: {
    BaseButtonSpinner,
  },
  props: {
    model: {
      type: String,
      default: null,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      submitted: false,
      filename: "",
      description: "",
      filelist: [], // Store our uploaded files
      tmp: 0,
      temporaryAlert: false,
    };
  },
  computed: {
    width() {
      return this.tmp + "%";
    },
  },
  methods: {
    async submitForm() {
      if (this.filelist.length > 0) {
        this.submitted = true;
        let files = [];
        this.filelist.forEach((file) => {
          files.push(file.data);
        });

        try {
          await this.$store.dispatch("files/storeFiles", {
            filelist: files,
            filename: this.filename,
            description: this.description,
            model: this.model,
            id: this.id,
          });

          this.$router.back();
        } catch (error) {
          this.error = error.message || "Something went wrong!";
        }
      } else {
        this.temporaryAlert = true;
        this.tmp = 0;

        var refreshId = setInterval(() => {
          this.tmp += 0.05;
          if (this.tmp >= 100) {
            this.tmp = 0;
            this.temporaryAlert = false;
            clearInterval(refreshId);
          }
        }, 1.25);
      }
    },
    resetForm() {
      this.$router.back();
    },
    remove(index) {
      this.filelist.splice(index, 1);
    },
    dragover(event) {
      event.preventDefault();
      if (this.filelist.length > 0) {
        event.currentTarget.classList.remove("bg-gray-50");
      }
      event.currentTarget.classList.add("bg-gray-100");
    },
    dragleave(event) {
      event.currentTarget.classList.remove("bg-gray-100");
      if (this.filelist.length > 0) {
        event.currentTarget.classList.add("bg-gray-50");
      }
    },
    drop(event) {
      event.preventDefault();
      this.onChange(event);
      event.currentTarget.classList.remove("bg-gray-100");
      event.currentTarget.classList.add("bg-gray-50");
    },
    onChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.addFile(files[0]);
    },
    addFile(file) {
      let reader = new FileReader();

      // validation for file type and size
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
      if (!allowedExtensions.exec(file.name)) {
        alert("Per favore carica file con estenzione .jpeg .jpg .png o .pdf");
        return false;
      }

      const maxFileSize = 10000000; // in bytes
      if (file.size > maxFileSize) {
        alert("Dimensioni del file troppo elevate.");
        return false;
      }

      // agiungo file in coda all'array
      this.filename = file.name;
      reader.onload = (e) => {
        this.filelist.push({
          name: file.name,
          data: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>
