<template>
  <div>
    <simple-title title="Acquista" :isUpdating="isUpdating"></simple-title>
    <transition name="slide-top-blur-2">
      <div class="mt-5 md:mt-0" v-if="!isLoading">
        <div class="shadow sm:rounded-md sm:overflow-hidden">
          <div class="px-4 py-5 bg-white sm:p-6">
            <collapse-transition :duration="400">
              <div v-if="onRiepilogue" class="w-full text-xl font-medium pb-4">
                Riepilogo d'ordine
              </div>
            </collapse-transition>
            <div class="space-y-3 divide-y divide-gray-300">
              <!-- 1. Selezione del profilo -->
              <div>
                <collapse-transition :duration="400">
                  <div v-if="order.profile == null">
                    <h1 class="text-lg text-gray-900">
                      Seleziona il profilo di navigazione
                    </h1>
                    <p class="text-sm text-gray-600">
                      Ad ogni profilo corrispondono prestazioni di velocità di
                      navigazione diverse.
                    </p>

                    <div class="grid grid-cols-1 sm:grid-cols-3 gap-4 lg:gap-8 items-center justify-center my-4">
                      <profile-card v-for="(profile, index) in profiles" :key="index"
                        @click="selectProfile(profile.name)" :name="profile.name" :rate="profile.rate"
                        :img="profile.img" :price="profile.price"></profile-card>
                    </div>
                  </div>
                </collapse-transition>
                <collapse-transition :duration="400">
                  <div v-if="order.profile != null">
                    <riepilogue-table-row class="text-md" title="Profilo" :value="order.profile + ' Crono'">
                      <path
                        d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                    </riepilogue-table-row>
                    <collapse-transition :duration="400">
                      <div v-if="!onRiepilogue" class="w-full text-right cursor-pointer text-indigo-600 text-sm"
                        @click="resetProfile()">
                        Modifica
                      </div>
                    </collapse-transition>
                  </div>
                </collapse-transition>
              </div>

              <!-- 2. Selezione del periodo -->
              <div v-if="order.profile != null">
                <collapse-transition :duration="400">
                  <div class="pt-6" v-if="order.period == null">
                    <h1 class="text-lg text-gray-900">
                      Indica se desideri acquistare dei giorni o dei mesi
                    </h1>
                    <p class="text-sm text-gray-600">
                      Ti verrà poi richiesto di specificare quantità e date.
                    </p>

                    <div class="flex flex-col space-y-3 my-4">
                      <div class="flex">
                        <input id="daily" name="period" value="daily" type="radio" v-model="order.period"
                          class="h-4 w-4 focus:ring-gray-600 text-gray-600 mt-1" />
                        <label for="daily" class="ml-3 block text-md font-bold text-gray-600">
                          Giorni
                        </label>
                      </div>
                      <div class="flex">
                        <input id="monthly" name="period" value="monthly" type="radio" v-model="order.period"
                          class="h-4 w-4 focus:ring-gray-600 text-gray-600 mt-1" />
                        <label for="monthly" class="ml-3 block text-md font-bold text-gray-600">
                          Mesi
                        </label>
                      </div>
                    </div>
                  </div>
                </collapse-transition>
                <collapse-transition :duration="400">
                  <div v-if="order.period != null">
                    <riepilogue-table-row class="text-md" title="Periodo" :value="computedPeriod">
                      <path fill-rule="evenodd"
                        d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                        clip-rule="evenodd" />
                    </riepilogue-table-row>
                    <collapse-transition :duration="400">
                      <div v-if="!onRiepilogue" class="w-full text-right cursor-pointer text-indigo-600 text-sm"
                        @click="resetPeriod()">
                        Modifica
                      </div>
                    </collapse-transition>
                  </div>
                </collapse-transition>
              </div>

              <!-- 3.1 Se sceglie giorno -->
              <div v-if="order.period == 'daily'">
                <collapse-transition :duration="400">
                  <div class="pt-6" v-if="order.days.length == 0">
                    <h1 class="text-lg text-gray-900">
                      Seleziona i giorni
                    </h1>
                    <p class="text-sm text-gray-600 pb-4">
                      Nel calendario seleziona i giorni che vuoi ti sia attivata
                      la navigazione.
                    </p>

                    <v-calendar :attributes="attributes" :min-date="new Date()" @dayclick="onDayClick" is-expanded />

                    <div class="flex flex-wrap justify-start py-4">
                      <div v-for="(day, index) in days" :key="index"
                        class="rounded-full bg-indigo-400 bg-opacity-20 text-indigo-500 px-2 text-md my-0.5 mr-2">
                        {{ day.id }}
                      </div>
                    </div>

                    <div class="flex flex-col">
                      <p>Giorni : {{ days.length }}</p>
                      <p>Totale : {{ totalExpected + " €" }}</p>
                      <div v-if="!isConvenient"
                        class="px-4 py-2 my-4 w-full rounded-md bg-gradient-to-r from-yellow-300 to-yellow-400 flex flex-row space-x-4">
                        <i-bell classes="h-6 w-6"></i-bell>
                        <p class="text-gray-900">
                          Attenzione! Il costo del periodo selezionato è
                          superiore al costo del mese intero.
                        </p>
                      </div>
                      <div v-if="!isConvenient" class="flex flex-row space-x-2 items-center">
                        <input type="checkbox" name=""
                          class="rounded-sm w-4 appearance-none checked:bg-blue-600 checked:border-transparent"
                          v-model="nonConvenience" id="" />
                        <p @click="nonConvenience = !nonConvenience" class="cursor-pointer">
                          Confermo di voler comunque procedere
                        </p>
                      </div>
                    </div>

                    <div class="flex">
                      <div class="flex-grow flex justify-between py-4">
                        <base-button :isLight="true" label="Reset" @click="cleanDays()"></base-button>
                        <base-button v-if="(isConvenient ||
        (!isConvenient && nonConvenience)) &&
      days.length > 0
      " label="Conferma" @click="confirmDays()"></base-button>
                        <div v-else-if="days.length == 0" class="text-gray-700">
                          Seleziona almeno un giorno per procedere
                        </div>
                        <div class="text-gray-700" v-else>
                          Per procedere confermare
                        </div>
                      </div>
                    </div>
                  </div>
                </collapse-transition>
                <collapse-transition :duration="400">
                  <div v-if="order.days.length > 0">
                    <riepilogue-table-row class="text-md" title="Giorni" :value="order.days.length">
                      <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
                    </riepilogue-table-row>
                    <collapse-transition :duration="400">
                      <div v-if="!onRiepilogue" class="w-full text-right cursor-pointer text-indigo-600 text-sm"
                        @click="resetDays()">
                        Modifica
                      </div>
                    </collapse-transition>
                  </div>
                </collapse-transition>
              </div>

              <!-- 3.2 Se sceglie mese -->
              <div v-if="order.period == 'monthly'">
                <collapse-transition :duration="400">
                  <div class="pt-6" v-if="order.months == null">
                    <h1 class="text-lg text-gray-900">
                      Definisci il periodo
                    </h1>
                    <p class="text-sm text-gray-600">
                      Indica data di inizio, e quantitativo di mesi.
                    </p>

                    <div class="py-4 flex flex-col space-y-4">
                      <div class="flex flex-row space-x-4 items-center">
                        <p class="text-md text-gray-900">
                          Seleziona il numero di mesi:
                        </p>

                        <div class="text-gray-700">
                          <select v-model="months"
                            class=" text-base py-1 placeholder-gray-600 border rounded-md focus:shadow-outline"
                            placeholder="1">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                        </div>
                      </div>
                      <div class="flex flex-row space-x-4 items-center">
                        <p class="text-md text-gray-900">
                          Seleziona la data di inizio:
                        </p>

                        <v-date-picker class="inline-block h-full" v-model="date">
                          <template v-slot="{ inputValue, togglePopover }">
                            <div class="flex items-center">
                              <button
                                class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                                @click="togglePopover()">
                                <i-calendar-o classes="w-4 h-4 text-current"></i-calendar-o>
                              </button>
                              <input @click="togglePopover()" :value="inputValue"
                                class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                                readonly />
                            </div>
                          </template>
                        </v-date-picker>
                      </div>
                      <div class="flex flex-row space-x-4 items-center">
                        <p class="text-md text-gray-900">
                          Data di fine:
                        </p>
                        <p>{{ end_date }}</p>
                      </div>
                    </div>

                    <div class="flex">
                      <div class="flex-grow flex justify-between py-4">
                        <base-button :isLight="true" label="Reset" @click="cleanMonths()"></base-button>
                        <base-button label="Conferma" @click="confirmMonths()"></base-button>
                      </div>
                    </div>
                  </div>
                </collapse-transition>
                <collapse-transition :duration="400">
                  <div v-if="order.months != null">
                    <riepilogue-table-row class="text-md" title="Mesi" :value="order.months">
                      <path d="M5 4a2 2 0 012-2h6a2 2 0 012 2v14l-5-2.5L5 18V4z" />
                    </riepilogue-table-row>
                    <collapse-transition :duration="400">
                      <div v-if="!onRiepilogue" class="w-full text-right cursor-pointer text-indigo-600 text-sm"
                        @click="resetMonths()">
                        Modifica
                      </div>
                    </collapse-transition>
                  </div>
                </collapse-transition>
              </div>

              <collapse-transition :duration="400">
                <div v-if="onRiepilogue">
                  <div class="flex flex-row justify-between pt-1 text-gray-700">
                    <p class="text-md">Dettagli</p>
                    <div class="text-sm text-right" v-html="order.details"></div>
                  </div>
                </div>
              </collapse-transition>

              <collapse-transition :duration="400">
                <div v-if="onRiepilogue">
                  <div class="flex flex-row justify-between pt-2 text-gray-700">
                    <p class="text-lg">Totale</p>
                    <div class="text-md text-right">{{ order.total }} €</div>
                  </div>
                </div>
              </collapse-transition>
            </div>
          </div>
          <collapse-transition :duration="400">
            <div v-if="onRiepilogue" class="px-4 py-3 bg-gray-50 sm:px-6 flex">
              <!-- actions -->
              <div class="flex-grow flex justify-between">
                <base-button :isLight="true" label="Modifica ordine" @click="backToEdit()"></base-button>
                <base-button @click.prevent="addToCart()" label="Aggiungi al carrello"></base-button>
              </div>
            </div>
          </collapse-transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import json from "@/json/crono.json";
import moment from "moment";
import RiepilogueTableRow from "@/ui/services/RiepilogueTableRow";
import ProfileCard from "@/ui/services/ProfileCard";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";

export default {
  name: "InternetPurchase",
  props: ["id"],
  components: {
    RiepilogueTableRow,
    ProfileCard,
    CollapseTransition,
  },
  data() {
    return {
      days: [],
      months: 1,
      date: new Date(),
      isLoading: true,
      isUpdating: true,
      onRiepilogue: false,
      nonConvenience: false,
      order: {
        itemCode: null,
        quantity: 0,
        invoice_notes: "",
        profile: null,
        period: null,
        days: [],
        months: null,
        start_date: null,
        end_date: null,
        details: "",
        total: 0,
      },
      profiles: json,
    };
  },
  computed: {
    service() {
      return this.$store.getters["services/loadedService"];
    },
    computedPeriod() {
      if (this.order.period == "daily") return "Giorni";
      else return "Mesi";
    },
    items() {
      return this.$store.getters["services/cronoItems"];
    },
    dates() {
      return this.days.map((day) => day.date);
    },
    attributes() {
      return this.dates.map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
    end_date() {
      return moment(this.date, "DD/MM/YYYY")
        .add(this.months, "months")
        .format("DD/MM/YYYY");
    },
    selectedProfile() {
      return this.profiles.filter((profile) => {
        return profile.name == this.order.profile;
      })[0];
    },
    totalExpected() {
      return this.selectedProfile.price.daily * this.days.length;
    },
    isConvenient() {
      return this.totalExpected >= this.selectedProfile.price.monthly
        ? false
        : true;
    },
    days_sorted() {
      return this.order.days.slice().sort((a, b) => a.date - b.date);
    },
  },
  async mounted() {
    this.setBreadcrumbs();

    await this.loadCronoItems();

    this.isLoading = false;
    this.isUpdating = false;
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Servizi", link: "/services" },
        {
          title: this.service.item.category.name,
          link: "/services/internet/" + this.service.id,
        },
        {
          title: "Acquista",
          link: "/services/internet/" + this.service.id + "/purchase",
        },
      ]);
    },
    resetProfile() {
      this.order.profile = null;
    },
    resetPeriod() {
      this.order.period = null;
    },
    resetDays() {
      this.order.days = [];
    },
    resetMonths() {
      this.order.months = null;
    },
    selectProfile(profile) {
      this.order.profile = profile;
    },
    onDayClick(day) {
      const idx = this.days.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
          id: day.id,
          date: day.date,
        });
      }
    },
    cleanDays() {
      this.days = [];
    },
    cleanMonths() {
      this.months = 1;
      this.date = new Date();
    },
    confirmDays() {
      this.order.days = this.days;

      let details = "";
      let invoice_notes = this.order.days.length + " giorni di navigazione : ";
      this.days_sorted.forEach((day) => {
        let giorno = moment(day.id).format("DD/MM/YYYY");
        details += giorno.toString() + "<br/>";
        invoice_notes += giorno.toString() + ", ";
      });
      this.order.details = details;
      this.order.invoice_notes = invoice_notes.slice(0, -2);
      this.endProcess();
    },
    confirmMonths() {
      this.order.months = this.months;
      this.order.start_date = moment(this.date).format("DD/MM/YYYY");
      this.order.end_date = moment(this.date, "DD/MM/YYYY")
        .add(this.order.months, "months")
        .format("DD/MM/YYYY");

      let details = "";
      details += "Dal " + this.order.start_date + "<br/>";
      details += "Al  " + this.order.end_date + "<br/>";
      this.order.details = details;
      this.order.invoice_notes =
        "Periodo dal " + this.order.start_date + " al " + this.order.end_date;

      this.endProcess();
    },
    loadTotal() {
      if (this.order.period == "monthly")
        this.order.total =
          this.selectedProfile.price.monthly * this.order.months;
      if (this.order.period == "daily")
        this.order.total =
          this.selectedProfile.price.daily * this.order.days.length;
    },
    loadItemCode() {
      this.order.itemCode = this.selectedProfile.items[this.order.period];
    },
    loadQuantity() {
      if (this.order.period == "monthly")
        this.order.quantity = this.order.months;
      if (this.order.period == "daily")
        this.order.quantity = this.order.days.length;
    },
    endProcess() {
      this.loadTotal();
      this.loadItemCode();
      this.loadQuantity();
      this.onRiepilogue = true;
    },
    backToEdit() {
      this.onRiepilogue = false;
      this.resetMonths();
      this.resetDays();
    },
    async loadCronoItems() {
      if (this.items.length == 0) {
        this.isLoading = true;
        this.isUpdating = true;
      } else {
        this.isLoading = false;
        this.isUpdating = true;
      }
      try {
        await this.$store.dispatch("services/getCronoItems");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
      this.isUpdating = false;
    },
    getItem(itemCode) {
      return this.items.find((item) => {
        return item.code == itemCode;
      });
    },
    addToCart() {
      let item = this.getItem(this.order.itemCode);
      item.quantity = this.order.quantity;
      item.details = this.service;
      // console.log(item);
      item.details.invoice_notes = this.order.invoice_notes;

      try {
        this.$store.commit("payments/addToCart", {
          items: [item],
        });
        this.$router.replace("/payments/checkout");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
};
</script>
