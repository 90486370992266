<template>
  <router-link :to="serviceDetailsLink" v-if="service.subordinate == 0">
    <!-- Desktop view -->
    <div
      class="hidden sm:flex p-4 bg-gray-200 rounded-lg shadow-md hover:shadow-lg bg-gradient-to-r hover:from-gray-200 hover:to-gray-300"
    >
      <div class="flex-shrink flex items-center text-gray-400">
        <svg
          class="font-normal w-16"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            v-if="service.type == 'Internet'"
            fill-rule="evenodd"
            d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
            clip-rule="evenodd"
          />
          <path
            v-if="service.type == 'Voip'"
            d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
          />
          <path
            v-if="service.type == 'Generic'"
            fill-rule="evenodd"
            d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div
        class="flex-shrink flex items-center font-medium text-gray-400 uppercase mx-auto text-center px-8"
      >
        {{ service.item.category.name }}
      </div>
      <div class="flex-grow text-gray-400 text-left py-1 px-4">
        <div class="flex flex-col">
          <div class="text-lg text-yellow-500 pb-1">
            <span class="uppercase mr-1 font-bold">
              {{ service.item.name }}
            </span>
          </div>
          <div class="text-gray-400 text-sm">
            <span class="font-bold" v-if="service.type == 'Voip'">
              {{ service.details.number }} •
            </span>
            {{ service.address.address }}
            {{ service.address.civic }}, {{ service.address.city }}
            {{ service.address.cap }} ({{ service.address.province }})
          </div>
          <span v-if="services.length > 0" class="text-sm mt-4 mb-2"
            >Servizi opzionali</span
          >
          <div v-for="(inService, index) in services" :key="index">
            <div
              class="flex flex-row mb-3 items-start"
              v-if="inService.main_id == service.id"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="text-gray-500 w-12 md:w-5 mr-2"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 2a2 2 0 00-2 2v11a3 3 0 106 0V4a2 2 0 00-2-2H4zm1 14a1 1 0 100-2 1 1 0 000 2zm5-1.757l4.9-4.9a2 2 0 000-2.828L13.485 5.1a2 2 0 00-2.828 0L10 5.757v8.486zM16 18H9.071l6-6H16a2 2 0 012 2v2a2 2 0 01-2 2z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div>{{ inService.item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-shrink flex items-center text-gray-400">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="w-16"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>

    <!-- Mobile view -->
    <div
      class="sm:hidden flex justify-between pl-1 bg-gray-200 rounded-lg shadow-md hover:shadow-lg bg-gradient-to-r hover:from-gray-200 hover:to-gray-300"
    >
      <div class="flex-grow flex items-center">
        <div class="flex-grow">
          <div class="grid grid-cols-1 text-gray-400 mx-1 text-left px-1 py-1">
            <span class="text-md font-bold text-gray-400 uppercase mr-1 pt-1">
              {{ service.item.category.name }}
            </span>
            <span
              class="text-xl font-medium text-gray-800 capitalize mr-1 pt-1"
            >
              {{ service.item.name.toLowerCase() }}
            </span>
            <span v-if="services.length > 0" class="text-xs mt-2 mb-1"
              >Servizi opzionali</span
            >
            <div
              v-for="inService in services"
              :key="inService.id"
              class="text-sm mb-2"
            >
              <div class="flex flex-row items-end items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="text-gray-500 w-5 md:w-5 mr-1"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 2a2 2 0 00-2 2v11a3 3 0 106 0V4a2 2 0 00-2-2H4zm1 14a1 1 0 100-2 1 1 0 000 2zm5-1.757l4.9-4.9a2 2 0 000-2.828L13.485 5.1a2 2 0 00-2.828 0L10 5.757v8.486zM16 18H9.071l6-6H16a2 2 0 012 2v2a2 2 0 01-2 2z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="text-sm">{{ inService.item.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            class="text-gray-400 w-8 sm:w-12"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "BaseService",
  props: ["service"],
  computed: {
    services() {
      return this.$store.getters["services/services"].filter((srv) => {
        return srv.main_id == this.service.id;
      });
    },
    serviceDetailsLink() {
      return (
        this.$route.path +
        "/" +
        this.service.type.toLowerCase() +
        "/" +
        this.service.id
      );
    },
  },
};
</script>

<style scoped>
@layer utilities {
  .border-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
