import json from './init.json';

export default {
  setUser(state, payload) {
    state.token = payload.token;
    state.userId = payload.userId;
    state.didAutoLogout = false;
  },
  setAutoLogout(state) {
    state.didAutoLogout = true;
  },
  reset(state) {
    const s = json[0];
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  }
};