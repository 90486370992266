<template>
  <div></div>
  <!-- <router-link :to="relativeRoute('invoices')">
        <pills-button
            class="mr-1 lg:mr-4 mt-1 mainButton min-w-max"
            title="Le tue fatture"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                class="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"
                fill="currentColor"
            >
                <path
                    fill-rule="evenodd"
                    d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                />
            </svg>
        </pills-button>
    </router-link> -->

  <!-- <router-link :to="relativeRoute('due')">
        <pills-button
            class="mr-1 lg:mr-4 mt-3 mainButton min-w-max"
            title="Da pagare"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.736 6.979C9.208 6.193 9.696 6 10 6c.304 0 .792.193 1.264.979a1 1 0 001.715-1.029C12.279 4.784 11.232 4 10 4s-2.279.784-2.979 1.95c-.285.475-.507 1-.67 1.55H6a1 1 0 000 2h.013a9.358 9.358 0 000 1H6a1 1 0 100 2h.351c.163.55.385 1.075.67 1.55C7.721 15.216 8.768 16 10 16s2.279-.784 2.979-1.95a1 1 0 10-1.715-1.029c-.472.786-.96.979-1.264.979-.304 0-.792-.193-1.264-.979a4.265 4.265 0 01-.264-.521H10a1 1 0 100-2H8.017a7.36 7.36 0 010-1H10a1 1 0 100-2H8.472c.08-.185.167-.36.264-.521z"
                    clip-rule="evenodd"
                />
            </svg>
        </pills-button>
    </router-link> -->
</template>

<script>
export default {
  name: "SideMenu",
  methods: {
    relativeRoute(relative = "") {
      return "/payments/" + relative;
    },
  },
};
</script>

<style scoped>
.router-link-active .mainButton {
  @apply bg-gray-200;
  @apply rounded-md;
  @apply shadow-md;
}

.mainButton:hover {
  @apply bg-opacity-10;
  @apply bg-gray-200;
  @apply rounded-md;
}
</style>
