<template>
  <svg
    :class="classes"
    height="32"
    width="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path d="M0 0h32v32H0z" fill="#000"></path>
      <g fill-rule="nonzero">
        <path d="M13.02 10.505h5.923v10.857H13.02z" fill="#ff5f00"></path>
        <path
          d="M13.396 15.935a6.944 6.944 0 0 1 2.585-5.43c-2.775-2.224-6.76-1.9-9.156.745s-2.395 6.723 0 9.368 6.38 2.969 9.156.744a6.944 6.944 0 0 1-2.585-5.427z"
          fill="#eb001b"
        ></path>
        <path
          d="M26.934 15.935c0 2.643-1.48 5.054-3.81 6.21s-5.105.851-7.143-.783a6.955 6.955 0 0 0 2.587-5.428c0-2.118-.954-4.12-2.587-5.429 2.038-1.633 4.81-1.937 7.142-.782s3.811 3.566 3.811 6.21z"
          fill="#f79e1b"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Mastercard",
  props: ["classes"],
};
</script>
