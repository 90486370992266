<template>
  <div :class="step != '2' ? 'px-4 py-5 sm:p-6' : ''
        " v-if="!isEmpty" class="flex flex-col">
    <div class="flex flex-col">
      <div v-if="step != '2'"
        class="flex-grow flex justify-between items-start text-gray-800 border-b border-gray-200 mb-5 pb-4 text-xl">
        Carrello
      </div>
      <div class="flex-grow flex justify-between items-start text-md text-gray-400 pb-1 ">
        <div class="text-left flex flex-col">Prodotto</div>
        <div>Prezzo</div>
      </div>
      <div v-for="service in services" :key="service.id"
        class="flex-grow flex justify-between items-start pt-4 pb-3 border-t border-gray-200">
        <div class="text-left flex flex-col">
          <span class="font-bold text-md text-gray-700">
            <span class="text-gray-500">{{ service.billing_frequency }}ｘ</span>{{ service.item.name }}
          </span>
          <span class="text-sm font-light text-gray-500">
            {{ parseInvoiceNotes(service) }}
          </span>
        </div>
        <div class="flex flex-col">
          <div class="text-gray-700 font-regular text-xl whitespace-nowrap">
            {{ price(service.item.price * service.billing_frequency) }}
          </div>
          <p v-if="service.subordinate != 1 &&
        step != '2'
        " @click="removeServiceFromCart(service)"
            class="text-right text-sm text-gray-400 hover:text-gray-900 cursor-pointer">
            Rimuovi
          </p>
        </div>
      </div>
      <div v-for="item in items" :key="item.id"
        class="flex-grow flex justify-between items-start pt-4 pb-3 border-t border-gray-200">
        <div class="text-left flex flex-col">
          <span class="font-bold text-md text-gray-700">
            <span class="text-gray-500">{{ item.quantity }}ｘ</span>{{ item.name }}
          </span>
          <span class="text-sm font-light text-gray-500">
            {{ parseInvoiceNotes(item.details) }}
          </span>
        </div>
        <div class="flex flex-col">
          <div class="text-gray-700 font-regular text-xl whitespace-nowrap">
            {{ price(item.price * item.quantity) }}
          </div>
          <p v-if="step != '2'" @click="removeItemFromCart(item)"
            class="text-right text-sm text-gray-400 hover:text-gray-900 cursor-pointer">
            Rimuovi
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <div v-show="discount > 0" class="flex flex-row justify-between border-t border-gray-200 pt-4 mt-2">
        <span class="text-gray-500 text-sm">Subtotale</span>
        <span class="text-gray-500 text-sm">{{ granSubTotal }}</span>
      </div>
      <div v-show="discount > 0" class="flex flex-row justify-between mt-2">
        <span class="text-gray-500 text-sm">Sconto</span>
        <span class="text-gray-500 text-sm">{{ price(discount) }}</span>
      </div>
      <div class="flex flex-row justify-between border-t border-gray-200 pt-4 mt-2">
        <span class="font-medium text-gray-700 text-xl">Totale</span>
        <span class="font-medium text-gray-700 text-xl">{{ granTotal }}</span>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="flex flex-col mx-auto">
      <img src="/assets/undraw/empty_cart.svg" style="max-width: 400px" class="w-full md:w-1/2 mx-auto my-5 sm:my-20"
        alt="" />
      <div class="mx-auto p-4 md:p-10 flex-flex-col space-y-4">
        <h1 class="text-gray-600 text-2xl md:text-3xl text-center">
          Il tuo carrello Nibble è vuoto.
        </h1>
        <h1 class="text-gray-400 pext-lg md:text-xl text-center">
          Non ci sono prodotti né servizi nel carrello!
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "OrderSummary",
  props: ["step"],
  computed: {
    cart() {
      return this.$store.getters["payments/cart"];
    },
    services() {
      return this.cart.services;
    },
    client() {
      return this.$store.getters["user/loadedClient"];
    },
    vat() {
      return this.$gf.getVATbyCode(this.client.vat_code)['multiplier']
    },
    items() {
      return this.cart.items;
    },
    isService() {
      return this.services.length > 0 ? true : false;
    },
    isItem() {
      return this.items.length > 0 ? true : false;
    },
    subtotal() {
      let price = 0;

      // adding service price
      this.services.forEach((service) => {
        price += service.item.price * service.billing_frequency;
      });

      // adding item price
      this.items.forEach((item) => {
        price += item.price * item.quantity;
      });

      return price;
    },
    granSubTotal() {
      return this.price(this.subtotal);
    },
    discount() {
      let discount = 0;

      // adding service price
      this.services.forEach((service) => {
        if (service.discount != 0)
          discount +=
            (service.item.price *
              service.billing_frequency *
              service.discount) /
            100;
      });

      return discount;
    },
    granTotal() {
      if (this.discount == 0) return this.granSubTotal;
      else {
        return this.price(
          parseFloat(this.subtotal) - parseFloat(this.discount)
        );
      }
    },
    isEmpty() {
      if (this.items.length == 0 && this.services.length == 0) return true;
      else return false;
    },
  },
  methods: {
    price(price, invertedSymbol = false) {
      const priceVAT = this.vat * price
      if (invertedSymbol) return "€" + priceVAT.toFixed(2);
      else return priceVAT.toFixed(2) + " €";
    },
    parseInvoiceNotes(service) {
      let notes = service.invoice_notes;
      if (notes) {
        notes = notes.replace(
          "[DATINI]",
          moment(service.next_invoice_date).format("DD/MM/YYYY")
        );
        notes = notes.replace(
          "[DATFIN]",
          moment(service.next_invoice_date)
            .add(service.billing_frequency, "M")
            .subtract(1, "days")
            .format("DD/MM/YYYY")
        );
        notes = notes.replace("[DISCOUNT]", service.discount.toString());
      }
      return notes;
    },
    removeServiceFromCart(removeService) {
      let services = this.services.filter((service) => {
        return (
          service.id != removeService.id && service.main_id != removeService.id
        );
      });
      return this.$store.commit("payments/removeToCart", {
        services: services,
      });
    },
    removeItemFromCart(removeItem) {
      let items = this.items.filter((item) => {
        return item.id != removeItem.id;
      });
      return this.$store.commit("payments/removeToCart", { items: items });
    },
  },
};
</script>
