<template>
  
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    id="Layer_1"
    :class="classes"
    fill="currentColor"
    viewBox="0 0 100 100"
  >
    <title />
    <path
      d="M76.09,53.55H68.93v-12a2,2,0,1,0-4,0v12H23.88A5.89,5.89,0,0,0,18,59.43V72.3a5.88,5.88,0,0,0,5.88,5.87H76.09A5.87,5.87,0,0,0,82,72.3V59.43A5.88,5.88,0,0,0,76.09,53.55ZM78,72.3a1.87,1.87,0,0,1-1.87,1.87H23.88A1.88,1.88,0,0,1,22,72.3V59.43a1.88,1.88,0,0,1,1.88-1.88H76.09A1.88,1.88,0,0,1,78,59.43ZM52.44,30.66a2,2,0,0,1,0-2.83,20.48,20.48,0,0,1,29,0,2,2,0,0,1-2.82,2.83,16.49,16.49,0,0,0-23.32,0,2,2,0,0,1-2.83,0ZM66.93,30a11.2,11.2,0,0,1,7.95,3.29,2,2,0,1,1-2.83,2.83,7.24,7.24,0,0,0-10.24,0,2,2,0,0,1-1.42.58A2,2,0,0,1,59,36.11a2,2,0,0,1,0-2.83A11.16,11.16,0,0,1,66.93,30ZM31.72,63.85V67.4a2,2,0,0,1-4,0V63.85a2,2,0,1,1,4,0Zm11.61,0V67.4a2,2,0,0,1-4,0V63.85a2,2,0,0,1,4,0Z"
    />
  </svg>
</template>

<script>
export default {
  name: "Router",
  props: ["classes"],
};
</script>
