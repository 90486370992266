import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      breadcrumbs: [],
      months: [
        {
          id: "01",
          name: "Gennaio",
        },
        {
          id: "02",
          name: "Febbraio",
        },
        {
          id: "03",
          name: "Marzo",
        },
        {
          id: "04",
          name: "Aprile",
        },
        {
          id: "05",
          name: "Maggio",
        },
        {
          id: "06",
          name: "Giugno",
        },
        {
          id: "07",
          name: "Luglio",
        },
        {
          id: "08",
          name: "Agosto",
        },
        {
          id: "09",
          name: "Settembre",
        },
        {
          id: "10",
          name: "Ottobre",
        },
        {
          id: "11",
          name: "Novembre",
        },
        {
          id: "12",
          name: "Dicembre",
        },
      ],
    };
  },
  mutations,
  actions,
  getters
};