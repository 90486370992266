<template>
  <main-layout>
    <div class="mb-8 mt-4 md:mt-0 md:mb-16 flex flex-col-reverse md:flex-row">
      <div class="text-4xl md:text-3xl font-bold flex-grow">
        I tuoi servizi
      </div>
      <div class="text-lg md:text-xl text-gray-500 md:text-right">
        <span class="hidden md:inline"> Sei sulla Home, </span
        ><span class="md:hidden"> Benvenuto su myNibble, </span
        >{{
          loadedClient.name == "" || loadedClient.name == null
            ? loadedClient.heading
            : loadedClient.name
        }}
      </div>
    </div>
    <div v-if="isLoading">
      <div class="hidden sm:block">
        <skeleton-services-list
          class="mb-6 opacity-80"
        ></skeleton-services-list>
        <skeleton-services-list
          class="mb-6 opacity-40"
        ></skeleton-services-list>
        <skeleton-services-list
          class="mb-6 opacity-20"
        ></skeleton-services-list>
      </div>

      <div class="sm:hidden">
        <skeleton-services-list-mobile
          class="mb-6 opacity-80"
        ></skeleton-services-list-mobile>
        <skeleton-services-list-mobile
          class="mb-6 opacity-40"
        ></skeleton-services-list-mobile>
        <skeleton-services-list-mobile
          class="mb-6 opacity-20"
        ></skeleton-services-list-mobile>
      </div>
    </div>
    <div v-else>
      <div class="mb-6" v-for="service in services" :key="service.id">
        <base-service :service="service"></base-service>
      </div>
    </div>
  </main-layout>
</template>

<script>
import BaseService from "@/ui/services/BaseService";
import SkeletonServicesList from "@/ui/skeleton/services/SkeletonServicesList";
import SkeletonServicesListMobile from "@/ui/skeleton/services/SkeletonServicesListMobile";

export default {
  components: { BaseService, SkeletonServicesList, SkeletonServicesListMobile },
  name: "ServicesList",
  data() {
    return {
      isLoading: false,
    };
  },
  watch: {
    async loadedClient() {
      await this.loadServices();
    },
  },
  computed: {
    services() {
      return this.$store.getters["services/services"];
    },
    name() {
      return this.$store.getters["user/name"];
    },
    loadedClient() {
      return this.$store.getters["user/loadedClient"];
    },
  },
  async mounted() {
    this.setBreadcrumbs();

    if (
      this.$store.getters["services/services"].length == 0 ||
      this.services[0].client_id != this.loadedClient.id
    ) {
      await this.loadServices();
    }
  },

  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Servizi", link: "/services" },
      ]);
    },
    async loadServices() {
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          await this.$store.dispatch("services/getServices");
        } catch (error) {
          this.error = error.message || "Something went wrong!";
        }
        this.isLoading = false;
      }
    },
  },
};
</script>
