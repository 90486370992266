<template>
    <div class="text-sm sm:text-md">
        <!-- Desktop view -->
        <div
            :class="isUpdating ? 'magic-shadow-back' : 'magic-shadow'"
            class="flex p-4 rounded-md bg-gradient-to-r from-white to-gray-50 hover:from-gray-50 hover:to-white"
        >
            <div class="flex-grow flex sm:grid sm:grid-cols-5 items-center">
                <div
                    class="flex-grow sm:col-span-1 text-left text-gray-800 mr-2"
                >
                    <span class="mr-1">
                        n. {{ invoice.filename.replace("Fattura n.", "") }}
                    </span>
                </div>
                <div class="mr-2 sm:mr-0 sm:col-span-4 text-left text-gray-800">
                    <span class="mr-1">
                        <span class="text-gray-400">Emessa il </span>
                        {{ formattedDate }}
                    </span>
                </div>
            </div>
            <div
                @click="download(invoice)"
                content="Download"
                v-tippy="{ placement: 'top', arrow: false }"
                class="flex-shrink justify-end items-end text-blue-400 hover:text-blue-500"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    class="w-6"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "BaseInvoiceRow",
    props: {
        invoice: {},
        isUpdating: Boolean,
    },
    computed: {
        formattedDate() {
            return moment(this.invoice.created_at).format("DD/MM/YYYY");
        },
        userId() {
            return this.$store.getters["userId"];
        },
    },
    methods: {
        async download(file) {
            file.downloading = true;
            try {
                await this.$store.dispatch("files/downloadFile", {
                    file: file,
                });
            } catch (error) {
                this.error = error.message || "Something went wrong!";
            }
            file.downloading = false;
        },
    }
};
</script>

<style scoped>
.magic-shadow {
    @apply shadow-lg;
    transition: 0.4s ease-out;
}

.magic-shadow-back {
    @apply shadow-sm;
    transition: 0.2s ease-in;
}
</style>