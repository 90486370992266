import { createStore } from 'vuex';

import authModule from './modules/auth/index.js';
import userModule from './modules/user/index.js';
import filesModule from './modules/files/index.js';
import settingsModule from './modules/settings/index.js';
import mainModule from './modules/main/index.js';
import servicesModule from './modules/services/index.js';
import paymentsModule from './modules/payments/index.js';
import ticketsModule from './modules/tickets/index.js';

const store = createStore({
  modules: {
    auth: authModule,
    user: userModule,
    files: filesModule,
    settings: settingsModule,
    main: mainModule,
    services: servicesModule,
    payments: paymentsModule,
    tickets: ticketsModule
  }
});

export default store;