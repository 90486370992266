<template>
  <div class="md:hidden mt-3 -mb-2">
    <div class="flex items-center">
      <div class="z-20 flex-grow flex items-center">
        <span class="text-3xl xs:text-4xl font-bold text-gray-900">
          <slot></slot>
        </span>
      </div>
      <div v-if="screamedMenu.length > 1" class="flex-grow-0 flex items-center">
        <i-menu-to-times
          @clicked="toggleSecondaryMenu"
          :isOpen="isOpen"
        ></i-menu-to-times>
      </div>
    </div>
    <transition name="slide-down">
      <div
        v-show="isOpen"
        class="absolute z-10 origin-top-right right-0 w-full mt-2"
      >
        <div
          class="bg-gradient-to-b from-gray-100 to-white shadow-md rounded-2xl text-gray-900 p-5 pb-6 pl-7 h-max"
        >
          <div class="grid grid-cols-1 divide-y divide-gray-200 text-base">
            <transition
              v-for="(item, index) in screamedMenu"
              :key="index"
              :name="getTransition(index)"
            >
              <router-link
                v-show="isOpen"
                @click.prevent="toggleSecondaryMenu"
                :class="setPadding(index)"
                :to="item.link"
                class="mainSecondaryMenu"
              >
                {{ item.label }}
              </router-link>
            </transition>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "MobileSecondaryMenu",
  props: ["menu"],
  emits: ["toggle"],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    screamedMenu() {
      return this.menu.filter((item) => {
        return this.enable(item);
      });
    },
  },
  methods: {
    toggleSecondaryMenu() {
      this.isOpen = !this.isOpen;
      console.log("chiudere loi chiuli", this.isOpen);
      this.$emit("toggle");
    },
    setPadding(index) {
      let exit = "";
      switch (index) {
        case 0:
          exit = "pb-3";
          break;
        case this.screamedMenu.length - 1:
          exit = "pt-3";
          break;
        default:
          exit = "py-3";
          break;
      }
      return exit;
    },
    enable(item) {
      if (item.enable != undefined) return item.enable;
      else return true;
    },
    getTransition(index) {
      return "slide-bottom-blur-" + (index + 2);
    },
  },
};
</script>
