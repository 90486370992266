<template>
    <simple-title title="Checkout"></simple-title>
    <the-checkout :services="cart.services" :items="cart.items"></the-checkout>
</template>

<script>
import TheCheckout from "./../../../../components/checkout/TheCheckout.vue";

export default {
    name: "VoipCheckout",
    props: ["id"],
    components: {
        TheCheckout,
    },
    computed: {
        service() {
            return this.$store.getters["services/loadedService"];
        },
        cart() {
            return this.$store.getters["payments/cart"];
        },
    },
    mounted() {
        this.setBreadcrumbs();
    },
    methods: {
        setBreadcrumbs() {
            this.$store.dispatch("main/setBreadcrumbs", [
                { title: "Servizi", link: "/services" },
                {
                    title: this.service.item.category.name,
                    link: "/services/voip/" + this.id,
                },
                {
                    title: "Checkout",
                    link: "/services/voip/" + this.id + "/checkout",
                },
            ]);
        },
    },
};
</script>