import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      tickets: {
        open: {},
        closed: {}
      },
      subcategories: [],
      loadedTicket: null,
      updateDatetime: null
    };
  },
  mutations,
  actions,
  getters
};