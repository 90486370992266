<template>
  <div class="hidden md:flex pt-4 px-4 sm:px-6 lg:px-10 text-gray-400">
    myNibble
    <span v-for="(item, index) in path" :key="index" class="flex">
      <i-chevron-right classes="w-6 h-6"></i-chevron-right>
      <router-link :to="item.link" :class="isLast(index)">
        {{ item.title }}
      </router-link>
    </span>
  </div>
</template>
<script>
export default {
  name: "Breadcrumbs",
  props: ["path"],
  methods: {
    isLast(index) {
      if (this.path.length - 1 === index) return "text-yellow-500";
    },
  },
};
</script>
