<template>
    <div
        class="flex p-1 bg-gray-200 rounded-lg bg-gradient-to-r hover:from-gray-200 hover:to-gray-300"
    >
        <div class="flex-grow text-gray-300 text-left mx-1 py-1 px-1">
            <div class="flex flex-col">
                <div>
                    <div class="relative w-12 h-4 mt-1 mb-2">
                        <div class="absolute top-0 left-0 h-full w-full">
                            <span
                                class="skeleton-box transition-transform transform-center block h-full rounded-md bg-gray-500"
                            ></span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="relative w-3/4 h-5 mb-1">
                        <div class="absolute top-0 left-0 h-full w-full">
                            <span
                                class="skeleton-box transition-transform transform-center block h-full rounded-md bg-gray-300"
                            ></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkeletonServicesListMobile'
}
</script>


<style scoped>
.skeleton-box {
    position: relative;
    overflow: hidden;
    filter: blur(0.4px);
    @apply bg-opacity-50;
}
.skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
    );
    animation: shimmer 1s infinite;
    content: "";
}
@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}
</style>

