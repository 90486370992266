<template>
  <div>
    <div v-if="!isCompleted" class="grid lg:grid-cols-5">
      <div class="lg:col-span-3">
        <div class="p-4 sm:px-10 sm:py-8 flex flex-col space-y-2">
          <div class="text-lg text-gray-900 font-regular">
            Informazioni di fatturazione
          </div>
          <div class="pb-5 sm:pb-10" v-if="client">
            <table
              class="table-fixed xl:table-auto rounded border-separate sm:border-collapse border sm:border-2 border-gray-200 w-full font-medium text-sm sm:text-md"
            >
              <tbody class="sm:divide-y-2 sm:divide-gray-200">
                <tr class="sm:hidden">
                  <td class="text-gray-900 px-2 pt-2">Intestazione</td>
                </tr>
                <tr class="">
                  <td
                    class="hidden sm:block font-semibold px-2 sm:px-5 lg:px-6 py-1 sm:py-2 lg:py-3 text-gray-500"
                    style="background-color: rgb(240,242,243)"
                  >
                    Intestazione
                  </td>
                  <td
                    class="pb-2 sm:py-2 lg:py-3 px-2 sm:px-5 lg:px-6 text-gray-500 sm:text-gray-800"
                  >
                    {{ client.heading }}
                  </td>
                </tr>

                <tr class="sm:hidden">
                  <td class="text-gray-900 pl-2">
                    <div class="pt-1 border-t border-gray-200">Email</div>
                  </td>
                </tr>
                <tr class="">
                  <td
                    class="hidden sm:block font-semibold px-2 sm:px-5 lg:px-6 py-1 sm:py-2 lg:py-3 text-gray-500"
                    style="background-color: rgb(240,242,243)"
                  >
                    Email
                  </td>
                  <td
                    class="pb-1 sm:py-2 lg:py-3 px-2 sm:px-5 lg:px-6 text-gray-500 sm:text-gray-800 truncate"
                  >
                    {{ client.email }}asdcasdcasdcasdcasdcasdc
                  </td>
                </tr>

                <tr class="sm:hidden">
                  <td class="text-gray-900 pl-2">
                    <div class="pt-1 border-t border-gray-200">Indirizzo</div>
                  </td>
                </tr>
                <tr class="">
                  <td
                    class="hidden sm:block font-semibold px-2 sm:px-5 lg:px-6 py-1 sm:py-2 lg:py-3 text-gray-500"
                    style="background-color: rgb(240,242,243)"
                  >
                    Indirizzo
                  </td>
                  <td
                    class="pb-1 sm:py-2 lg:py-3 px-2 sm:px-5 lg:px-6 text-gray-500 sm:text-gray-800"
                  >
                    {{ client.address }} {{ client.civic }}
                  </td>
                </tr>

                <tr class="sm:hidden">
                  <td class="text-gray-900 pl-2">
                    <div class="pt-1 border-t border-gray-200">Città</div>
                  </td>
                </tr>
                <tr class="">
                  <td
                    class="hidden sm:block font-semibold px-2 sm:px-5 lg:px-6 py-1 sm:py-2 lg:py-3 text-gray-500"
                    style="background-color: rgb(240,242,243)"
                  >
                    Città
                  </td>
                  <td
                    class="pb-1 sm:py-2 lg:py-3 px-2 sm:px-5 lg:px-6 text-gray-500 sm:text-gray-800"
                  >
                    {{ client.city }}
                  </td>
                </tr>

                <tr class="sm:hidden">
                  <td class="text-gray-900 pl-2">
                    <div class="pt-1 border-t border-gray-200">Provincia</div>
                  </td>
                </tr>
                <tr class="">
                  <td
                    class="hidden sm:block font-semibold px-2 sm:px-5 lg:px-6 py-1 sm:py-2 lg:py-3 text-gray-500"
                    style="background-color: rgb(240,242,243)"
                  >
                    Provincia
                  </td>
                  <td
                    class="pb-1 sm:py-2 lg:py-3 px-2 sm:px-5 lg:px-6 text-gray-500 sm:text-gray-800"
                  >
                    {{ client.province }}
                  </td>
                </tr>

                <tr class="sm:hidden">
                  <td class="text-gray-900 pl-2">
                    <div class="pt-1 border-t border-gray-200">CAP</div>
                  </td>
                </tr>
                <tr class="">
                  <td
                    class="hidden sm:block font-semibold px-2 sm:px-5 lg:px-6 py-1 sm:py-2 lg:py-3 text-gray-500"
                    style="background-color: rgb(240,242,243)"
                  >
                    CAP
                  </td>
                  <td
                    class="pb-1 sm:py-2 lg:py-3 px-2 sm:px-5 lg:px-6 text-gray-500 sm:text-gray-800"
                  >
                    {{ client.cap }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="text-lg text-gray-900 font-regular">
            Metodo di pagamento
          </div>

          <div class="pb-5 sm:pb-10" v-if="card">
            <table
              class="table-fixed xl:table-auto rounded border-separate sm:border-collapse border sm:border-2 border-gray-200 w-full font-medium text-sm sm:text-md"
            >
              <tbody class="sm:divide-y-2 sm:divide-gray-200">
                <tr class="sm:hidden">
                  <td class="text-gray-900 px-2 pt-2">Nome sulla carta</td>
                </tr>
                <tr class="">
                  <td
                    class="hidden sm:block font-semibold px-2 sm:px-5 lg:px-6 py-1 sm:py-2 lg:py-3 text-gray-500"
                    style="background-color: rgb(240,242,243)"
                  >
                    Nome sulla carta
                  </td>
                  <td
                    class="pb-2 sm:py-2 lg:py-3 px-2 sm:px-5 lg:px-6 text-gray-500 sm:text-gray-800"
                  >
                    {{ card.billing_details.name }}
                  </td>
                </tr>

                <tr class="sm:hidden">
                  <td class="text-gray-900 pl-2">
                    <div class="pt-1 border-t border-gray-200">
                      Dettagli carta
                    </div>
                  </td>
                </tr>
                <tr class="">
                  <td
                    class="hidden sm:table-cell font-semibold px-2 sm:px-5 lg:px-6 py-1 sm:py-2 lg:py-3 text-gray-500"
                    style="background-color: rgb(240,242,243)"
                  >
                    Dettagli carta
                  </td>
                  <td
                    class="pb-2 sm:py-2 lg:py-3 px-2 sm:px-5 lg:px-6 text-gray-500 sm:text-gray-800"
                  >
                    <div class="flex items-center space-x-4">
                      <img
                        :src="'/assets/img/' + card.card.brand + '.png'"
                        class="w-6"
                        alt=""
                      />
                      <span class="capitalize font-bold">
                        {{ card.card.brand }}
                      </span>
                      <span> termina con •••• {{ card.card.last4 }} </span>
                    </div>
                  </td>
                </tr>

                <tr class="sm:hidden">
                  <td class="text-gray-900 pl-2">
                    <div class="pt-1 border-t border-gray-200">Scadenza</div>
                  </td>
                </tr>
                <tr class="">
                  <td
                    class="hidden sm:block font-semibold px-2 sm:px-5 lg:px-6 py-1 sm:py-2 lg:py-3 text-gray-500"
                    style="background-color: rgb(240,242,243)"
                  >
                    Scadenza
                  </td>
                  <td
                    class="pb-2 sm:py-2 lg:py-3 px-2 sm:px-5 lg:px-6 text-gray-500 sm:text-gray-800"
                  >
                    {{ card.card.exp_month }} / {{ card.card.exp_year }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="lg:col-span-2">
        <div
          class="p-4 sm:px-10 sm:py-8 flex flex-col space-y-6 bg-gray-50 lg:bg-transparent"
        >
          <div
            class="text-lg text-gray-900 font-regular border-b border-gray-500 pb-2"
          >
            Dettagli ordine
          </div>
          <div>
            <order-summary :step="step"></order-summary>
          </div>
          <div class="py-5 pb-20 sm:py-20">
            <div v-if="!isSubmitted">
              <div
                @click="initPay()"
                class="bg-blue-500 hover:bg-opacity-70 active:bg-blue-800 text-white rounded p-4 items-center text-2xl cursor-pointer"
              >
                <div
                  class="flex space-x-4 items-center mx-auto w-min unselectable"
                >
                  <i-shield-check classes="h-8 w-8"></i-shield-check>
                  <p class="unselectable whitespace-nowrap">Paga ora</p>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                class="bg-blue-500 bg-opacity-70 text-white rounded p-4 items-center text-2xl cursor-wait"
              >
                <div
                  class="flex space-x-4 items-center mx-auto w-min unselectable"
                >
                  <base-spinner-mini :isLight="true"></base-spinner-mini>
                  <p class="unselectable whitespace-nowrap">Paga ora</p>
                </div>
              </div>
              <div class="text-sm text-gray-500 font-regular leading-5 pt-3">
                Per favore, <strong>rimani in attesa</strong> e
                <strong>non uscire</strong> dalla pagina mentre è in corso il
                pagamento
              </div>
            </div>
            <div
              v-if="!!error"
              class="bg-red-100 text-sm px-4 py-3 border-l-4 border-red-400 mt-10 rounded space-y-2 flex flex-col"
            >
              <div class="flex w-full justify-between items-start">
                <div class="text-gray-900 text-md">
                  Impossibile elaborare il pagamento
                </div>
                <div @click="error = null">
                  <i-times
                    classes="h-4 w-4 hover:bg-white cursor-pointer rounded"
                  ></i-times>
                </div>
              </div>
              <div class="text-gray-600">{{ error.message }}</div>
            </div>
          </div>
        </div>
        <div
          class="sm:hidden h-10 bg-gradient-to-b from-gray-50 to-white"
        ></div>
      </div>
    </div>
    <div v-else>
      <div
        class="px-4 py-8 sm:p-6 sm:py-8 flex flex-col items-center space-y-5"
      >
        <i-check-circle-o class="h-24 w-24 mt-4 text-green-500"></i-check-circle-o>
        <div
          class="flex flex-col items-center text-center space-y-4 text-gray-900"
        >
          <span class="text-2xl font-regular text-green-700">
            Il pagamento è avvenuto con successo!
          </span>
          <span class="text-sm font-regular">
            Grazie per aver acquistato su myNibble.
          </span>
        </div>
        <div class="py-5">
          <router-link to="/services">
            <p
              class="rounded bg-gradient-to-r from-yellow-400 to-yellow-500 px-5 py-2 text-white"
            >
              Torna ai tuoi Servizi
            </p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderSummary from "@/components/checkout/modules/OrderSummary";
import { loadStripe } from "@stripe/stripe-js";

export default {
  name: "CheckoutPay",
  components: {
    OrderSummary,
  },
  props: ["step"],
  data() {
    return {
      isCompleted: false,
      isSubmitted: false,
      stripe: null,
      error: null,
    };
  },
  computed: {
    client() {
      return this.$store.getters["user/loadedClient"];
    },
    card() {
      return this.$store.getters["settings/cards"].find((pm) => {
        return pm.id == this.paymethod;
      });
    },
    cart() {
      return this.$store.getters["payments/cart"];
    },
    paymethod() {
      return this.$store.getters["payments/paymethod"];
    },
  },
  methods: {
    async initPay() {
      this.isSubmitted = true;
      this.error = null;
      this.stripe = await loadStripe("pk_live_orvuU9fj9XS9Gbzge9RIZVCI");

      let intent = await this.paymentIntent();

      if (intent) {
        this.stripe
          .confirmCardPayment(intent.client_secret, {
            payment_method: intent.last_payment_error.payment_method.id,
          })
          .then((result) => {
            this.isSubmitted = false;

            if (result.error) {
              // Show error to your customer
              this.error = result.error;
              // console.log(result.error);
            } else {
              if (result.paymentIntent.status === "succeeded") {
                // The payment is complete!
                this.isCompleted = true;
                this.$store.commit("payments/initCart");
              }
            }
          });
      } else {
        this.isSubmitted = false;
        this.isCompleted = true;
        this.$store.commit("payments/initCart");
      }
    },
    async paymentIntent() {
      let status = false;
      try {
        status = await this.$store.dispatch("payments/newPaymentIntent", {
          items: [...this.cart.items],
          services: [...this.cart.services],
          stripe_id: this.client.stripe_id,
          paymethod_id: this.paymethod,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      return status;
    },
  },
};
</script>
<style>
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
table > tbody > tr > td {
  padding: 0px;
  border-spacing: 0px !important;
}
</style>
