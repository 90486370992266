<template>
    <transition name="slide-top-blur-2">
        <div v-if="!isLoading">
            <div class="grid">
                <div class="mt-5 md:mt-0">
                    <form
                        @submit.prevent="submitForm"
                        @reset.prevent="resetForm"
                    >
                        <div class="shadow sm:rounded-md sm:overflow-hidden">
                            <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div class="w-full lg:w-1/2">
                                    <div class="">
                                        <transition
                                            name="slide-bottom-scale-blur"
                                        >
                                            <label
                                                v-if="password.length > 0"
                                                for="password"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                Vecchia password
                                            </label>
                                        </transition>
                                        <div
                                            class="mt-1 flex rounded-md shadow-sm"
                                        >
                                            <input
                                                type="password"
                                                name="password"
                                                required="required"
                                                v-model.trim="password"
                                                id="password"
                                                class="focus:ring-yellow-500 focus:border-yellow-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
                                                placeholder="Vecchia password"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full lg:w-1/2">
                                    <div class="">
                                        <transition
                                            name="slide-bottom-scale-blur"
                                        >
                                            <label
                                                v-if="new_password.length > 0"
                                                for="new_password"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                Nuova password
                                                <span class="text-gray-400"
                                                    >(Almeno 6 caratteri)</span
                                                >
                                            </label>
                                        </transition>
                                        <div
                                            class="mt-1 flex rounded-md shadow-sm"
                                        >
                                            <input
                                                type="password"
                                                name="new_password"
                                                required="required"
                                                v-model.trim="new_password"
                                                id="new_password"
                                                class="focus:ring-yellow-500 focus:border-yellow-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
                                                placeholder="Nuova password"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full lg:w-1/2">
                                    <div class="">
                                        <transition
                                            name="slide-bottom-scale-blur"
                                        >
                                            <label
                                                v-if="
                                                    confirmed_password.length >
                                                    0
                                                "
                                                for="confirmed_password"
                                                class="block text-sm font-medium text-gray-700"
                                            >
                                                Conferma password
                                            </label>
                                        </transition>
                                        <div
                                            class="mt-1 flex rounded-md shadow-sm"
                                        >
                                            <input
                                                type="password"
                                                name="confirmed_password"
                                                required="required"
                                                v-model.trim="
                                                    confirmed_password
                                                "
                                                id="confirmed_password"
                                                class="focus:ring-yellow-500 focus:border-yellow-500 flex-1 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
                                                placeholder="Conferma password"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="error"
                                    class="text-sm text-red-600 border-l-4 border-red-500 bg-red-100 p-3"
                                >
                                    {{ error }}
                                </div>
                                <div
                                    v-if="success"
                                    class="text-sm text-green-600 border-l-4 border-green-500 bg-green-100 p-3"
                                >
                                    Password modificata con successo
                                </div>
                            </div>
                            <div
                                class="flex px-4 py-3 bg-gray-50 justify-between sm:px-6"
                            >
                                <base-button
                                    type="reset"
                                    label="Reset"
                                    :isLight="true"
                                ></base-button>
                                <base-button
                                    type="submit"
                                    label="Modifica password"
                                    v-if="!submitted"
                                ></base-button>
                                <base-spinner-button
                                    v-else
                                ></base-spinner-button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </transition>
    <div v-if="isLoading" class="flex items-center gap-2">
        <base-spinner-mini></base-spinner-mini> Carico
    </div>
</template>

<script>
export default {
    name: "EditPasswordForm",
    data() {
        return {
            isLoading: true,
            error: null,
            success: false,
            submitted: false,
            password: "",
            new_password: "",
            confirmed_password: "",
        };
    },
    computed: {
        email() {
            return this.$store.getters["user/email"];
        },
    },
    async mounted() {
        this.isLoading = false;
    },
    methods: {
        async submitForm() {
            this.submitted = true;
            this.success = false;
            this.error = null;

            try {
                await this.$store.dispatch("settings/editPassword", {
                    email: this.email,
                    password: this.password,
                    new_password: this.new_password,
                    confirmed_password: this.confirmed_password,
                });

                this.success = true;
                this.resetForm();
            } catch (error) {
                this.submitted = false;
                this.error = error.message || "Something went wrong!";
            }
        },
        resetForm() {
            this.password = "";
            this.new_password = "";
            this.confirmed_password = "";
            this.submitted = false;
        },
    },
};
</script>