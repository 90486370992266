<template>
    <button
        :class="
            isLight
                ? 'text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                : 'text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
        "
        class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md"
    >
        <base-spinner-mini :isLight="!isLight"></base-spinner-mini>
    </button>
</template>

<script>
export default {
    name: "BaseButtonSpinner",
    props: {
        isLight: {
            type: Boolean,
            default: false,
        },
    },
};
</script>