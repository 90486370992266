<template>
  <base-dialog
    :show="!!alertMessage"
    title="Esci"
    @close="confirmLogout"
    @cancel="alertMessage = null"
  >
    <div v-html="alertMessage"></div>
  </base-dialog>
  <div
    @click.prevent="logout()"
    class="flex md:hidden flex-col items-center mt-20"
  >
    <div
      class="flex flex-row items-center py-2 px-4 text-gray-800 cursor-pointer"
    >
      <span class="text-2xl">Esci</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        class="w-5 ml-2"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExitButton",
  data() {
    return {
      alertMessage: null,
    };
  },
  methods: {
    logout() {
      this.alertMessage = "Sei sicuro di voler uscire?";
    },
    async confirmLogout() {
      this.alertMessage = null;
      await this.$store.dispatch("logout");
      this.$router.replace("/login");
    },
  },
};
</script>
