<template>
    <div class="px-4 py-5 bg-white sm:p-6 flex flex-col">
        <div class="flex items-start">
            <div class="flex items-center h-5 mr-4">
                <input
                    id="offers"
                    name="offers"
                    type="checkbox"
                    v-model="autorenewal"
                    @change="setAutorenewal"
                    class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
            </div>
            <div class="text-sm flex flex-col">
                <label
                    for="offers"
                    class="font-bold text-gray-700 flex flex-shrink"
                    >Rinnovo automatico
                    <svg
                        :content="`Viene rinnovato automaticamente il servizio senza che tu debba alzare un dito.<br> Prima che il tuo abbonamento scada, ti verrà addebitata la tariffa di rinnovo.<br> Ti ricorderemo in anticipo il pagamento imminente tramite email.<br> Puoi annullare il rinnovo automatico aprendo un ticket o contattando il servizio clienti`"
                        v-tippy="{ placement: 'top', arrow: true, allowHTML: true }"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="w-4 ml-1"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </label>
                <p class="text-gray-500">
                    Alla scadenza verrà rinnovato automaticamente il servizio
                    per il periodo desiderato.
                </p>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "AutomaticRenewal",
    emits: ["setAutorenewal"],
    data() {
        return {
            autorenewal: false,
            tooltip:
                "Viene rinnovato automaticamente il servizio senza che tu debba alzare un dito.<br> Prima che il tuo abbonamento scada, ti verrà addebitata la tariffa di rinnovo.<br> Ti ricorderemo in anticipo il pagamento imminente tramite email.<br> Puoi annullare il rinnovo automatico aprendo un ticket o contattando il servizio clienti",
        };
    },
    methods: {
        setAutorenewal() {
            this.$emit("setAutorenewal", this.autorenewal);
        },
    },
};
</script>