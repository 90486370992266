import clickOutside from "../directives/click-ouside.js";
import VueTippy from 'vue-tippy'
import { SetupCalendar, Calendar, DatePicker } from 'v-calendar';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive("click-outside", clickOutside);

    Vue.use(VueTippy, {
      directive: "tippy", // => v-tippy
      flipDuration: 0,
      popperOptions: {
        modifiers: {
          preventOverflow: {
            enabled: true
          }
        }
      }
    });

    Vue.use(SetupCalendar, {})
      // Use the components
      .component('v-calendar', Calendar)
      .component('v-date-picker', DatePicker)
  }
};

export default GlobalDirectives;
