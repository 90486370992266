import TheHeader from '@/layout/TheHeader'
import TheFooter from '@/layout/TheFooter'
import BlankView from '@/views/misc/BlankView'
import BlankTitle from '@/views/misc/BlankTitle'
// import SampleView from '@/views/misc/SampleView'
import BlankSecondaryTitle from '@/views/misc/BlankSecondaryTitle'

import PaymentsView from '@/views/payments/PaymentsView'
import Checkout from '@/views/payments/modules/checkout/Checkout'

import InvoicesList from '@/views/payments/modules/invoices/InvoicesList'
// import TheInvoice from '@/views/payments/modules/invoices/TheInvoice'

// Payments Titles
import InvoicesTitle from '@/views/payments/modules/pageTitles/InvoicesTitle'
import InvoiceSecondaryTitle from '@/views/payments/modules/pageTitles/InvoiceSecondaryTitle'
import CheckoutTitle from '@/views/payments/modules/pageTitles/CheckoutTitle'
import CheckoutSecondaryTitle from '@/views/payments/modules/pageTitles/CheckoutSecondaryTitle'

export default [
    {
        path: '/payments',
        name: 'payments',
        components: {
            header: TheHeader,
            default: PaymentsView,
            footer: TheFooter
        },
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                redirect: '/payments/invoices'
            },
            {
                path: 'invoices',
                name: 'invoices',
                components: {
                    pageTitle: BlankTitle,
                    secondaryTitle: BlankSecondaryTitle,
                    default: BlankView
                },
                children: [
                    {
                        path: '',
                        components: {
                            pageTitle: InvoicesTitle,
                            secondaryTitle: InvoiceSecondaryTitle,
                            default: InvoicesList
                        }
                    }
                ]
            },
            {
                path: 'checkout',
                name: 'checkout',
                components: {
                    pageTitle: CheckoutTitle,
                    default: Checkout,
                    secondaryTitle: CheckoutSecondaryTitle,
                }
            }
        ]
    }
]