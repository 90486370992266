<template>
  <div v-if="!isLoading">
    <transition name="slide-top-blur-2">
      <div
        v-if="!isLoading"
        class="flex flex-col bg-gray-50 shadow-xl rounded-md px-3 sm:px-4 py-3 mt-3 mb-8"
      >
        <div
          v-if="files.length > 0"
          class="flex-grow flex my-2 border-b border-gray-700 items-center hideScrollbar overflow-x-scroll"
        >
          <div
            v-for="(item, index) in menu"
            :key="index"
            @click="toggleView(item.view)"
            :class="
              view == item.view
                ? 'border-gray-700 text-gray-900'
                : 'border-gray-50 text-gray-400 hover:border-gray-500'
            "
            class="border-b-4 text-md px-5 py-2 hover:bg-gray-100 hover:text-gray-500 cursor-pointer"
          >
            {{ item.name }}
          </div>
        </div>
        <div
          v-else
          class="flex-grow flex justify-between text-gray-500 font-medium py-4"
        >
          Nessun documento ancora disponibile
        </div>
        <div v-for="(item, indexMenu) in menu" :key="indexMenu">
          <div v-if="view == item.view">
            <div v-if="item.elements.length > 0">
              <base-file-item
                v-for="(file, index) in item.elements"
                :key="index"
                :file="file"
              ></base-file-item>
            </div>
            <div
              v-else
              class="flex-grow flex justify-between text-gray-500 font-medium py-4"
            >
              Nessun documento di tipo "{{ item.name }}" ancora disponibile
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div v-else>
    <base-spinner-mini></base-spinner-mini>
  </div>
</template>

<script>
import BaseFileItem from "@/ui/BaseFileItem";

export default {
  name: "FilesList",
  components: { BaseFileItem },
  data() {
    return {
      isLoading: true,
      isUpdating: true,
      view: "documents",
    };
  },
  computed: {
    files() {
      return this.$store.getters["files/files"];
    },
    documents() {
      return this.files && this.files.length != 0
        ? this.files.filter((file) => {
            return file.tag == "document" || file.tag == "client";
          })
        : [];
    },
    invoices() {
      return this.files && this.files.length != 0
        ? this.files.filter((file) => {
            return file.tag == "invoice";
          })
        : [];
    },
    messages() {
      return this.files && this.files.length != 0
        ? this.files.filter((file) => {
            return file.tag == "message";
          })
        : [];
    },
    contracts() {
      return this.files && this.files.length != 0
        ? this.files.filter((file) => {
            return file.tag == "contract";
          })
        : [];
    },
    menu() {
      return [
        {
          view: "documents",
          name: "Documenti",
          elements: this.documents,
        },
        {
          view: "invoices",
          name: "Fatture",
          elements: this.invoices,
        },
        {
          view: "contracts",
          name: "Contratti",
          elements: this.contracts,
        },
        {
          view: "tickets",
          name: "Ticket",
          elements: this.messages,
        },
      ];
    },
  },
  async mounted() {
    this.setBreadcrumbs();
    await this.loadFiles();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Documenti e contratti", link: "/files" },
      ]);
    },
    async loadFiles() {
      if (this.files && this.files.length == 0) {
        this.isLoading = true;
        this.isUpdating = true;
      } else {
        this.isLoading = false;
        this.isUpdating = true;
      }

      try {
        await this.$store.dispatch("files/getUserFiles");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      this.isLoading = false;
      this.isUpdating = false;
    },
    toggleView(view) {
      this.view = view;
    },
  },
};
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
