<template>
  <transition name="slide-top-blur">
    <div class="mt-5 md:mt-0">
      <div class="shadow rounded-md sm:overflow-hidden" v-if="status == 'progress'">
        <billing-info></billing-info>

        <order-summary :services="services" :items="items"></order-summary>

        <paymethod-selection @changeMethod="changeMethod"></paymethod-selection>

        <automatic-renewal v-if="services.length > 0 && checkout.payment_method"
          @setAutorenewal="setAutorenewal"></automatic-renewal>

        <div class="px-4 py-3 bg-gray-50 sm:px-6 flex">
          <!-- actions -->
          <div class="flex-grow flex justify-end" v-if="checkout.payment_method">
            <base-button v-if="!submitted" @click.prevent="pay()" :label="'Paga ' + price(granTotal, true)"
              :hasIcon="true">
              <path fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd" />
            </base-button>
            <base-spinner-button v-else></base-spinner-button>
          </div>
          <div class="flex-grow flex justify-end" v-else>
            <div class="py-1 sm:py-2 px-2 sm:px-4 text-right">
              Per procedere è necessario inserire un metodo di pagamento valido
            </div>
          </div>
        </div>
      </div>
      <div class="shadow rounded-md sm:overflow-hidden" v-if="status == 'completed'">
        <payment-completed :autorenweal="checkout.autorenewal"></payment-completed>
      </div>
    </div>
  </transition>
</template>

<script>
import BillingInfo from "./modules/BillingInfo";
import OrderSummary from "./modules/OrderSummary";
import PaymethodSelection from "./modules/PaymethodSelection";
import AutomaticRenewal from "./modules/AutomaticRenewal";
import PaymentCompleted from "./modules/PaymentCompleted";

export default {
  name: "TheCheckout",
  components: {
    BillingInfo,
    OrderSummary,
    PaymethodSelection,
    AutomaticRenewal,
    PaymentCompleted,
  },
  props: {
    services: {
      type: Array,
      default() {
        return [];
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      submitted: false,
      status: "progress",
      checkout: {
        payment_method: null,
        autorenewal: false,
        services: [...this.services],
        items: [...this.items],
        checksumImport: 0,
      },
    };
  },
  computed: {
    subtotal() {
      let price = 0;

      // adding service price
      this.services.forEach((service) => {
        price += service.item.price * service.billing_frequency;
      });

      // adding item price
      this.items.forEach((item) => {
        price += item.price * item.quantity;
      });

      return price;
    },
    discount() {
      let discount = 0;

      // adding service price
      this.services.forEach((service) => {
        if (service.discount != 0)
          discount +=
            (service.item.price *
              service.billing_frequency *
              service.discount) /
            100;
      });

      return discount;
    },
    granTotal() {
      if (this.discount == 0) return this.subtotal;
      else {
        return parseFloat(this.subtotal) - parseFloat(this.discount);
      }
    },
    client() {
      return this.$store.getters["user/loadedClient"];
    },
    vat() {
      return this.$gf.getVATbyCode(this.client.vat_code)['multiplier']
    },
  },
  methods: {
    price(priceVAT, invertedSymbol = false) {
      // const priceVAT = this.vat * price
      if (invertedSymbol) return "€" + priceVAT.toFixed(2);
      else return priceVAT.toFixed(2) + " €";
    },
    changeMethod(method_id) {
      this.checkout.payment_method = method_id;
    },
    setAutorenewal(autorenewal) {
      this.checkout.autorenewal = autorenewal;
    },
    async pay() {
      // this.isLoading = true;
      this.checkout.checksumImport = this.granTotal;

      this.submitted = true;

      try {
        this.status = await this.$store.dispatch(
          "payments/completePayment",
          this.checkout
        );
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      this.submitted = false;
    },
  },
};
</script>

<style>
.slide-top-blur-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
