<template>
  <div
    class="px-4 py-1 rounded-md bg-gray-100 hover:bg-yellow-300 shadow-md hover:shadow-lg cursor-pointer transform hover:scale-105 transition-all duration-200 ease-in-out"
  >
    <div class="flex flex-col items-center">
      <img :src="img" alt="" class="w-24" />
      <p class="text-2xl font-bold text-gray-900 whitespace-nowrap">
        {{ name }} <span class="text-gray-500">CRONO</span>
      </p>
      <div
        class="flex flex-row space-x-1 my-3 text-sm md:text-md whitespace-nowrap"
      >
        <arrow-circle-down
          :classes="'h-4 md:h-5 w-4 md:w-5'"
        ></arrow-circle-down>
        <p>{{ rate.down }} Mbps</p>
        <arrow-circle-up :classes="'h-4 md:h-5 w-4 md:w-5'"></arrow-circle-up>
        <p>{{ rate.up }} Mbps</p>
      </div>
      <div class="leading-5 text-center">
        <p class="text-sm text-gray-600">€ {{ price.daily }} al giorno</p>
        <p class="text-sm text-gray-600">€ {{ price.monthly }} al mese</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileCard",
  props: ["img", "name", "rate", "price"],
};
</script>
