import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      invoices: [],
      items: [],
      loadedInvoice: null,
      updateDatetime: null,
      cart: {
        services: [],
        items: []
      },
      paymethod: null,
      paymentCompleted: false
    };
  },
  mutations,
  actions,
  getters
};