<template>
    <div class="bg-gray-100">
        <div class="max-w-7xl mx-auto">
            This is a Sample
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: "SampleView"
}
</script>