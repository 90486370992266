<template>
  <div>
    <transition name="slide-top-blur-2">
      <div v-if="!isLoading">
        <base-ticket-card @closeTicket="setStatusClosed()">
          <new-message-form
            v-if="loadedTicket.status != 'closed'"
            :ticket="loadedTicket"
            @refresh="loadTicket()"
          ></new-message-form>
          <div
            v-else
            class="p-2 md:p-4 text-center text-gray-400 font-light text-sm"
          >
            Questo ticket è chiuso, quindi è di sola lettura. Non puoi più
            rispondere. Se devi fornire altre informazioni, apri un nuovo ticket
            e includi il numero di questo per riferimento.
          </div>
        </base-ticket-card>
      </div>
    </transition>
    <transition-group v-if="!isLoading" name="slide-left-blur-2">
      <div v-for="message in loadedTicket.messages" :key="message.id">
        <base-message :message="message"></base-message>
      </div>
    </transition-group>
    <div v-if="isLoading">Carico...</div>
  </div>
</template>

<script>
import BaseTicketCard from "@/ui/tickets/BaseTicketCard";
import NewMessageForm from "@/components/forms/NewMessageForm";
import BaseMessage from "@/ui/tickets/messages/BaseMessage";

export default {
  name: "TheTicket",
  components: { BaseTicketCard, NewMessageForm, BaseMessage },
  props: ["id"],
  data() {
    return {
      isLoading: true,
      isUpdating: true,
    };
  },
  computed: {
    loadedTicket() {
      return this.$store.getters["tickets/loadedTicket"];
    },
    subcategories() {
      return this.$store.getters["tickets/subcategories"];
    },
  },
  async mounted() {
    this.setBreadcrumbs();

    if (this.subcategories.length == 0) {
      await this.loadSubcategories();
    }

    await this.loadTicket();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Supporto", link: "/support" },
        { title: "I tuoi ticket", link: "/support/tickets/" },
        { title: `#${this.id}`, link: `/support/tickets/${this.id}` },
      ]);
    },
    async loadTicket() {
      if (this.loadedTicket && this.loadedTicket.length == 0) {
        this.isLoading = true;
        this.isUpdating = true;
      } else {
        this.isUpdating = true;
      }

      try {
        await this.$store.dispatch("tickets/getTicket", {
          id: this.id,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      this.isLoading = false;
      this.isUpdating = false;
    },
    async loadSubcategories() {
      this.isUpdating = true;
      try {
        await this.$store.dispatch("tickets/getSubcategories");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isUpdating = false;
    },
    async setStatusClosed() {
      this.isUpdating = true;
      try {
        await this.$store.dispatch("tickets/setStatusClosed", {
          id: this.loadedTicket.id,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isUpdating = false;
    },
  },
};
</script>
