export default {
  async editPassword(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/edit-password`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
      body: JSON.stringify({
        email: payload.email,
        password: payload.password,
        new_password: payload.new_password,
        confirmed_password: payload.confirmed_password,
      })
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('editPassword', responseData);
  },

  async getCardsSaved(context) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/cards`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    console.log('getCardsSaved', responseData);

    context.commit('setCards', responseData.cards);
  },

  async getSubscriptions(context) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/subscriptions`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getSubscriptions', responseData);

    context.commit('setSubscriptions', responseData.subscriptions.data);
  },

  async newCardSetupIntent(context) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/cards/intent`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    console.log('newCardSetupIntent', responseData);

    context.commit('setNewCardSetupIntent', responseData.intent);
  },

  async storePaymentMethod(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/cards/create`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
      body: JSON.stringify({
        payment_method: payload.payment_method
      })
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('storePaymentMethod', responseData);
    context.commit('setCards', responseData.cards);
  },

  async setDefaultCard(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/api/user/cards/set-default`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
      body: JSON.stringify({
        payment_method: payload.id
      })
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('setDefaultCard', responseData);
    context.commit('setCards', responseData.cards);
  },

  async deleteCard(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/cards/delete`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
      body: JSON.stringify({
        payment_method: payload.id
      })
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('setDefaultCard', responseData.cards);
    context.commit('setCards', responseData.cards);
  },
  async sendEmailVerification(context) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/sendEmailVerification`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
    });
    // console.log('sendEmailVerification start' );

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('sendEmailVerification', responseData);
  },



};
