<template>
    <div v-if="isLoading">
        <skeleton-base class="hidden md:block"></skeleton-base>
        <skeleton-base-mobile class="md:hidden"></skeleton-base-mobile>
    </div>
    <base-layout :secondaryMenu="secondaryMenu" v-else-if="hasService">
        <template #header>
            <router-view name="pageTitle"></router-view>
        </template>

        <template #secondaryMenu>
            <router-view name="secondaryTitle"></router-view>
        </template>

        <template #menu>
            <side-menu
                :additional="additional"
                :isPrepaid="isPrepaid"
            ></side-menu>
        </template>

        <template #main>
            <router-view name="default"> </router-view>
        </template>
    </base-layout>
    <div v-else>Not Found</div>
</template>


<script>
import SideMenu from "./SideMenu";

export default {
    name: "VoipService",
    components: {
        SideMenu,
    },
    props: ["id"],
    data() {
        return {
            isLoading: true,
        };
    },
    computed: {
        secondaryMenu() {
            return [
                {
                    label: "Riepilogo del servizio",
                    link: this.relativeRoute("riepilogue"),
                },
                {
                    label: "In scadenza",
                    link: this.relativeRoute("expiring"),
                    enable: !this.isPrepaid,
                },
                {
                    label: "Ricarica",
                    link: this.relativeRoute("recharge"),
                    enable: this.isPrepaid,
                },
                {
                    label: "Servizi opzionali",
                    link: this.relativeRoute("additional"),
                    enable: this.additional,
                },
                {
                    label: "Apri ticket",
                    link: this.relativeRoute("newTicket"),
                },
                {
                    label: "Ticket",
                    link: this.relativeRoute("ticketList"),
                },
                {
                    label: "Registro Chiamate",
                    link: this.relativeRoute("registry"),
                    enable: this.isPrepaid
                },
            ];
        },
        services() {
            return this.$store.getters["services/services"];
        },
        service() {
            return this.$store.getters["services/loadedService"];
        },
        hasService() {
            return (
                !this.isLoading && this.$store.getters["services/loadedService"]
            );
        },
        additional() {
            return this.$store.getters["services/services"].filter((el) => {
                return el.main_id == this.id;
            }).length > 0
                ? true
                : false;
        },
        isPrepaid() {
            return this.service.details.tariff_plan == "pre" ? true : false;
        },
    },
    async created() {
        await this.loadService();
        if (this.services.length == 0) await this.loadServices();
        this.isLoading = false;
    },
    methods: {
        async loadService() {
            try {
                await this.$store.dispatch("services/getService", {
                    id: this.id,
                });
            } catch (error) {
                this.error = error.message || "Something went wrong!";
            }
        },
        async loadServices() {
            try {
                await this.$store.dispatch("services/getServices");
            } catch (error) {
                this.error = error.message || "Something went wrong!";
            }
        },
        relativeRoute(relative = "") {
            return "/services/voip/" + this.id + "/" + relative;
        },
    },
};
</script>