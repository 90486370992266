<template>
  <div class="flex items-center justify-between">
    <div class="text-3xl font-bold">
      <span> Ticket </span>
    </div>
    <div
      v-if="hasTicket"
      class="flex items-center text-gray-500 text-3xl font-bold"
    >
      <i-ticket-o classes="w-10 ml-2 mr-1 "></i-ticket-o>
      <span class="text-2xl"> #{{ loadedTicket.id }} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheTicketTitle",
  data() {
    return {
      isUpdating: false,
    };
  },
  computed: {
    loadedTicket() {
      return this.$store.getters["tickets/loadedTicket"];
    },
    hasTicket() {
      return this.loadedTicket != null;
    },
  },
  methods: {
    async loadTicket() {
      this.isUpdating = true;
      try {
        await this.$store.dispatch("tickets/getTicket", {
          id: this.loadedTicket.id,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isUpdating = false;
    },
  },
};
</script>
