<template>
  <div>
    <base-dialog
      :show="!!alertMessage"
      title="Verifica indirizzo"
      @close="confirmSending"
      @cancel="this.alertMessage = null"
    >
      <div v-html="alertMessage"></div>
    </base-dialog>
    <simple-title title="Notifiche"></simple-title>
    <div v-show="!isLoading">
      <transition name="slide-top-blur-2">
        <div
          v-if="!isLoading"
          class="flex flex-col space-y-4 divide-y divide-gray-200 bg-gray-50 shadow-xl rounded-md px-4 py-3 mt-3 mb-8"
        >
          <!-- Email -->

          <!-- not Hover -->
          <riepilogue-table-row
            title="Email"
            :value="email"
            @mouseleave="isHover = false"
            @mouseover="isHover = true"
            v-if="!isHover"
          >
            <path
              v-if="email_verified"
              class="text-green-500"
              fill-rule="evenodd"
              d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
              clip-rule="evenodd"
            />

            <path
              v-else
              class="text-yellow-500"
              fill-rule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
            />
          </riepilogue-table-row>

          <!-- Hover && not Verified -->
          <riepilogue-table-row
            content="Cliccando, ti arriverà una mail con un link per confermare e verificare il tuo indirizzo di posta elettronica. "
            v-tippy="{ placement: 'top-end', arrow: false }"
            class="cursor-pointer underline"
            title="Email"
            value="Invia link di verifica indirizzo email"
            @click="sendEmailVerification"
            @mouseleave="isHover = false"
            @mouseover="isHover = true"
            v-if="isHover && !email_verified"
          >
            <path
              fill-rule="evenodd"
              d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
              clip-rule="evenodd"
            />
          </riepilogue-table-row>

          <!-- Hover && Verified -->
          <riepilogue-table-row
            valueClass="text-green-400"
            title="Email"
            value="Email verificata correttamente"
            @mouseleave="isHover = false"
            @mouseover="isHover = true"
            v-if="isHover && email_verified"
          >
            <path
              class="text-green-400"
              fill-rule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </riepilogue-table-row>

          <div
            v-if="isUpdating"
            class="flex flex-row flex-grow justify-end pt-3 text-gray-400 text-sm"
          >
            <p class="mr-2" v-html="liveStatus"></p>
            <i-check
              v-if="liveStatus.includes('Email inviata correttamente')"
              classes="h-5 w-5 text-green-500"
            ></i-check>

            <base-spinner-mini v-else></base-spinner-mini>
          </div>

          <!-- mobile SMS -->
          <riepilogue-table-row title="SMS" :value="mobile">
            <path
              fill-rule="evenodd"
              d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </riepilogue-table-row>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import RiepilogueTableRow from "./../../../ui/services/RiepilogueTableRow";

export default {
  name: "Notifications",
  components: {
    RiepilogueTableRow,
  },
  data() {
    return {
      isLoading: true,
      isUpdating: false,
      isHover: false,
      alertMessage: null,
      liveStatus: "",
    };
  },
  computed: {
    email() {
      return this.$store.getters["user/email"];
    },
    mobile() {
      return this.$store.getters["user/mobile"];
    },
    email_verified() {
      return this.$store.getters["user/email_verified_at"] == null
        ? false
        : true;
    },
  },
  async mounted() {
    this.setBreadcrumbs();
    // console.log(this.mobile);
    await this.loadUserProfile();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Impostazioni", link: "/settings" },
        {
          title: "Notifiche",
          link: "/settings/notifications",
        },
      ]);
    },
    sendEmailVerification() {
      this.alertMessage =
        "Confermi di voler procedere alla verifica dell'indirizzo email?";
    },
    async confirmSending() {
      this.alertMessage = null;
      this.liveStatus = `invio mail a ${this.email} ...`;
      this.isUpdating = true;
      try {
        await this.$store.dispatch("settings/sendEmailVerification");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.liveStatus = `<span class="text-green-500"> Email inviata correttamente!</span>`;

      setTimeout(() => {
        this.isUpdating = false;
      }, 1500);
    },
    async loadUserProfile() {
      if (this.userProfile && this.userProfile.length == 0) {
        this.isLoading = true;
        this.isUpdating = true;
      } else {
        this.isLoading = false;
        this.isUpdating = true;
      }

      try {
        await this.$store.dispatch("user/setClient");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      this.isLoading = false;
      this.isUpdating = false;
    },
  },
};
</script>
