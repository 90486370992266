<template>
  <transition name="slide-top-blur-4">
    <div v-if="!isLoading">
      <simple-title
        title="Abbonamenti automatizzati"
        :isUpdating="isUpdating"
        :keepTheTitle="true"
      ></simple-title>

      <div
        class="flex flex-col space-y-4 bg-gray-50 shadow-lg rounded-md px-4 py-3"
      >
        <div
          v-if="subscriptions.length == 0"
          class="flex text-sm py-2 text-md text-gray-400"
        >
          Non ci sono sottoscrizione attive
        </div>
        <div v-else>
          <div
            v-for="subscription in subscriptions"
            :key="subscription.id"
            class="flex items-center text-sm"
          >
            <div
              class="flex-shrink bg-gradient-to-r from-yellow-400 to-yellow-500 rounded-md"
            >
              <i-clock-o classes="w-12 p-1"></i-clock-o>
            </div>
            <div class="flex-grow pl-2 sm:pl-3">
              <div
                class="col-span-1 flex flex-col space-y-1 justify-start text-left font-medium text-md text-gray-900"
              >
                <div>
                  <span class="uppercase">
                    {{ subscription.items.data[0].plan_product.name }}
                  </span>
                  <span
                    v-if="
                      subscription.items.data.length > 1 &&
                        subscription.items.data.length < 3
                    "
                    class="lowercase"
                  >
                    e 1 altro ...
                  </span>
                  <span
                    v-if="subscription.items.data.length > 2"
                    class="lowercase"
                  >
                    e
                    {{ subscription.items.data.length - 1 }}
                    altri ...
                  </span>
                </div>
              </div>
              <!-- {{ subscription.status }} -->
              <div
                class="col-span-1 flex items-center justify-start text-gray-400 font-light"
              >
                Prossima scadenza in data
                {{ computedTimestamp(subscription.current_period_end) }}
                per
                {{ getSubscriptionAmount(subscription) }} €
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <div v-if="isLoading" class="flex py-4">
    <base-spinner-mini></base-spinner-mini>
    <span class="ml-2 text-gray-500">
      Carico sottoscrizioni automatizzate
    </span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "SubscriptionsList",
  data() {
    return {
      isLoading: true,
      isUpdating: false,
    };
  },
  computed: {
    subscriptions() {
      return this.$store.getters["settings/subscriptions"];
    },
  },
  async mounted() {
    await this.loadUserSubscriptions();
  },
  methods: {
    async loadUserSubscriptions() {
      if (this.subscriptions && this.subscriptions.length == 0) {
        this.isLoading = true;
        this.isUpdating = true;
      } else {
        this.isLoading = false;
        this.isUpdating = true;
      }

      try {
        await this.$store.dispatch("settings/getSubscriptions");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      this.isLoading = false;
      this.isUpdating = false;
    },
    translate(key) {
      switch (key) {
        case "month":
          return "mesi";
        case "year":
          return "anno";
        default:
          return "";
      }
    },
    computedTimestamp(ts) {
      return moment
        .unix(ts)
        .locale("it")
        .format("DD MMM");
    },
    getSubscriptionAmount(subscription) {
      let price = 0;
      subscription.items.data.forEach((item) => {
        price += item.plan.amount;
      });

      return (price / 100).toFixed(2);
    },
  },
};
</script>
