<template>
  <div v-if="ticket" class="grid mt-5 md:mt-0 shadow sm:rounded-md sm:overflow-hidden">
    <div class="px-3 sm:px-6 py-4 bg-white">
      <div class="grid grid-cols-2 items-center">
        <div class="flex text-md sm:text-lg items-center">
          Status
          <base-tag-status
            class="ml-2"
            :status="ticket.status"
          ></base-tag-status>
        </div>
        <div class="text-right text-sm sm:text-base">
          Aperto il {{ dateFormatted(ticket.created_at) }}
        </div>
      </div>
    </div>
    <div
      class="px-3 sm:px-6 py-2 bg-gray-50 text-gray-700 shadow-inner"
      v-if="isTicketable"
    >
      <div
        class="grid grid-cols-2 justify-between text-sm sm:text-base"
      >
        <div class="flex flex-row items-center space-x-2">
          <p>Servizio</p>
        </div>
        <router-link
          :to="absoluteRoute()"
          class="hover:text-yellow-500"
          content="Vai al servizio"
          v-tippy="{ placement: 'bottom-end', arrow: false }"
        >
          <div class="flex flex-row items-center space-x-2 justify-end">
            <p class="uppercase" v-if="ticket.service">
              {{ ticket.service.name }}
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                v-if="ticket.service.type == 'Internet'"
                fill-rule="evenodd"
                d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
                clip-rule="evenodd"
              />
              <path
                v-if="ticket.service.type == 'Voip'"
                d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
              />
            </svg>
          </div>
        </router-link>
      </div>
    </div>
    <div class="px-3 sm:px-6 py-4 bg-white">
      <div class="grid grid-cols-1">
        <div class="flex text-md md:text-lg text-gray-500">
          {{ getSubcategory(ticket.subcategory_id).name }}
        </div>
        <div class="flex text-2xl">
          {{ ticket.subject }}
        </div>
      </div>
    </div>
    <div class="px-3 sm:px-6 pb-4 bg-white border-b">
      <div class="flex items-center ">
        <div class="flex-1">
          <div v-if="takenCharge" class="flex text-sm text-gray-400">
            Preso in carico da {{ technician }}
          </div>
          <div v-else class="flex text-md text-gray-300">
            In attesa di essere preso in carico
          </div>
        </div>
        <div class="flex-1">
          <span
            class="grid justify-end text-gray-600 text-right text-sm cursor-pointer hover:text-yellow-500"
            @click="closeTicket()"
            v-if="ticket.status != 'closed'"
          >
            <span v-if="!isUpdating">
              Hai risolto il problema? Chiudi il ticket!
            </span>
            <span v-else>
              <base-spinner-mini></base-spinner-mini>
            </span>
          </span>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import moment from "moment";
import BaseTagStatus from "./BaseTagStatus";

export default {
  name: "BaseTicketCard",
  components: { BaseTagStatus },
  emits: ["closeTicket"],
  data() {
    return {
      isUpdating: false,
    };
  },
  computed: {
    ticket() {
      return this.$store.getters["tickets/loadedTicket"];
    },
    subcategories() {
      return this.$store.getters["tickets/subcategories"];
    },
    takenCharge() {
      return this.ticket.admin_id == null ? false : true;
    },
    isTicketable() {
      return this.ticket.ticketable_id == null ? false : true;
    },
    technician() {
      if (this.takenCharge) {
        return this.ticket.admin.name;
      } else {
        return "";
      }
    },
  },
  methods: {
    getSubcategory(id) {
      return this.subcategories.find((category) => {
        return category.id == id;
      });
    },
    dateFormatted(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    closeTicket() {
      this.isUpdating = !this.isUpdating;
      this.$emit("closeTicket");
    },
    absoluteRoute() {
      if (this.ticket && this.ticket.service)
        return `/services/${this.ticket.service.type.toLowerCase()}/${
          this.ticket.service.id
        }/riepilogue`;
      else return "";
    },
  },
};
</script>
