<template>
  <div>
    <base-dialog :show="!!error" :title="errorTitle" @close="handleError">
      <div v-html="error"></div>
    </base-dialog>
    <base-spinner v-if="isLoading"></base-spinner>
    <div
      class="min-h-screen flex flex-col space-y-4 items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8"
    >
      <div class="max-w-md w-full space-y-8">
        <div :class="isLoading ? 'blur-scale-2' : ''">
          <img
            class="mx-auto h-16 w-auto py-3 px-1 border-transparent rounded-2xl bg-gray-900 shadow-xl"
            src="/assets/logos/nibble.svg"
            alt="logo"
          />
          <h2 class="mt-6 text-center text-2xl font-normal text-gray-900">
            Recupera la tua password
          </h2>
          <p class="mt-2 text-center text-sm text-gray-600">
            O
            <router-link
              to="/"
              class="font-medium text-yellow-500 hover:text-yellow-400"
            >
              torna alla pagina di Accesso
            </router-link>
          </p>
        </div>
      </div>
      <div class="text-center" :class="isLoading ? 'blur-scale-3' : ''">
        <p class="text-gray-800 text-sm">
          Se hai dimenticato la password del tuo account, quest'area ti permette
          di recuperarla.
        </p>
        <p class="text-gray-500 text-xs">
          Se non ricordi l'indirizzo email associato al tuo account contatta il
          servizio clienti Nibble.
        </p>
      </div>
      <div class="max-w-md w-full">
        <div v-if="!isSubmitted">
          <form class="space-y-6" @submit.prevent="submitForm">
            <input type="hidden" name="remember" value="true" />
            <div
              :class="isLoading ? 'blur-scale-3' : ''"
              class="rounded-md shadow-sm -space-y-px"
            >
              <div>
                <label for="email-address" class="sr-only">Email address</label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required
                  v-model.trim="email"
                  class="
                    appearance-none
                    relative
                    block
                    w-full
                    px-3
                    py-2
                    border border-gray-300
                    placeholder-gray-500
                    text-gray-900
                    rounded-md
                    focus:outline-none
                    focus:ring-yellow-500
                    focus:border-yellow-500
                    focus:z-10
                    sm:text-sm
                  "
                  placeholder="Indirizzo Email"
                />
              </div>
            </div>
            <div
              :class="isLoading ? 'blur-scale-3' : ''"
              v-if="!formIsValid"
              class="
                text-sm text-red-600
                border-l-4 border-red-500
                bg-red-100
                p-3
              "
            >
              Inserisci un indirizzo email e una password validi.
            </div>
            <div :class="isLoading ? 'blur-scale-4' : ''">
              <button
                type="submit"
                class="
                  group
                  relative
                  w-full
                  flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  text-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-yellow-500
                  hover:bg-yellow-700
                "
              >
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                  <!-- Heroicon name: lock-closed -->
                  <svg
                    class="h-5 w-5 text-yellow-400 group-hover:text-yellow-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                Prosegui
              </button>
            </div>
          </form>
        </div>
        <div v-else class="space-y-6">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              class="text-green-500 w-12 mx-auto"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div>
            <p>
              Ti abbiamo inviato un'email con le istruzioni per reimpostare la
              password.
            </p>
            <p class="text-gray-500 text-sm">
              Verifica che l'email non sia finita nelle cartelle di posta
              indesiderata o di SPAM.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      formIsValid: true,
      isLoading: false,
      error: null,
      errorTitle: "An error occurred",
      isSubmitted: false,
    };
  },
  methods: {
    async submitForm() {
      this.formIsValid = true;
      if (this.email === "" || !this.email.includes("@")) {
        this.formIsValid = false;
        return;
      }

      this.isLoading = true;

      const actionPayload = {
        email: this.email,
      };

      try {
        // do Login
        await this.$store.dispatch("forgotPassword", actionPayload);
        this.isSubmitted = true;
      } catch (err) {
        this.error = err.message || "Failed to authenticate, try later.";
        this.errorTitle = err.title || "An error occurred";
      }

      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
.blur-scale-2 {
  opacity: 0.8;
  filter: blur(4px);
  transform: scale(0.9);
  transition: all 0.2s linear;
}
.blur-scale-3 {
  opacity: 0.8;
  filter: blur(4px);
  transform: scale(0.9);
  transition: all 0.3s linear;
}
.blur-scale-4 {
  opacity: 0.8;
  filter: blur(4px);
  transform: scale(0.9);
  transition: all 0.4s linear;
}
</style>
