<template>
  <base-dialog
    :show="!!alertMessage"
    title="Esci"
    @close="confirmLogout"
    @cancel="alertMessage = null"
  >
    <div v-html="alertMessage"></div>
  </base-dialog>
  <transition name="fade">
    <div
      v-show="isOpen"
      @click="toggleMenu"
      class="md:hidden fixed h-screen w-screen bg-gray-500 bg-opacity-60 z-40 transition-all duration-500 bottom-0 left-0"
    ></div>
  </transition>
  <div
    :class="isOpen ? '' : ' translate-y-full'"
    class="fixed z-50 inset-x-0 bottom-0 md:hidden transition-all duration-500 transform"
  >
    <div
      class="rounded-t-lg z-50 shadow-md bg-gray-100 ring-1 ring-black ring-opacity-5 overflow-hidden flex flex-col"
    >
      <div
        :class="user && user.clients && user.clients.length > 1 ? 'mb-2' : ''"
        class="shadow bg-white border-b border-gray-300"
      >
        <div class="flex items-center justify-between">
          <div class="p-4 text-gray-500">
            Profilo
          </div>

          <div @click="toggleMenu" class="text-gray-400 p-3">
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
        <div class="p-3">
          <div class="flex items-center justify-between">
            <div class="flex items-center space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-12 w-12 text-gray-900"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clip-rule="evenodd"
                />
              </svg>
              <p>{{ loadedClient.heading }}</p>
            </div>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-green-600"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div class="grid grid-cols-2 gap-2 pt-2">
            <div class="p-1">
              <div
                @click="showProfile"
                class="rounded border-2 border-gray-300 px-1 py-0.5 text-md text-center font-medium cursor-pointer hover:bg-gray-100 hover:shadow"
              >
                Vai al profilo
              </div>
            </div>
            <div class="p-1">
              <div
                @click="logout"
                class="rounded border-2 border-gray-300 px-1 py-0.5 text-md text-center font-medium cursor-pointer hover:bg-gray-100 hover:shadow"
              >
                Esci
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-100 -mt-1">
        <div
          v-for="(client, index) in user.clients"
          :key="index"
          @click="setClient(client.id)"
        >
          <div
            v-if="client.id != loadedClient.id"
            class="p-3 border-b border-gray-200 cursor-pointer hover:bg-gray-300 text-gray-600 hover:text-gray-900"
          >
            <div class="flex items-center justify-between">
              <div class="flex items-center space-x-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-12 w-12 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                    clip-rule="evenodd"
                  />
                </svg>
                <p>{{ client.heading }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div @click="toggleMenu" class="px-2 py-3">
    <svg
      v-if="!isOpen"
      xmlns="http://www.w3.org/2000/svg"
      class="h-7 w-7"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      class="h-7 w-7"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "MobileProfileButton",
  data() {
    return {
      isOpen: false,
      isLoading: false,
      alertMessage: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    loadedClient() {
      return this.$store.getters["user/loadedClient"];
    },
  },
  async mounted() {
    if (this.loadedClient.heading == undefined) {
      await this.setClient(false);
    }
    if (this.user.name == undefined) {
      await this.loadUser();
    }
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    logout() {
      this.alertMessage = "Sei sicuro di voler uscire?";
    },
    async confirmLogout() {
      this.alertMessage = null;
      await this.$store.dispatch("logout");
      this.$router.replace("/login");
    },
    async loadUser() {
      this.isLoading = true;
      await this.$store.dispatch("user/getUser");
      this.isLoading = false;
    },
    async setClient(id) {
      this.isLoading = true;
      await this.$store.dispatch("user/setClient", { clientId: id });
      this.isOpen = false;
      this.isLoading = false;
    },
    showProfile() {
      this.isOpen = false;
      this.$router.push("/settings/profile");
    },
  },
};
</script>

<style scoped>
.fade-enter-active {
  -webkit-animation: fade 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.fade-leave-active {
  -webkit-animation: fade 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both reverse;
  animation: fade 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both reverse;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
