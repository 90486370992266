<template>
  <div>
    <simple-title title="Registro chiamate" :isUpdating="isUpdating"></simple-title>
    <transition name="slide-top-blur-2">
      <div v-if="!isLoading" class="flex flex-col bg-gray-50 shadow-xl rounded-md px-4 py-3 mt-3 mb-8">
        <div class="flex-grow flex flex-row justify-center items-center space-x-5 mb-8">
          <div class="flex flex-row items-center space-x-3">
            <label for="month" class="text-sm font-medium text-gray-700">Mese</label>
            <select id="month" name="month" required="required" v-model="period.month" @change="getTraffic()"
              autocomplete="month"
              class="flex-grow mt-1 py-1 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 text-sm">
              <option v-for="(month, index) in months" :key="index" :value="month.id">
                {{ month.name }}
              </option>
            </select>
          </div>
          <div class="flex flex-row items-center space-x-3">
            <label for="year" class="text-sm font-medium text-gray-700">Anno</label>
            <select id="year" name="year" required="required" v-model="period.year" @change="getTraffic()"
              autocomplete="year"
              class="flex-grow w-16 mt-1 py-1 px-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 text-sm">
              <option v-for="(year, index) in years" :key="index" :value="year">
                {{ year }}
              </option>
            </select>
          </div>
        </div>
        <div class="hidden md:grid grid-cols-5 text-sm py-2 border-b border-gray-200 font-bold">
          <div class="col-span-1">Da</div>
          <div class="col-span-1">A</div>
          <div class="col-span-1">Data</div>
          <div class="col-span-1">Durata</div>
          <div class="col-span-1 text-right">Importo</div>
        </div>
        <div class="flex py-5 justify-center" v-if="isUpdating">
          <base-spinner-mini class="mr-4"></base-spinner-mini> Carico lista
          aggiornata
        </div>
        <div v-else-if="traffic.length == 0" class="flex py-5 justify-center">
          Nessun dato disponibile
        </div>
        <div v-else>
          <div v-for="(item, index) in traffic" :key="index"
            class="flex flex-col md:grid md:grid-cols-5 text-sm py-2 border-b border-gray-200">
            <div class="flex justify-between md:col-span-1">
              <span class="md:hidden text-gray-400">Da:</span>
              {{ formatNumber(item[0]) }}
            </div>
            <div class="flex justify-between md:col-span-1">
              <span class="md:hidden text-gray-400">A:</span>
              {{ formatNumber(item[1]) }}
            </div>
            <div class="flex justify-between md:col-span-1">
              <span class="md:hidden text-gray-400">Data:</span>
              {{ formatDate(item[2]) }}
            </div>
            <div class="flex justify-between md:col-span-1">
              <span class="md:hidden text-gray-400">Durata:</span>
              {{ formatDuration(item[4]) }}
            </div>
            <div class="flex justify-between md:col-span-1 md:justify-end">
              <span class="md:hidden text-gray-400">Importo:</span>
              <div v-html="formatPrice(item[5])"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex" v-else>
        <base-spinner-mini class="mr-4"></base-spinner-mini> Carico lista
        chiamate
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "VoipRegistry",
  data() {
    return {
      isLoading: true,
      isUpdating: true,
      traffic: [],
      period: {
        year: null,
        month: null,
      },
      years: ["2023", "2024"],
    };
  },
  computed: {
    service() {
      return this.$store.getters["services/loadedService"];
    },
    months() {
      return this.$store.getters["main/months"];
    },
    nowMonth() {
      return moment().format("MM");
    },
    nowYear() {
      return moment().format("YYYY");
    },
    client() {
      return this.$store.getters["user/loadedClient"];
    },
    vat() {
      return this.$gf.getVATbyCode(this.client.vat_code)['multiplier']
    },
  },
  async mounted() {
    this.setBreadcrumbs();
    this.init();
    await this.getTraffic();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Servizi", link: "/services" },
        {
          title: this.service.item.category.name,
          link: "/services/voip/" + this.service.id,
        },
        {
          title: "Registro chiamate",
          link: "/services/voip/" + this.service.id + "/registry",
        },
      ]);
    },
    init() {
      this.period.month = this.nowMonth;
      this.period.year = this.nowYear;
    },
    formatNumber(item) {
      let number = item;
      const match = number.match(/\(([^)]+)\)/);

      if (match) {
        // Estrai la parte tra parentesi tonde
        const numeroConParentesi = match[1];

        // Rimuovi tutti i "4" dall'inizio della stringa
        const numeroPulito = numeroConParentesi.replace(/^4+/, "");

        number = numeroPulito;
      }

      if (number.startsWith("0039"))
        number = "+39 " + number.substring(4, number.length);
      return number.substring(0, number.length - 3) + "***";
    },
    formatDate(timestamp) {
      return moment.unix(timestamp).format("DD MMM YYYY HH:mm:ss");
    },
    formatDuration(durata) {
      return moment.utc(durata * 1000).format("HH:mm:ss");
    },
    formatPrice(item) {
      if (item === "" || isNaN(item)) {
        return "€ 0<span class='text-gray-300'>,0000</span>";
      }
      const numeroArrotondato = (parseFloat(item) * this.vat).toFixed(4);
      const numeroFormattato = numeroArrotondato.replace(".", ",");
      return "€ " + numeroFormattato;
    },
    async getTraffic() {
      this.isUpdating = true;
      this.traffic = await this.$store.dispatch("services/getTraffic", {
        id: this.service.id,
        year: this.period.year,
        month: this.period.month,
      });
      this.isLoading = false;
      this.isUpdating = false;
    },
  },
};
</script>
