<template>
    <div>
        <simple-title title="Modifica Password"></simple-title>
        <div>
            <edit-password-form></edit-password-form>
        </div>
    </div>
</template>

<script>
import EditPasswordForm from "./../../../components/forms/EditPasswordForm";

export default {
    name: "EditPassword",
    components: {
        EditPasswordForm,
    },
    async mounted() {
        this.setBreadcrumbs();
    },
    methods: {
        setBreadcrumbs() {
            this.$store.dispatch("main/setBreadcrumbs", [
                { title: "Impostazioni", link: "/settings" },
                {
                    title: "Modifica password",
                    link: "/settings/edit-password",
                },
            ]);
        }
    },
};
</script>