export default {
  async getTickets(context) {
    let url =
       `${process.env.VUE_APP_API_URL}/user/tickets`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getTickets', responseData);

    context.commit('setTickets', responseData.tickets);
    context.commit('updateDatetime');
  },

  async getSubcategories(context) {
    let url =
       `${process.env.VUE_APP_API_URL}/user/tickets/subcategories`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getSubcategories', responseData);

    context.commit('setSubcategories', responseData.subcategories);
  },

  async getTicket(context, payload) {
    let url =
       `${process.env.VUE_APP_API_URL}/user/tickets/${payload.id}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getTicket ' + payload.id, responseData);

    context.commit('setLoadedTicket', { ticket: responseData.ticket });
    context.commit('updateDatetime');
  },

  async newTicket(context, payload) {
    let url =
       `${process.env.VUE_APP_API_URL}/user/tickets/create`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
      body: JSON.stringify({
        subcategory: payload.subcategory,
        service: (payload.service === undefined) ? null : payload.service,
        subject: payload.subject,
        text: payload.text
      })
    });

    const responseData = await response.json();

    if (responseData.status_code != 200) {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }
    
    return responseData.newTicket;

  },

  async newMessage(context, payload) {
    let url =
       `${process.env.VUE_APP_API_URL}/user/tickets/${payload.ticket.id}/messages/create`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
      body: JSON.stringify({
        ticket_id: payload.ticket.id,
        text: payload.newMessage.text
      })
    });

    const responseData = await response.json();

    if (responseData.status_code != 200) {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }
    
    return responseData.newMessage;

  },

  async setStatusClosed(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/tickets/${payload.id}/close`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('setStatusClosed', responseData);

    context.commit('setLoadedTicket', { ticket: responseData.ticket });
    context.commit('updateDatetime');
  },
};
