<template>
  <div v-if="hasInvoices">
    <div>
      <div
        class="flex flex-col-reverse sm:flex-row justify-between sm:items-center mb-4"
      >
        <div
          :class="isSearching ? 'text-gray-900' : 'text-gray-600'"
          class="flex items-center text-xl font-medium"
        >
          <i-search classes="w-5"></i-search>
          <input
            type="text"
            v-model="search"
            class="text-gray-600 focus:text-gray-900 border-none bg-transparent w-full rounded-l-md focus:ring-0 py-1"
            placeholder="Cerca numero o data"
            id=""
          />
        </div>
        <base-refresh-string
          timePointer="payments/updateDatetime"
          @refresh="loadInvoices()"
          :isUpdating="isUpdating"
        ></base-refresh-string>
      </div>
      <div v-if="filterInvoices.length > 0">
        <div v-for="invoice in filterInvoices" :key="invoice.id" class="mb-3">
          <transition name="slide-top-blur">
            <div v-if="initial">
              <base-invoice-row
                :invoice="invoice"
                :isUpdating="isUpdating"
              ></base-invoice-row>
            </div>
          </transition>
        </div>
      </div>
      <div v-else>Nessun risultato per "{{ search }}"</div>
    </div>
  </div>
  <div v-else-if="isLoading">
    <skeleton-internet-service
      class="hidden md:block"
    ></skeleton-internet-service>
    <skeleton-internet-service-mobile
      class="md:hidden"
    ></skeleton-internet-service-mobile>
  </div>
  <div v-else>Non ci sono documenti disponibili.</div>
</template>

<script>
import SkeletonInternetService from "../../../../ui/skeleton/services/SkeletonInternetService";
import SkeletonInternetServiceMobile from "../../../../ui/skeleton/services/SkeletonInternetServiceMobile";
import BaseInvoiceRow from "../../../../ui/invoices/BaseInvoiceRow";

import moment from "moment";

export default {
  name: "InvoicesList",
  components: {
    SkeletonInternetService,
    SkeletonInternetServiceMobile,
    BaseInvoiceRow,
  },
  data() {
    return {
      isLoading: true,
      isUpdating: false,
      initial: false,
      search: "",
    };
  },
  computed: {
    invoices() {
      return this.$store.getters["files/invoices"];
    },
    hasInvoices() {
      return this.invoices && this.invoices.length > 0;
    },
    isSearching() {
      return this.search != "";
    },
    filterInvoices() {
      return this.invoices.filter((el) => {
        return (
          moment(el.created_at)
            .format("DD/MM/YYYY")
            .includes(this.search) || el.id.toString(10).includes(this.search)
        );
      });
    },
  },
  async mounted() {
    this.setBreadcrumbs();

    await this.loadInvoices();

    this.initial = true;
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Pagamenti", link: "/payments" },
        { title: "Le tue fatture", link: "/payments/invoices" },
      ]);
    },
    async loadInvoices() {
      if (this.invoices && this.invoices.length == 0) {
        this.isLoading = true;
        this.isUpdating = true;
      } else {
        this.isUpdating = true;
        this.initial = true;
      }

      try {
        await this.$store.dispatch("files/getUserFiles");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      this.$store.commit("payments/updateDatetime");

      this.isLoading = false;
      this.isUpdating = false;
    },
  },
};
</script>

<style scoped>
.just-blur {
  opacity: 0.25;
  filter: blur(3px);
  transition: 0.4s ease-out;
}

.just-blur-back {
  opacity: 1;
  filter: blur(0px);
  transition: 0.4s ease-in;
}

.slide-top-blur-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.slide-top-blur-leave-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both reverse;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both
    reverse;
}
@-webkit-keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
</style>
