export default {
  name(state) {
    return state.user.name;
  },
  user(state) {
    return state.user;
  },
  email(state) {
    return state.user.email;
  },
  mobile(state) {
    return state.userProfile.mobile;
  },
  email_verified_at(state) {
    return state.user.email_verified_at;
  },
  paymethod(state) {
    return state.userProfile.payment;
  },
  userProfile(state) {
    return state.userProfile;
  },
  loadedClient(state) {
    return state.loadedClient;
  },
};