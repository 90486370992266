<template>
    <div
        class="px-4 py-8 bg-white sm:p-6 sm:py-8 flex flex-col items-center text-gray-900 space-y-8"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="text-green-500 w-16"
        >
            <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
            />
        </svg>
        <div class="flex flex-col items-center text-center space-y-4">
            <span class="text-lg font-bold">
                Il Pagamento è avvenuto con successo
            </span>
            <span class="text-md" v-if="autorenewal">
                Il <strong>rinnovo automatico</strong> del servizio è stato
                ativato correttamente
            </span>
        </div>
        <router-link to="riepilogue">
            <p class="text-yellow-500">Torna al Riepilogo del servizio</p>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "PaymentCompleted",
    props: ['autorenewal']
};
</script>