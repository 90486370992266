<template>
  <div v-if="isUpdating" class="flex flex-grow justify-end">
    <base-spinner-mini></base-spinner-mini>
  </div>
  <div v-else>
    <div class="flex justify-end items-center">
      <p class="text-sm sm:text-md text-gray-400 flex">
        <span class="hidden sm:flex">Ultimo aggiornamento alle ore {{ lastUpdate }}</span>
      </p>
      <div
        content="Ricarica risultati"
        v-tippy="{
          placement: 'top',
          arrow: false,
          allowHTML: true,
        }"
        @click="refresh"
        class="cursor-pointer text-gray-400 hover:text-gray-900"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 my-0.5 ml-2"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["timePointer", "isUpdating"],
  emits: ["refresh"],
  computed: {
    lastUpdate() {
      return moment(this.updateDatetime).format("HH:mm");
    },
    updateDatetime() {
      return this.$store.getters[this.timePointer];
    },
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>

<style>
div[class^="tippy-"],
div[class*=" tippy-"] {
  border-radius: 4px;
  padding: 2px 4px;
  color: white;
  background-color: black !important;
  z-index: 50000000;
}
</style>
