<template>
  <base-dialog :show="!!error" title="An error occurred" @close="handleError">
    <p>{{ error }}</p>
  </base-dialog>
  <base-spinner v-if="isLoading"></base-spinner>
  <div>
    <div
      class="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8"
    >
      <div class="max-w-md w-full space-y-8">
        <div :class="isLoading ? 'blur-scale-2' : ''">
          <img
            class="mx-auto h-16 w-auto py-3 px-1 border-transparent rounded-2xl bg-gray-900 shadow-xl"
            src="/assets/logos/nibble.svg"
            alt="logo"
          />
          <h2 class="mt-6 text-center text-2xl font-normal text-gray-900">
            Verifica la tua mail
          </h2>
          <!-- <p class="mt-2 text-center text-sm text-gray-600">
            O
            <router-link
              to="/"
              class="font-medium text-yellow-500 hover:text-yellow-400"
            >
              torna alla pagina di Accesso
            </router-link>
          </p> -->
        </div>
        <div v-if="isUpdating" class="flex flex-col items-center space-y-8">
          <p class="text-xl text-gray-500">È in corso la verifica della mail</p>
          <base-spinner-mini></base-spinner-mini>
          <p class="text-center text-gray-400">Attendere...</p>
        </div>
        <div v-else class="flex flex-col items-center">
          <div
            v-if="isVerified"
            class="flex flex-col space-y-2 items-center text-green-500"
          >
            <p class="text-3xl">Email verificata correttamente!</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-12 w-12 text-green-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              />
            </svg>
            <p class="text-gray-400 mt-12 text-sm text-center">
              Grazie per aver verificato il tuo indirizzo email. Questo ci
              fornisce la garanzia che le comunicazioni che ti inviamo per email
              ti arrivano correttamente e non finiscono tra la posta
              indesiderata.
            </p>
          </div>
          <div v-else class="flex flex-col space-y-2 items-center text-red-500">
            <p class="text-3xl">Errore nella verifica.</p>
            <p class="text-xl">Token non valido o scaduto</p>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-12 w-12 text-red-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 1.944A11.954 11.954 0 012.166 5C2.056 5.649 2 6.319 2 7c0 5.225 3.34 9.67 8 11.317C14.66 16.67 18 12.225 18 7c0-.682-.057-1.35-.166-2.001A11.954 11.954 0 0110 1.944zM11 14a1 1 0 11-2 0 1 1 0 012 0zm0-7a1 1 0 10-2 0v3a1 1 0 102 0V7z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerifyEmail",
  props: ["token"],
  data() {
    return {
      isLoading: false,
      formIsValid: true,
      error: null,
      isUpdating: false,
      isVerified: false,
    };
  },
  async created() {
    await this.startVerification();
    this.isUpdating = false;
  },
  methods: {
    async startVerification() {
      this.formIsValid = true;
      if (this.token === "") {
        this.formIsValid = false;
        return;
      }

      this.isUpdating = true;

      const actionPayload = {
        token: this.token,
      };

      try {
        // do Login
        await this.$store.dispatch("verifyEmail", actionPayload);
        this.isUpdating = false;
        this.isVerified = true;
      } catch (err) {
        this.isUpdating = false;
        this.isVerified = false;
        this.error = err.message || "Failed to authenticate, try later.";
      }
      // console.log(this.isVerified);

      return;
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
.blur-scale-2 {
  opacity: 0.8;
  filter: blur(4px);
  transform: scale(0.9);
  transition: all 0.2s linear;
}
.blur-scale-3 {
  opacity: 0.8;
  filter: blur(4px);
  transform: scale(0.9);
  transition: all 0.3s linear;
}
.blur-scale-4 {
  opacity: 0.8;
  filter: blur(4px);
  transform: scale(0.9);
  transition: all 0.4s linear;
}
</style>
