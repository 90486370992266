<template>
  <div>
    <simple-title title="I tuoi ordini" :isUpdating="isUpdating"></simple-title>
    <transition name="slide-top-blur-2">
      <div
        v-if="!isLoading"
        class="flex flex-col bg-gray-50 shadow-xl rounded-md px-4 py-3 mt-3 mb-8"
      >
        <div
          class="hidden md:grid grid-cols-3 text-sm py-2 border-b border-gray-200 font-bold"
        >
          <div class="col-span-1">Data ordine</div>
          <div class="col-span-1">Numero</div>
          <div class="col-span-1 text-right">Ricaricati</div>
        </div>
        <div class="flex py-5 justify-center" v-if="isUpdating">
          <base-spinner-mini class="mr-4"></base-spinner-mini> Carico lista
          aggiornata
        </div>
        <div v-else-if="orders.length == 0" class="flex py-5 justify-center">
          Nessun ordine ancora effettuato
        </div>
        <div v-else>
          <div
            v-for="(order, index) in orders"
            :key="index"
            class="flex flex-col md:grid md:grid-cols-3 text-sm py-2 border-b border-gray-200"
          >
            <div
              class="flex flex-row justify-between md:justify-start md:col-span-1"
            >
              <span class="md:hidden text-gray-400">Data ordine:</span>
              {{ formatOrderDate(order.created_at) }}
            </div>
            <div
              class="flex flex-row justify-between md:justify-start md:col-span-1"
            >
              <span class="md:hidden text-gray-400">Numero:</span>
              {{ service.details.number }}
            </div>
            <div
              class="flex flex-row justify-between md:justify-start md:col-span-1 md:justify-end"
            >
              <span class="md:hidden text-gray-400">Ricaricati:</span>
              {{ order.value }} €
            </div>
          </div>
        </div>
      </div>
      <div class="flex" v-else>
        <base-spinner-mini class="mr-4"></base-spinner-mini> Carico lista ordini
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "VoipOrders",
  data() {
    return {
      isLoading: true,
      isUpdating: true,
      orders: [],
    };
  },
  computed: {
    service() {
      return this.$store.getters["services/loadedService"];
    },
  },
  async mounted() {
    this.setBreadcrumbs();
    await this.getOrders();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Servizi", link: "/services" },
        {
          title: this.service.item.category.name,
          link: "/services/voip/" + this.service.id,
        },
        {
          title: "I tuoi ordini",
          link: "/services/voip/" + this.service.id + "/orders",
        },
      ]);
    },
    formatOrderDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getOrders() {
      this.isUpdating = true;
      this.orders = await this.$store.dispatch("services/getVoipOrders", {
        id: this.service.id,
      });
      this.isLoading = false;
      this.isUpdating = false;
    },
  },
};
</script>
