import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      services: [],
      loadedService: null,
      rechargeItems: [],
      cronoItems: []
    };
  },
  mutations,
  actions,
  getters
};