// import Cookies from 'js-cookie'
export default {
  setInvoices(state, payload) {
    state.invoices = payload;
  },
  setItems(state, payload) {
    state.items = payload;
  },
  setLoadedInvoice(state, payload) {
    state.loadedInvoice = payload.invoice;
  },
  updateDatetime(state) {
    state.updateDatetime = new Date();
  },
  setCartClient(state) {
    // let clientid = Cookies.get('clientId');
    // if (localStorage.getItem('cart' + clientid))
    //   state.cart = JSON.parse(localStorage.getItem('cart' + clientid));
    // else state.cart = {
    //   items: [],
    //   services: []
    // }
    state.cart = {
      items: [],
      services: []
    }
  },
  addToCart(state, payload) {
    if (payload.services) state.cart.services = [...state.cart.services, ...payload.services];
    if (payload.items) state.cart.items = [...state.cart.items, ...payload.items];
    // let clientid = Cookies.get('clientId');
    // localStorage.setItem('cart' + clientid, JSON.stringify(state.cart));
  },
  removeToCart(state, payload) {
    if (payload.services) state.cart.services = [...payload.services];
    if (payload.items) state.cart.items = [...payload.items];
    console.log(state.cart)
    // let clientid = Cookies.get('clientId');
    // localStorage.setItem('cart' + clientid, JSON.stringify(state.cart));
  },
  setPaymethod(state, payload) {
    state.paymethod = payload.id;
  },
  setPaymentCompleted(state, payload) {
    state.paymentCompleted = payload;
  },
  initCart(state) {
    state.cart.services = [];
    state.cart.items = [];
    state.paymethod = null;

    // let clientid = Cookies.get('clientId');
    // localStorage.setItem('cart' + clientid, JSON.stringify(state.cart));
  }
};