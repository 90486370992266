<template>
  <div class="flex justify-between items-center">
    <div>
      <div class="mt-1">
        <span class="text-sm md:text-md font-bold text-gray-500"
          >{{ loadedService.item.category.name }}
        </span>
      </div>
      <div class="-mt-1 -mb-4 md:mb-0">
        <span class="text-xl md:text-3xl capitalize">{{
          loadedService.item.name.toLowerCase()
        }}</span>
      </div>
    </div>
    <div>
      <router-link
        to="/services"
        class="flex items-center hover:text-yellow-500 cursor-pointer "
      >
        <i-chevron-left classes="w-4 sm:w-5 md:w-6 mr-1"></i-chevron-left>
        <span class="text-sm sm:text-md md:text-lg whitespace-nowrap"
          >Tutti i servizi</span
        >
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "RiepilogueTitle",
  computed: {
    loadedService() {
      return this.$store.getters["services/loadedService"];
    },
    hasService() {
      return this.loadedService != null;
    },
  },
};
</script>
