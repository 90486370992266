<template>
    <button
        :type="type"
        :class="[
            isLight
                ? 'text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
                : 'text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500',
            hideLabelOnMobile ? 'px-2 md:px-3' : 'px-3',
        ]"
        class="inline-flex justify-center items-center py-2 border border-transparent shadow-sm text-sm font-medium rounded"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            v-if="hasIcon"
            :class="[
                isLight ? 'text-gray-700' : 'text-gray-400',
                iconRotate ? 'transform rotate-90' : '',
                hideLabelOnMobile ? 'md:mr-2' : 'mr-2',
            ]"
            class="w-5"
        >
            <slot></slot>
        </svg>
        <span :class="hideLabelOnMobile ? 'hidden md:flex' : ''">
            {{ label }}
        </span>
    </button>
</template>

<script>
export default {
    name: "BaseButton",
    props: {
        type: {
            type: String,
            default: "button",
        },
        label: {
            type: String,
            default: "",
        },
        isLight: {
            type: Boolean,
            default: false,
        },
        hasIcon: {
            type: Boolean,
            default: false,
        },
        iconRotate: {
            type: Boolean,
            default: false,
        },
        hideLabelOnMobile: {
            type: Boolean,
            default: false,
        },
    },
};
</script>