import TheHeader from './../../layout/TheHeader'
import TheFooter from './../../layout/TheFooter'
import BlankView from './../../views/misc/BlankView'
// import BlankTitle from './../../views/misc/BlankTitle'
import SampleView from './../../views/misc/SampleView'


// Settings Titles
import GenericSettingsTitle from './../../views/settings/pageTitles/GenericSettingsTitle'

export default [
    {
        path: '/informations',
        name: 'informations',
        components: {
            header: TheHeader,
            default: BlankView,
            footer: TheFooter
        },
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                redirect: '/informations/privacy'
            },
            {
                path: 'privacy',
                name: 'privacy',
                components: {
                    pageTitle: GenericSettingsTitle,
                    // secondaryTitle: ProfileInfoTitle,
                    default: SampleView
                }
            },
            {
                path: 'terms',
                name: 'terms',
                components: {
                    pageTitle: GenericSettingsTitle,
                    // secondaryTitle: EditPasswordTitle,
                    default: SampleView
                }
            },
            {
                path: 'changelog',
                name: 'changelog',
                components: {
                    pageTitle: GenericSettingsTitle,
                    // secondaryTitle: EditPasswordTitle,
                    default: SampleView
                }
            },
        ]
    }
]