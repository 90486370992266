export default {
  async getInvoices(context) {
    let url =
      `${process.env.VUE_APP_API_URL}/api/user/invoices`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getInvoices', responseData);

    context.commit('setInvoices', responseData.invoices);
    context.commit('updateDatetime');
  },

  async getItems(context) {
    let url =
      `${process.env.VUE_APP_API_URL}/api/items`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getItems', responseData);

    context.commit('setItems', responseData.items);
  },

  async getInvoice(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/api/user/invoices/${payload.id}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getInvoice ' + payload.id, responseData);

    context.commit('setLoadedInvoice', { invoice: responseData.invoice });
    context.commit('updateDatetime');
  },

  async completePayment(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/payments/invoice`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
      body: JSON.stringify({
        stripe_id: payload.stripe_id,
        services: payload.services,
        items: payload.items,
        paymethod_id: payload.paymethod_id
      })
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('completePayment', responseData);

    return responseData.status_desc;
  },

  async newPaymentIntent(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/payments/intent`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
      body: JSON.stringify({
        stripe_id: payload.stripe_id,
        services: payload.services,
        items: payload.items,
        paymethod_id: payload.paymethod_id
      })
    });

    const responseData = await response.json();
    // console.log('newPaymentIntent', responseData);

    if (responseData.status_code == '402') {
      // console.log('Gestisco 3D Secure');
      return responseData.payment_intent;
    }
    else if (responseData.status_code == '200') {
      // console.log('Fine della veglia');
      return false;
    }
    else return false

  },
};
