<template>
  <div class="flex flex-row items-center md:items-end space-x-2 text-gray-700 py-4 hover:bg-gray-100">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      class="md:w-6 w-8 md:h-6 h-8"
    >
      <path
        v-if="file.ext == 'pdf'"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
      />
      <path
        v-else
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
    <div
      class="flex-grow flex flex-col sm:flex-row sm:items-center sm:justify-between "
    >
      <div >
        {{ file.filename }}
      </div>
      <div class="capitalize text-gray-400 text-sm">
        {{ dateFormatter(file.updated_at) }}
      </div>
    </div>
    <div
      class="flex-shrink flex items-center flex-row-reverse sm:flex-row justify-between text-right"
    >
      <div
        class="flex items-center ml-4 text-blue-500 cursor-pointer"
        content="Scarica"
        v-tippy="{
          placement: 'top',
          arrow: false,
        }"
        @click="download(file)"
      >
        <svg
          v-if="!file.downloading"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          class="w-6 py-0.5"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          />
        </svg>
        <base-spinner-mini v-else></base-spinner-mini>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "BaseFileItem",
  props: ["file"],
  methods: {
    dateFormatter(date) {
      return moment(date)
        .lang("it")
        .format("DD MMM YYYY");
    },
    async download(file) {
      file.downloading = true;
      try {
        await this.$store.dispatch("files/downloadFile", {
          file: file,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      file.downloading = false;
    },
  },
};
</script>
