import { createApp } from 'vue';

import router from './router';
import store from './store/index.js';
import App from './App';

// Global Cmp
import MainLayout from './layout/MainLayout';
import BaseLayout from './layout/BaseLayout';
import BaseButton from './ui/buttons/BaseButton';
import BaseButtonSpinner from './ui/buttons/BaseButtonSpinner';
import BaseSpinner from './ui/BaseSpinner';
import BaseSpinnerMini from './ui/BaseSpinnerMini';
import BaseDialog from './ui/BaseDialog';
import BaseRefreshString from './ui/BaseRefreshString';
import BaseSimpleTitle from './ui/BaseSimpleTitle';
import BaseActionTitle from './ui/BaseActionTitle';
import PillsButton from './ui/buttons/PillsButton';

// Skeleton Cmp
import SkeletonBaseLayout from './ui/skeleton/SkeletonBaseLayout'
import SkeletonBaseLayoutMobile from './ui/skeleton/SkeletonBaseLayoutMobile'

import GlobalDirectives from "./plugins/globalDirectives.js";
import GlobalFunctions from "./plugins/globalFunctions.js";
import Icons from './icons/main.js';

const app = createApp(App)

app.use(router);

app.use(store);

app.use(GlobalDirectives);
app.use(Icons);

app.component('main-layout', MainLayout);
app.component('base-layout', BaseLayout);
app.component('base-button', BaseButton);
app.component('base-spinner-button', BaseButtonSpinner);
app.component('base-spinner', BaseSpinner);
app.component('base-spinner-mini', BaseSpinnerMini);
app.component('base-dialog', BaseDialog);
app.component('base-refresh-string', BaseRefreshString);
app.component('simple-title', BaseSimpleTitle);
app.component('action-title', BaseActionTitle);
app.component('pills-button', PillsButton);

app.component('skeleton-base', SkeletonBaseLayout);
app.component('skeleton-base-mobile', SkeletonBaseLayoutMobile);

app.config.globalProperties.$gf = GlobalFunctions;

app.mount('#app')

import './index.css';
import './assets/tailwind.css';