<template>
  <div class="">
    <div class="max-w-7xl mx-auto pb-4 sm:pb-4 md:pb-10 opacity-100">
      <div class="flex flex-col w-full mb-4 md:mb-8">
        <div class="flex flex-col w-full">
          <input
            class="w-full mb-2 sm:mb-3 md:mb-6 px-3 sm:px-5 py-2 text-md sm:text-sm md:text-xl rounded-lg placeholder-gray-400 font-light outline-none"
            placeholder="Usa parole chiave per trovare risposta"
            type="text"
            v-model="filter.text"
            name=""
            id=""
          />
        </div>
        <div class="grid">
          <div
            class="flex flex-row space-x-6 md:space-x-8 border-t border-gray-400 flex-nowrap overflow-x-auto items-center"
          >
            <h1
              :class="{
                'border-yellow-500 text-yellow-500':
                  filter.category == 'connectivity' && filter.text == '',
                'border-gray-300 text-gray-500': filter.text != '',
              }"
              class="text-base md:text-lg pt-1 border-t-4 border-transparent cursor-pointer hover:text-yellow-500 transition-all duration-300 whitespace-nowrap"
              @click="setCategory('connectivity')"
            >
              Connettività
            </h1>
            <h1
              :class="{
                'border-yellow-500 text-yellow-500':
                  filter.category == 'telephony' && filter.text == '',
                'border-gray-300 text-gray-500': filter.text != '',
              }"
              class="text-base md:text-lg pt-1 border-t-4 border-transparent cursor-pointer hover:text-yellow-500 transition-all duration-300 whitespace-nowrap"
              @click="setCategory('telephony')"
            >
              Telefonia
            </h1>
            <h1
              :class="{
                'border-yellow-500 text-yellow-500':
                  filter.category == 'administration' && filter.text == '',
                'border-gray-300 text-gray-500': filter.text != '',
              }"
              class="text-base md:text-lg pt-1 border-t-4 border-transparent cursor-pointer hover:text-yellow-500 transition-all duration-300 whitespace-nowrap"
              @click="setCategory('administration')"
            >
              Amministrazione
            </h1>
            <h1
              :class="{
                'border-yellow-500 text-yellow-500':
                  filter.category == 'general-info' && filter.text == '',
                'border-gray-300 text-gray-500': filter.text != '',
              }"
              class="text-base md:text-lg pt-1 border-t-4 border-transparent cursor-pointer hover:text-yellow-500 transition-all duration-300 whitespace-nowrap"
              @click="setCategory('general-info')"
            >
              Info Generali
            </h1>
          </div>
        </div>
        <collapse-transition :duration="150">
          <div
            class="flex flex-row items-center w-full gap-4 mt-6"
            v-if="filter.text != ''"
          >
            <i-search classes="h-6 w-6 text-gray-500"></i-search>
            <h2 class="text-base">Hai cercato "{{ filter.text }}"</h2>
            <i-times
              @click="filter.text = ''"
              classes="flex-shrink text-right h-5 w-5 text-gray-500 hover:text-yellow-500 cursor-pointer"
            ></i-times>
          </div>
        </collapse-transition>
      </div>
      <div
        v-for="(faq, index) in filteredJson"
        :key="index"
        class="transition-all duration-300"
        :class="{
          'bg-gray-700 md:bg-transparent shadow-inner md:shadow-none':
            filter.showed == index,
        }"
      >
        <div
          class="py-1.5 md:py-3"
          :class="{
            'border-b border-gray-600 ':
              filter.showed != index && filter.showed != index + 1,
          }"
        >
          <div
            @click="toggleFAQ(index)"
            class="flex flex-row items-start space-x-2 md:space-x-4 hover:text-yellow-500 transition-all duration-300 text-lg cursor-pointer"
          >
            <div
              :class="{
                'text-yellow-500 pb-2': filter.showed == index,
              }"
              class="flex"
            >
              <i-plus-circle
                class="w-6 h-6"
                v-if="filter.showed != index"
              ></i-plus-circle>
              <i-minus-circle
                class="w-6 h-6 text-yellow-500"
                v-if="filter.showed == index"
              ></i-minus-circle>
            </div>
            <div class="flex">
              <p class="text-base leading-5 md:leading-6">
                {{ faq.question }}
              </p>
            </div>
          </div>
          <collapse-transition :duration="400">
            <div
              v-show="filter.showed == index"
              :class="{
                'text-gray-200': filter.showed == index,
              }"
              class="ml-8 md:mx-10 mt-2 mb-6 md:my-6 md:text-gray-900 transition-all duration-300"
            >
              <div
                class="py-1"
                v-for="(el, aIndex) in faq.answear"
                :key="aIndex"
              >
                <paragraph
                  :class="el.classes"
                  v-if="el.type == 'p'"
                  v-html="el.text"
                ></paragraph>
                <a
                  class="text-yellow-500"
                  v-if="el.type == 'a'"
                  :class="el.classes"
                  :href="el.link"
                  >{{ el.label }}</a
                >
                <img
                  :class="el.classes"
                  v-if="el.type == 'img'"
                  :src="el.url"
                />
              </div>
            </div>
          </collapse-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import json from "@/json/faq.json";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import Paragraph from "@/components/Paragraph";

export default {
  name: "Guides",
  components: {
    CollapseTransition,
    Paragraph,
  },
  data() {
    return {
      myJson: json,
      filter: {
        category: "connectivity",
        text: "",
        showed: -1,
      },
    };
  },
  computed: {
    filteredJson() {
      return this.myJson.filter((el) => {
        if (this.filter.text == "") {
          return el.type == this.filter.category ? true : false;
        } else {
          return (
            this.searchInTitle(el.question) ||
            this.searchInParagraphs(el.answear)
          );
        }
      });
    },
  },
  mounted() {
    this.setBreadcrumbs();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Supporto", link: "/support" },
        { title: "Guide e FAQ", link: "/support/guides/" },
      ]);
    },
    toggleFAQ(index) {
      this.filter.showed == index
        ? (this.filter.showed = -1)
        : (this.filter.showed = index);
    },
    setCategory(category) {
      this.filter.showed = -1;
      this.filter.category = category;
    },
    searchInTitle(title) {
      if (title.toUpperCase().includes(this.filter.text.toUpperCase()))
        return true;
      else return false;
    },
    searchInParagraphs(answear) {
      let r = false;
      answear.forEach((el) => {
        if (el.type == "p") {
          if (el.text.toUpperCase().includes(this.filter.text.toUpperCase()))
            r = true;
        }
      });
      return r;
    },
  },
};
</script>
