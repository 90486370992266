<template>
  <div>
    <simple-title title="I tuoi ordini" :isUpdating="isUpdating"></simple-title>

    <transition name="slide-top-blur-2">
      <div
        v-if="!isLoading"
        class="flex flex-col bg-gray-50 shadow-xl rounded-md px-4 py-3 mt-3 mb-8"
      >
        <div
          class="hidden md:grid grid-cols-4 text-sm py-2 border-b border-gray-200 font-bold"
        >
          <div class="col-span-1">Data ordine</div>
          <div class="col-span-1">Dal</div>
          <div class="col-span-1">Al</div>
          <div class="col-span-1 text-right">Profilo</div>
        </div>
        <div class="flex py-5 justify-center" v-if="isUpdating">
          <base-spinner-mini class="mr-4"></base-spinner-mini> Carico lista
          aggiornata
        </div>
        <div v-else-if="orders.length == 0" class="flex py-5 justify-center">
          Nessun ordine ancora effettuato
        </div>
        <div v-else>
          <div
            v-for="(order, index) in orders"
            :key="index"
            class="flex flex-col md:grid md:grid-cols-4 text-sm py-2 border-b border-gray-200"
          >
            <div
              class="flex flex-row justify-between md:justify-start md:col-span-1"
            >
              <span class="md:hidden text-gray-400">Data ordine:</span>
              {{ formatOrderDate(order.created_at) }}
            </div>
            <div
              class="flex flex-row justify-between md:justify-start md:col-span-1"
            >
              <span class="md:hidden text-gray-400">Dal:</span>
              {{ formatDate(order.start_date) }}
            </div>
            <div
              class="flex flex-row justify-between md:justify-start md:col-span-1"
            >
              <span class="md:hidden text-gray-400">Al:</span>
              {{ formatDate(order.end_date) }}
            </div>

            <div
              class="flex flex-row justify-between md:justify-start md:col-span-1 md:justify-end"
            >
              <span class="md:hidden text-gray-400">Profilo:</span>
              {{ formatProfile(order.radius_service) + " Crono" }}
              <i-information-circle
                :content="formatTooltip(order.radius_service)"
                v-tippy="{ placement: 'top', arrow: true, allowHTML: true }"
                classes="h-5 w-5 hidden md:flex ml-1"
              ></i-information-circle>
            </div>
          </div>
        </div>
      </div>
      <div class="flex" v-else>
        <base-spinner-mini class="mr-4"></base-spinner-mini> Carico lista ordini
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import json from "@/json/crono.json";

export default {
  name: "InternetOrders",
  data() {
    return {
      isLoading: true,
      isUpdating: true,
      orders: [],
      profiles: json,
    };
  },
  computed: {
    service() {
      return this.$store.getters["services/loadedService"];
    },
  },
  async mounted() {
    this.setBreadcrumbs();
    await this.getOrders();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Servizi", link: "/services" },
        {
          title: this.service.item.category.name,
          link: "/services/internet/" + this.service.id,
        },
        {
          title: "I tuoi ordini",
          link: "/services/internet/" + this.service.id + "/orders",
        },
      ]);
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    formatOrderDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatProfile(code) {
      return this.profiles.find((profile) => {
        return profile.code == code;
      }).name;
    },
    formatTooltip(code) {
      let profile = this.profiles.find((profile) => {
        return profile.code == code;
      });
      return (
        profile.rate.down +
        " Mbps in Download <br/> " +
        profile.rate.up +
        " Mbps in Upload"
      );
    },
    async getOrders() {
      this.isUpdating = true;
      this.orders = await this.$store.dispatch("services/getCronoOrders", {
        id: this.service.id,
      });
      this.isLoading = false;
      this.isUpdating = false;
    },
  },
};
</script>
