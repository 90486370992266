// import Cookies from 'js-cookie'

export default {
  invoices(state) {
    return state.invoices;
  },
  items(state) {
    return state.items;
  },
  loadedInvoice(state) {
    return state.loadedInvoice;
  },
  updateDatetime(state) {
    return state.updateDatetime;
  },
  paymentCompleted(state) {
    return state.paymentCompleted;
  },
  paymethod(state) {
    return state.paymethod;
  },
  cart(state) {
    // let clientid = Cookies.get('clientId');
    // if (localStorage.getItem('cart' + clientid)) state.cart = JSON.parse(localStorage.getItem('cart' + clientid));

    return state.cart;
  }
};