<template>
  <main-layout>
    <div
      class="flex flex-col sm:flex-row sm:justify-between items-top flex-grow"
    >
      <h2 class="mb-8 mt-4 md:mt-0 text-4xl md:mb-4 md:text-3xl font-bold">
        Documenti e contratti
      </h2>
      <div class="flex flex-row items-center justify-between">
        <router-link to="/files/list">
          <svg
            v-if="!folderOpen"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="w-10 h-10 fill-current md:-ml-1 -mr-1 md:-mr-2"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            class="w-10 h-10 fill-current md:-ml-1 -mr-1 md:-mr-2 "
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M2 6a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1H8a3 3 0 00-3 3v1.5a1.5 1.5 0 01-3 0V6z"
              clip-rule="evenodd"
            />
            <path
              d="M6 12a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2H2h2a2 2 0 002-2v-2z"
            />
          </svg>
        </router-link>
        <router-link to="/files/upload">
          <div class="flex flex-row items-center" :class="{'px-4 py-2 bg-gray-200 rounded-sm shadow-md': !folderOpen}">
            <p>Carica</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              class="w-6 h-6 ml-2 fill-current"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </router-link>
      </div>
    </div>
    <router-view></router-view>
  </main-layout>
</template>

<script>
export default {
  name: "FilesView",
  computed: {
    folderOpen() {
      return this.$route.path == "/files/list" ? true : false;
    },
  },
};
</script>
