<template>
  <div class="flex flex-col pr-8">
    <router-link :to="relativeRoute('tickets')">
      <pills-button
        class="mr-1 lg:mr-4 mt-1 mainButton min-w-max"
        title="I tuoi ticket"
      >
        <i-inbox classes="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"></i-inbox>
      </pills-button>
    </router-link>

    <router-link :to="relativeRoute('tickets/new')">
      <pills-button
        class="mr-1 lg:mr-4 mt-3 mainButton min-w-max"
        title="Nuovo ticket"
      >
        <i-plus-circle
          classes="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"
        ></i-plus-circle>
      </pills-button>
    </router-link>

    <router-link :to="relativeRoute('guides')">
      <pills-button
        class="mr-1 lg:mr-4 mt-3 mainButton min-w-max"
        title="Guide e FAQ"
      >
        <i-paper-airplane
          classes="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"
        ></i-paper-airplane>
      </pills-button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  methods: {
    relativeRoute(relative = "") {
      return "/support/" + relative;
    },
  },
};
</script>

<style scoped>
.router-link-active .mainButton {
  @apply bg-gray-200;
  @apply rounded-md;
  @apply shadow-md;
}

.mainButton:hover {
  @apply bg-opacity-10;
  @apply bg-gray-200;
  @apply rounded-md;
}
</style>
