<template>
  <form @submit.prevent="submitNewMessage()" @reset.prevent="uploadFile()">
    <div class="px-3 py-2 sm:py-4 bg-gray-50 sm:px-6">
      <div class="flex justify-between">
        <div class="flex-grow no-wrap flex items-center">
          <textarea
            name="text"
            required="required"
            v-model="newMessage.text"
            rows="1"
            placeholder="Scrivi un messaggio"
            class="text-gray-500 focus:text-gray-900 border-none bg-transparent w-full rounded-l-md focus:ring-0 py-1 pl-0 mr-2"
            id="newMessage_text"
          >
          </textarea>
        </div>
        <div
          class="flex-shrink flex items-center"
          :class="newMessage.text.length == 0 ? '' : 'pl-2'"
        >
          <button
            v-if="false && newMessage.text.length == 0"
            type="reset"
            class="inline-flex justify-center py-1.5 px-1 md:px-3 border-0 border-transparent text-sm font-medium rounded-md text-blue-600 hover:text-blue-700 focus:outline-none"
          >
            <div
              content="Invia un immagine o un documento"
              v-tippy="{ placement: 'top', arrow: false }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                />
              </svg>
            </div>
          </button>
          <div>
            <base-button
              type="submit"
              label="Rispondi"
              :isLight="true"
              :hasIcon="true"
              :iconRotate="true"
              :hideLabelOnMobile="true"
              v-if="!submitted"
              content="Invia messaggio"
              v-tippy="{ placement: 'top', arrow: false }"
            >
              <path
                d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
              />
            </base-button>
            <base-spinner-button v-else></base-spinner-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "BaseMessage",
  props: ["ticket"],
  emits: ["refresh"],
  data() {
    return {
      submitted: false,
      newMessage: {
        text: "",
      },
    };
  },
  methods: {
    async submitNewMessage() {
      this.submitted = true;
      try {
        await this.$store.dispatch("tickets/newMessage", {
          ticket: this.ticket,
          newMessage: this.newMessage,
        });
        this.$emit("refresh");
        this.submitted = false;
        this.newMessage.text = "";
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    uploadFile() {
      this.$router.push(this.ticket.id + "/file-upload");
    },
  },
};
</script>

<style scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffffff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
