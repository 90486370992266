export default {
  setUser(state, payload) {
    state.user = payload;
  },
  setUserProfile(state, payload) {
    state.loadedClient = payload;
  },
  setLoadedClient(state, payload) {
    state.loadedClient = payload;
  },
};