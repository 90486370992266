export const contract_types = {
    internet: '01',
    voip: '02',
    pbx: '03',
    web: '04',
    other: '99',
};

export const vat_codes = {
    '22': {
        name: 'IVA 22%',
        code: '22',
        multiplier: 1.22,
    },
    'N21': {
        name: 'NATURA N2.1',
        code: 'N21',
        multiplier: 1,
    }
};

export const paymethods = {
    AS: "Assegno",
    BO: "Bonifico bancario",
    CC: "Carta di Credito",
    CO: "Contanti",
    PAY: "PayPal",
    PP: "PayPal",
    RIDRD: "RID Data Fattura",
    RB30: "RIBA 30gg",
};
