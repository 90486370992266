<template>
  <div class="px-4 py-5 bg-white sm:p-6 flex flex-col">
    <div class="flex flex-col">
      <div
        class="flex-grow flex justify-between items-start text-gray-900 mb-5 border-b border-gray-200 pb-1 text-xl"
      >
        Informazioni di fatturazione
      </div>
    </div>
    <div class="flex flex-col" v-if="!isLoading">
      <span class="text-md">{{
        loadedClient.name + " " + loadedClient.surname
      }}</span>
      <span class="text-sm text-gray-500">{{
        loadedClient.address +
          ", " +
          loadedClient.civic +
          " - " +
          loadedClient.cap +
          " " +
          loadedClient.city +
          " (" +
          loadedClient.province +
          ")"
      }}</span>
      <span class="text-sm text-gray-500">{{ loadedClient.email }}</span>
    </div>
    <div v-else>
      <base-spinner-mini></base-spinner-mini>
    </div>
  </div>
</template>

<script>
export default {
  name: "BillingInfo",

  data() {
    return {
      isLoading: true,
      isUpdating: true,
    };
  },
  computed: {
    loadedClient() {
      return this.$store.getters["user/loadedClient"];
    },
  },
  async mounted() {
    await this.loadUserProfile();
  },
  methods: {
    async loadUserProfile() {
      if (this.loadedClient && this.loadedClient.length == 0) {
        this.isLoading = true;
        this.isUpdating = true;
      } else {
        this.isLoading = false;
        this.isUpdating = true;
      }

      try {
        await this.$store.dispatch("user/setClient");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      this.isLoading = false;
      this.isUpdating = false;
    },
  },
};
</script>
