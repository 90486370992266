import Login from './../../views/auth/Login'
import ForgotPassword from './../../views/auth/ForgotPassword'
import ResetPassword from './../../views/auth/ResetPassword'
import VerifyEmail from './../../views/auth/VerifyEmail'

export default [
    {
        path: '/login',
        name: 'Login myNibble',
        components: { header: Login },
        meta: { requiresUnauth: true }
    },
    {
        path: '/forgot-password',
        name: 'Forgot Password',
        components: { header: ForgotPassword },
        meta: { requiresUnauth: true }
    },
    {
        path: '/reset-password/:token',
        props: true,
        name: 'Reset Password',
        components: { header: ResetPassword },
        meta: { requiresAuth: false }
    },
    {
        path: '/email/verify/:token',
        props: true,
        name: 'Verify Email',
        components: { header: VerifyEmail },
        meta: { requiresAuth: true }
    },
]