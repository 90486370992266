<template>
    <div>
        <div class="grid grid-cols-2">
            <div class="flex flex-col text-left">
                <div>
                    <div class="relative w-16 h-4 my-1">
                        <div class="absolute top-0 left-0 h-full w-full">
                            <span
                                class="skeleton-box transition-transform transform-center block h-full rounded-sm bg-gray-300"
                            ></span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="relative w-72 h-6 my-1">
                        <div class="absolute top-0 left-0 h-full w-full">
                            <span
                                class="skeleton-box transition-transform transform-center block h-full rounded-md bg-gray-500"
                            ></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex w-full items-center justify-end">
                <div class="relative w-36 h-6 my-1">
                    <div class="absolute top-0 left-0 h-full w-full">
                        <span
                            class="skeleton-box transition-transform transform-center block h-full rounded-md bg-gray-900"
                        ></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="rounded-lg w-full mt-6">
            <div class="flex gap-8">
                <div class="flex-shrink rounded-md">
                    <div class="relative w-56 h-10 mt-1">
                        <div class="absolute top-0 left-0 h-full w-full">
                            <span
                                class="skeleton-box transition-transform transform-center block h-full rounded-md bg-gray-300"
                            ></span>
                        </div>
                    </div>
                    <div class="relative w-56 h-10 my-4">
                        <div class="absolute top-0 left-0 h-full w-full">
                            <span
                                class="skeleton-box transition-transform transform-center block h-full rounded-md bg-gray-200"
                            ></span>
                        </div>
                    </div>
                    <div class="relative w-56 h-10 my-4">
                        <div class="absolute top-0 left-0 h-full w-full">
                            <span
                                class="skeleton-box transition-transform transform-center block h-full rounded-md bg-gray-100"
                            ></span>
                        </div>
                    </div>
                </div>
                <div class="flex-grow pl-2 py-2"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SkeletonServicesInternet",
};
</script>


<style scoped>
.skeleton-box {
    position: relative;
    overflow: hidden;
    filter: blur(0.4px);
    @apply bg-opacity-50;
}
.skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
    );
    animation: shimmer 1s infinite;
    content: "";
}
@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}
</style>

