<template>
  <div>
    <simple-title title="I tuoi dati" :isUpdating="isUpdating"></simple-title>

    <div v-show="!isLoading">
      <transition name="slide-top-blur-2">
        <div
          v-if="!isLoading"
          class="flex flex-col space-y-4 divide-y divide-gray-200 bg-gray-50 shadow-xl rounded-md px-4 py-3 mt-3 mb-8"
        >
          <riepilogue-table-row title="Nome e cognome" :value="fullname">
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
              clip-rule="evenodd"
            />
          </riepilogue-table-row>
          <riepilogue-table-row
            v-if="isBusiness"
            title="Denominazione"
            :value="loadedClient.heading"
          >
            <path
              fill-rule="evenodd"
              d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
              clip-rule="evenodd"
            />
          </riepilogue-table-row>
          <riepilogue-table-row
            title="Codice cliente"
            :value="loadedClient.baseId"
          >
            <path
              fill-rule="evenodd"
              d="M6.625 2.655A9 9 0 0119 11a1 1 0 11-2 0 7 7 0 00-9.625-6.492 1 1 0 11-.75-1.853zM4.662 4.959A1 1 0 014.75 6.37 6.97 6.97 0 003 11a1 1 0 11-2 0 8.97 8.97 0 012.25-5.953 1 1 0 011.412-.088z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M5 11a5 5 0 1110 0 1 1 0 11-2 0 3 3 0 10-6 0c0 1.677-.345 3.276-.968 4.729a1 1 0 11-1.838-.789A9.964 9.964 0 005 11zm8.921 2.012a1 1 0 01.831 1.145 19.86 19.86 0 01-.545 2.436 1 1 0 11-1.92-.558c.207-.713.371-1.445.49-2.192a1 1 0 011.144-.83z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M10 10a1 1 0 011 1c0 2.236-.46 4.368-1.29 6.304a1 1 0 01-1.838-.789A13.952 13.952 0 009 11a1 1 0 011-1z"
              clip-rule="evenodd"
            />
          </riepilogue-table-row>
          <riepilogue-table-row title="Tipologia cliente" :value="typo">
            <path
              d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z"
            />
          </riepilogue-table-row>
          <riepilogue-table-row title="Codice Fiscale" :value="loadedClient.cf">
            <path
              fill-rule="evenodd"
              d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </riepilogue-table-row>
          <riepilogue-table-row
            v-if="isBusiness"
            title="Partita IVA"
            :value="loadedClient.taxId"
          >
            <path
              fill-rule="evenodd"
              d="M5 2a2 2 0 00-2 2v14l3.5-2 3.5 2 3.5-2 3.5 2V4a2 2 0 00-2-2H5zm2.5 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6.207.293a1 1 0 00-1.414 0l-6 6a1 1 0 101.414 1.414l6-6a1 1 0 000-1.414zM12.5 10a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
              clip-rule="evenodd"
            />
          </riepilogue-table-row>
          <riepilogue-table-row
            title="Indirizzo di fatturazione"
            :value="billingAddress"
          >
            <path
              fill-rule="evenodd"
              d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
              clip-rule="evenodd"
            />
          </riepilogue-table-row>
        </div>
      </transition>
      <transition name="slide-top-blur-3">
        <div
          v-if="!isLoading"
          class="flex flex-col space-y-4 divide-y divide-gray-200 bg-gray-50 shadow-xl rounded-md px-4 py-3 mt-3 mb-8"
        >
          <riepilogue-table-row title="Email" :value="loadedClient.email">
            <path
              fill-rule="evenodd"
              d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
              clip-rule="evenodd"
            />
          </riepilogue-table-row>
          <riepilogue-table-row
            v-if="!isEmpty(loadedClient.phone)"
            title="Telefono"
            :value="loadedClient.phone"
          >
            <path
              d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
            />
          </riepilogue-table-row>
          <riepilogue-table-row
            v-if="!isEmpty(loadedClient.mobile)"
            title="Cellulare"
            :value="loadedClient.mobile"
          >
            <path
              fill-rule="evenodd"
              d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </riepilogue-table-row>
        </div>
      </transition>
      <transition name="slide-top-blur-4">
        <router-link
          v-if="!isLoading"
          to="/files"
          class="flex items-center justify-between bg-gray-600 hover:bg-gray-400 p-4 rounded-md shadow-md hover:shadow-lg text-lg text-gray-300 hover:text-gray-600"
        >
          Documenti e contratti
          <i-folder classes="w-10 h-10 ml-2"></i-folder>
        </router-link>
      </transition>
    </div>
    <div v-if="isLoading" class="mb-8">
      <base-spinner-mini></base-spinner-mini>
    </div>
  </div>
</template>

<script>
import RiepilogueTableRow from "./../../../ui/services/RiepilogueTableRow";

export default {
  name: "ProfileInfo",
  components: {
    RiepilogueTableRow,
  },
  data() {
    return {
      isLoading: true,
      isUpdating: true,
      search: "",
    };
  },
  computed: {
    isBusiness() {
      return this.loadedClient.type == "3" ? false : true;
    },
    loadedClient() {
      return this.$store.getters["user/loadedClient"];
    },
    fullname() {
      return this.loadedClient.name + " " + this.loadedClient.surname;
    },
    typo() {
      return this.isBusiness ? "Business" : "Privato";
    },
    billingAddress() {
      return (
        this.loadedClient.address +
        ", " +
        this.loadedClient.civic +
        " - " +
        this.loadedClient.cap +
        " " +
        this.loadedClient.city +
        " (" +
        this.loadedClient.province +
        ")"
      );
    },
  },
  async mounted() {
    this.setBreadcrumbs();

    await this.loadUserProfile();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Impostazioni", link: "/settings" },
        {
          title: "I tuoi dati",
          link: "/settings/profile",
        },
      ]);
    },
    async loadUserProfile() {
      if (this.loadedClient && this.loadedClient.length == 0) {
        this.isLoading = true;
        this.isUpdating = true;
      } else {
        this.isLoading = false;
        this.isUpdating = true;
      }

      try {
        await this.$store.dispatch("user/setClient");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      this.isLoading = false;
      this.isUpdating = false;
    },
    isEmpty(value) {
      if (value == "" || value == null) return true;
      else return false;
    },
  },
};
</script>
