<template>
  <div
    :class="getClasses()"
    class="flex items-center text-xs uppercase bg-opacity-50 px-2 py-0.5 rounded-md whitespace-nowrap"
  >
    {{ statusFetched }}
  </div>
</template>

<script>
export default {
  name: "BaseTagStatus",
  props: {
    status: String,
  },
  computed: {
    statusFetched() {
      return this.getText();
    },
  },
  methods: {
    getText() {
      if (this.status) {
        switch (this.status) {
          case "open":
            return "Aperto";
          case "progress":
            return "In lavorazione";
          default:
            return "Risolto";
        }
      } else {
        return "loading...";
      }
    },
    getClasses() {
      if (this.status) {
        switch (this.status) {
          case "open":
            return "bg-yellow-300 text-yellow-500";
          case "progress":
            return "bg-indigo-300 text-indigo-500";
          case "closed":
            return "bg-green-300 text-green-500";
          default:
            return "bg-green-300 text-green-500";
        }
      } else {
        return "loading...";
      }
    },
  },
};
</script>
