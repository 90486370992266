<template>
  <div
    v-if="show"
    class="grid grid-cols-3 items-center w-full sm:px-20 pt-6 pb-3 sm:pt-10 sm:pb-7"
  >
    <div
      :class="
        step == '0'
          ? 'border-t-2 border-gray-400'
          : 'border-t-2 border-gray-300'
      "
      class="relative text-center cursor-pointer"
      @click="goTo('0')"
    >
      <div
        class="absolute -top-3.5"
        style="right: calc(50% - 18px);"
        v-html="getIcon('0')"
      ></div>
      <p
        :class="step == '0' ? 'text-gray-500 font-medium' : 'text-gray-400'"
        class="hidden sm:block pt-5 text-xs sm:text-sm whitespace-nowrap uppercase"
      >
        Carrello
      </p>
      <div
        :class="step == '0' ? 'text-gray-900 font-medium' : 'text-gray-400'"
        class="sm:hidden pt-5 text-xs sm:text-sm whitespace-nowrap uppercase text-center"
      >
        <i-cart classes="h-5 w-5 mx-auto"></i-cart>
      </div>
    </div>
    <div
      :class="
        step == '1'
          ? 'border-t-2 border-gray-400'
          : 'border-t-2 border-gray-300'
      "
      class="relative text-center cursor-pointer"
      @click="goTo('1')"
    >
      <div
        class="absolute -top-3.5"
        style="right: calc(50% - 18px);"
        v-html="getIcon('1')"
      ></div>
      <p
        :class="step == '1' ? 'text-gray-500 font-medium' : 'text-gray-400'"
        class="hidden sm:block pt-5 text-xs sm:text-sm whitespace-nowrap uppercase"
      >
        Metodo di pagamento
      </p>
      <div
        :class="step == '1' ? 'text-gray-900 font-medium' : 'text-gray-400'"
        class="sm:hidden pt-5 text-xs sm:text-sm whitespace-nowrap uppercase text-center"
      >
        <i-credit-card classes="h-5 w-5 mx-auto"></i-credit-card>
      </div>
    </div>
    <div
      :class="
        step == '2'
          ? 'border-t-2 border-gray-400'
          : 'border-t-2 border-gray-300'
      "
      class="relative text-center cursor-pointer"
      @click="goTo('2')"
    >
      <div
        class="absolute -top-3.5"
        style="right: calc(50% - 18px);"
        v-html="getIcon('2')"
      ></div>
      <p
        :class="step == '2' ? 'text-gray-500 font-medium' : 'text-gray-400'"
        class="hidden sm:block pt-5 text-xs sm:text-sm whitespace-nowrap uppercase"
      >
        Conferma
      </p>
      <div
        :class="step == '2' ? 'text-gray-900 font-medium' : 'text-gray-400'"
        class="sm:hidden pt-5 text-xs sm:text-sm whitespace-nowrap uppercase text-center"
      >
        <i-clipboard-check classes="h-5 w-5 mx-auto"></i-clipboard-check>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutWizard",
  props: ["step", "show"],
  emits: ["setStep"],
  methods: {
    getIcon(number) {
      if (this.step == number)
        return (
          '<div class="px-1 bg-white"><div class="rounded-full m-0.5 px-1.5 text-sm text-green-500 border-2 border-green-400 flex items-center font-bold bg-white shadow-lg"><span class="">' +
          (parseInt(number) + 1).toString() +
          "</span></div></div>"
        );

      if (parseInt(this.step) < parseInt(number))
        return (
          '<div class="px-1 bg-white"><div class="rounded-full m-0.5 px-1.5 text-sm border border-gray-300 bg-gray-300 text-white flex items-center font-bold"><span class="">' +
          (parseInt(number) + 1).toString() +
          "</span></div></div>"
        );
      else
        return '<div class="px-1 bg-white"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-7 w-7 bg-white text-green-500"> <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/></svg></div>';
    },
    goTo(value) {
      this.$emit("setStep", value);
    },
  },
};
</script>
