<template>
  <router-link to="/payments/checkout">
    <div
      :class="
        $route.path == '/payments/checkout'
          ? 'bg-gray-400 text-gray-900'
          : 'hover:bg-gray-50 hover:bg-opacity-25 hover:text-gray-300 text-gray-400'
      "
      class="rounded-full cursor-pointer p-1 text-white"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 p-px pt-0.5 z-50"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        />
      </svg>
      <div
        v-if="
          (cart.services && cart.services.length > 0) ||
            (cart.items && cart.items.length > 0)
        "
        class="z-40 absolute top-1 right-0 rounded-full bg-red-500 bg-opacity-70 w-3 h-3 "
      ></div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "CartButton",
  computed: {
    cart() {
      return this.$store.getters["payments/cart"];
    },
    loadedClient() {
      return this.$store.getters["user/loadedClient"];
    },
  },
  watch: {
    loadedClient() {
      this.$store.commit("payments/setCartClient");
      this.$store.commit("payments/setPaymethod", { id: null });
    },
  },
};
</script>
