<template>
  <div>
    <simple-title :title="title"></simple-title>

    <transition name="slide-top-blur-2" v-for="(service, index) in additionals" :key="index">
      <div v-if="!isLoading"
        class="flex flex-col space-y-4 divide-y divide-gray-200 bg-gray-50 shadow-xl rounded-md px-4 py-3 mt-3 mb-8 border-l-4 border-gray-500">
        <riepilogue-table-row title="Nome" :value="service.item.name">
          <path
            d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
        </riepilogue-table-row>
        <riepilogue-table-row v-if="service.item.description != ''" title="Descrizione"
          :value="service.item.description">
          <path fill-rule="evenodd"
            d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
            clip-rule="evenodd" />
          <path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z" />
        </riepilogue-table-row>
        <riepilogue-table-row v-if="service.item.info != NULL" title="Informazioni" :value="service.item.info">
          <path fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd" />
        </riepilogue-table-row>
        <riepilogue-table-row title="Scadenza vincolo" :value="bond_expiration">
          <path fill-rule="evenodd"
            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
            clip-rule="evenodd" />
        </riepilogue-table-row>
        <riepilogue-table-row title="Prezzo" :value="priceFormat(service.item.price)">
          <path fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.736 6.979C9.208 6.193 9.696 6 10 6c.304 0 .792.193 1.264.979a1 1 0 001.715-1.029C12.279 4.784 11.232 4 10 4s-2.279.784-2.979 1.95c-.285.475-.507 1-.67 1.55H6a1 1 0 000 2h.013a9.358 9.358 0 000 1H6a1 1 0 100 2h.351c.163.55.385 1.075.67 1.55C7.721 15.216 8.768 16 10 16s2.279-.784 2.979-1.95a1 1 0 10-1.715-1.029c-.472.786-.96.979-1.264.979-.304 0-.792-.193-1.264-.979a4.265 4.265 0 01-.264-.521H10a1 1 0 100-2H8.017a7.36 7.36 0 010-1H10a1 1 0 100-2H8.472c.08-.185.167-.36.264-.521z"
            clip-rule="evenodd" />
        </riepilogue-table-row>
      </div>
    </transition>
  </div>
</template>

<script>
// import SkeletonInternetService from "@/ui/skeleton/services/SkeletonInternetService";
// import SkeletonInternetServiceMobile from "@/ui/skeleton/services/SkeletonInternetServiceMobile";
import RiepilogueTableRow from "@/ui/services/RiepilogueTableRow";
import moment from "moment";

export default {
  name: "ServiceAdditional",
  components: { RiepilogueTableRow },
  data() {
    return {
      isLoading: true,
      isUpdating: true,
      showClosed: false,
    };
  },
  computed: {
    service() {
      return this.$store.getters["services/loadedService"];
    },
    additionals() {
      return this.$store.getters["services/services"].filter((srv) => {
        return srv.main_id == this.service.id;
      });
    },
    title() {
      return `Servizi opzionali (${this.additionals.length})`;
    },
    bond_expiration() {
      return moment(this.service.bond_expiration_date).format("DD/MM/YYYY");
    },
    client() {
      return this.$store.getters["user/loadedClient"];
    },
    vat() {
      return this.$gf.getVATbyCode(this.client.vat_code)['multiplier']
    },
  },
  mounted() {
    this.setBreadcrumbs();
    this.isLoading = false;
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Servizi", link: "/services" },
        {
          title: this.service.item.category.name,
          link:
            "/services/" +
            this.service.type.toLowerCase() +
            "/" +
            this.service.id,
        },
        {
          title: "Opzionali",
          link:
            "/services/" +
            this.service.type.toLowerCase() +
            "/" +
            this.service.id +
            "/additional",
        },
      ]);
    },
    priceFormat(price) {
      return (this.vat * price).toFixed(2) + " € / mese";
    },
  },
};
</script>
