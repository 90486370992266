<template>
    <footer
        style="box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1)"
        class="md:hidden z-20 fixed shadow-inner bottom-0 h-max w-full text-gray-900"
    >
        <div class="grid grid-cols-3 bg-gray-100 text-gray-600 py-1 xs:py-2">
            <router-link to="/services">
                <div class="col-span-1 flex flex-col">
                    <div class="mx-auto mt-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            class="w-6 xs:w-8"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                            />
                        </svg>
                    </div>
                    <div class="mx-auto mb-2 text-xs">Servizi</div>
                </div>
            </router-link>
            <router-link to="/payments/invoices">
                <div class="col-span-1 flex flex-col">
                    <div class="mx-auto mt-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            class="w-6 xs:w-8"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                            />
                        </svg>
                    </div>
                    <div class="mx-auto mb-2 text-xs">Pagamenti</div>
                </div>
            </router-link>
            <router-link to="/support">
                <div class="col-span-1 flex flex-col">
                    <div class="mx-auto mt-2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            class="w-6 xs:w-8"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                            />
                        </svg>
                    </div>
                    <div class="mx-auto mb-2 text-xs">Supporto</div>
                </div>
            </router-link>
        </div>
    </footer>
</template>

<script>
export default {
    name: "TheFooter",
    data() {
        return {
            footerCol1: false,
            footerCol2: false,
            footerCol3: false,
        };
    },
    methods: {
        footerCol1Change() {
            this.footerCol1 = !this.footerCol1;
        },
        footerCol2Change() {
            this.footerCol2 = !this.footerCol2;
        },
        footerCol3Change() {
            this.footerCol3 = !this.footerCol3;
            // console.log(this.foterCol1);
        },
    },
};
</script>


<style scoped>
a:active,
a.router-link-active {
    @apply text-yellow-500;
}

.slide-enter-active {
    -moz-transition-duration: 1.3s;
    -webkit-transition-duration: 1.3s;
    -o-transition-duration: 1.3s;
    transition-duration: 1.3s;
    -moz-transition-timing-function: linear;
    -webkit-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
}

.slide-leave-active {
    -moz-transition-duration: 1.3s;
    -webkit-transition-duration: 1.3s;
    -o-transition-duration: 1.3s;
    transition-duration: 1.3s;
}

.slide-enter-to,
.slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
</style>
