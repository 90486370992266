<template>
    <base-layout :secondaryMenu="secondaryMenu">
        <template #header>
            <router-view name="pageTitle"></router-view>
        </template>

        <template #secondaryMenu>
            <router-view name="secondaryTitle"></router-view>
        </template>
        
        <template #menu>
            <side-menu></side-menu>
        </template>

        <template #main>
            <router-view name="default"> </router-view>
            <exit-button></exit-button>
        </template>
    </base-layout>
</template>

<script>
import SideMenu from "./modules/SideMenu";
import ExitButton from "./../../ui/buttons/ExitButton"

export default {
    name: "SettingsView",
    components: { SideMenu, ExitButton },
    data() {
        return {
            secondaryMenu: [
                {
                    label: "I tuoi dati",
                    link: this.relativeRoute("profile"),
                },
                {
                    label: "Modifica password",
                    link: this.relativeRoute("edit-password"),
                },
                {
                    label: "Metodi di pagamento",
                    link: this.relativeRoute("payment-methods"),
                },
                {
                    label: "Notifiche",
                    link: this.relativeRoute("notifications"),
                },
                {
                    label: "Documenti e contratti",
                    link: '/files',
                },
                // {
                //     label: "Informazioni",
                //     link: '/informations',
                // },
            ],
        };
    },
    methods: {
        relativeRoute(relative = "") {
            return "/settings/" + relative;
        },
    }
};
</script>