<template>
  <div
    :class="
      keepTheTitle
        ? 'flex-col xs:justify-between xs:items-center'
        : 'justify-end md:justify-between'
    "
    class="flex xs:flex-row"
  >
    <h2
      :class="keepTheTitle ? 'flex' : 'hidden md:flex'"
      class="pb-4 md:pl-4 text-md text-gray-500 font-bold uppercase"
    >
      {{ title }}
    </h2>
    <router-link :to="destination">
      <div
        class="flex justify-end pb-4 items-center text-gray-400 hover:text-yellow-400"
      >
        <div
          class="flex flex-row items-center space-x-1"
          :content="tooltip"
          v-tippy="{ placement: 'top', arrow: false }"
        >
          <div class="uppercase text-sm pt-1">{{ label }}</div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-5"
            >
              <slot></slot>
            </svg>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "BaseActionTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
    keepTheTitle: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    destination: {
      type: String,
      default: "",
    },
    tooltip: {
      type: String,
      default: "",
    },
  },
};
</script>
