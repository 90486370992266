<template>
  <div class="flex flex-col pr-4 mr-4">
    <router-link :to="relativeRoute('profile')">
      <pills-button
        class="mr-1 lg:mr-4 mt-1 mainButton min-w-max"
        title="I tuoi dati"
      >
        <i-user classes="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"></i-user>
      </pills-button>
    </router-link>

    <router-link :to="relativeRoute('edit-password')">
      <pills-button
        class="mr-1 lg:mr-4 mt-3 mainButton min-w-max"
        title="Modifica password"
      >
        <i-key classes="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"></i-key>
      </pills-button>
    </router-link>

    <router-link :to="relativeRoute('payment-methods')">
      <pills-button
        class="mr-1 lg:mr-4 mt-3 mainButton min-w-max"
        title="Metodi di pagamento"
      >
        <i-credit-card
          classes="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"
        ></i-credit-card>
      </pills-button>
    </router-link>

    <router-link :to="relativeRoute('notifications')">
      <pills-button
        class="mr-1 lg:mr-4 mt-3 mainButton min-w-max"
        title="Notifiche"
      >
        <i-bell classes="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"></i-bell>
      </pills-button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  methods: {
    relativeRoute(relative = "") {
      return "/settings/" + relative;
    },
  },
};
</script>
