import Cookies from 'js-cookie'

export default {
  async login(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/login`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: payload.email,
        password: payload.password
      })
    });

    const responseData = await response.json();


    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      error.title = responseData.title;
      throw error;
    }

    Cookies.set('token', responseData.token, { expires: 365 });
    Cookies.set('userId', responseData.userId, { expires: 365 });
    Cookies.set('clientId', responseData.clientId, { expires: 365 });
    // Cookies.set('token', responseData.token, { expires: 365, domain: 'my.nibble.it', secure: true });
    // Cookies.set('userId', responseData.userId, { expires: 365, domain: 'my.nibble.it', secure: true });
    // Cookies.set('clientId', responseData.clientId, { expires: 365, domain: 'my.nibble.it', secure: true });

    context.commit('setUser', {
      token: responseData.token,
      userId: responseData.userId,
      clientId: responseData.clientId,
    });
  },
  tryLogin(context) {
    const token = Cookies.get('token');
    const userId = Cookies.get('userId');
    const clientId = Cookies.get('clientId');

    if (token && userId) {
      context.commit('setUser', {
        token: token,
        userId: userId,
        clientId: clientId,
      });
    }
  },

  async logout(context) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/logout`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
    });

    const responseData = await response.json();


    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      error.title = responseData.title;
      throw error;
    }

    // Cookies.remove('token', { path: '', domain: 'my.nibble.it' });
    // Cookies.remove('userId', { path: '', domain: 'my.nibble.it' });
    // Cookies.remove('clientId', { path: '', domain: 'my.nibble.it' });
    Cookies.remove('token');
    Cookies.remove('userId');
    Cookies.remove('clientId');

    context.commit('reset');
  },

  autoLogout(context) {
    context.dispatch('logout');
    context.commit('setAutoLogout');
  },

  async forgotPassword(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/forgot-password`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: payload.email,
      })
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      error.title = responseData.title;
      throw error;
    }

  },

  async resetPassword(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/reset-password`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: payload.token,
        password: payload.password,
        confirmedPassword: payload.confirmedPassword,
      })
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      error.title = responseData.title;
      throw error;
    }

  },

  async verifyEmail(context, payload) {

    let url =
      `${process.env.VUE_APP_API_URL}/email/verify/${payload.token}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

  },
};
