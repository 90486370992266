<template>
    <p class="text-sm my-1 text-gray-300 sm:text-gray-900">
        <slot></slot>
    </p>
</template>

<script>
export default {
    name: "Paragraph",
}
</script>