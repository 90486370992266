<template>
  <div class="bg-gradient-to-b from-white to-gray-50 rounded-md shadow">
    <div
      v-show="showWizard"
      class="sticky top-0 bg-white border-b border-gray-200 shadow rounded-t"
    >
      <checkout-wizard
        :step="step"
        :show="showWizard"
        @setStep="setStep"
      ></checkout-wizard>
    </div>

    <div :class="showActions ? '' : 'rounded'" class="shadow-inner bg-white">
      <collapse-transition>
        <order-summary v-if="step == '0'" :step="step"></order-summary>
      </collapse-transition>
      <collapse-transition>
        <paymethod-selection v-if="step == '1'"></paymethod-selection>
      </collapse-transition>
      <collapse-transition>
        <checkout-pay v-if="step == '2'" :step="step"></checkout-pay>
      </collapse-transition>
    </div>
    <div class="px-4 py-3 sm:px-6 sm:py-5 flex" v-if="showActions">
      <!-- actions -->
      <div
        :class="step == '0' ? 'justify-end' : 'justify-between'"
        class="flex-grow flex "
      >
        <base-button
          @click.prevent="prev()"
          label="Indietro"
          :isLight="true"
          v-if="step != 0"
        >
        </base-button>
        <base-button @click.prevent="next()" :label="label"> </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import CheckoutWizard from "./CheckoutWizard";

import OrderSummary from "@/components/checkout/modules/OrderSummary";
import PaymethodSelection from "@/components/checkout/modules/PaymethodSelection";
import CheckoutPay from "./CheckoutPay";

import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";

export default {
  name: "Checkout",
  components: {
    CheckoutWizard,
    OrderSummary,
    PaymethodSelection,
    CheckoutPay,
    CollapseTransition,
  },
  data() {
    return {
      step: "0",
      showActions: null,
      showWizard: null,
    };
  },
  watch: {
    step() {
      this.showActions = this.computeActions();
      this.showWizard = this.computeWizard();
    },
    cart(value, oldValue) {
      if (JSON.stringify(value) != JSON.stringify(oldValue)) {
        this.showActions = this.computeActions();
        this.showWizard = this.computeWizard();
      }
    },
    paymethod() {
      this.showActions = this.computeActions();
    },
  },
  computed: {
    cart() {
      return this.$store.getters["payments/cart"];
    },
    paymethod() {
      return this.$store.getters["payments/paymethod"];
    },
    services() {
      return this.cart.services;
    },
    items() {
      return this.cart.items;
    },
    label() {
      switch (this.step) {
        case "0":
          return "Procedi al pagamento";
        case "1":
          return "Procedi";
        case "2":
          return "Paga";
        default:
          return "Avanti";
      }
    },
  },
  mounted() {
    this.setBreadcrumbs();
    this.step = "0";
    this.showActions = this.computeActions();
    this.showWizard = this.computeWizard();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Checkout", link: "/payments/checkout" },
      ]);
    },
    computeActions() {
      switch (this.step) {
        case "0":
          if (this.services.length == 0 && this.items.length == 0) return false;
          else return true;
        case "1":
          if (
            this.paymethod == "" ||
            this.paymethod == null ||
            this.paymethod == undefined
          )
            return false;
          else return true;
        case "2":
          return false;
        default:
          return true;
      }
    },
    computeWizard() {
      if (this.services.length == 0 && this.items.length == 0) return false;
      else return true;
    },
    next() {
      let c = parseInt(this.step);
      c++;
      this.step = c.toString();
    },
    prev() {
      let c = parseInt(this.step);
      c--;
      this.step = c.toString();
    },
    setStep(e) {
      if (e == "2" && !this.paymethod) return;
      else this.step = e;
    },
  },
};
</script>
