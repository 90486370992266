export default {
  setServices(state, payload) {
    state.services = payload;
  },
  setLoadedService(state, payload) {
    state.loadedService = payload;
  },
  setRechargeItems(state, payload) {
    state.rechargeItems = payload;
  },
  setCronoItems(state, payload) {
    state.cronoItems = payload;
  },
};