<template>
    <div
        class="flex items-center justify-start  h-8 lg:h-10 pl-1 lg:pl-2 pr-3 text-md lg:text-lg"
    >
        <div>
            <slot></slot>
        </div>
        <div>
            <p class="text-gray-900 items-center pt-0.5" v-html="title"></p>
        </div>
        <span
            v-if="hasBadge"
            class="bg-gradient-to-l from-yellow-500 to-yellow-400 rounded-md px-1 py-0.5 ml-2 font-bold text-xs"
            ><svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="text-rey-900 w-5"
            >
                <path
                    fill-rule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                /></svg
        ></span>
    </div>
</template>

<script>
export default {
    name: "PillsButton",
    props: {
        title: {
            type: String,
            default: "",
        },
        hasBadge: {
            type: Boolean,
            default: false,
        }
    }
};
</script>