<template>
  <div>
    <simple-title title="Ricarica" :isUpdating="isUpdating"></simple-title>

    <transition name="slide-top-blur-2">
      <div class="mt-5 md:mt-0" v-if="!isLoading">
        <div class="shadow sm:rounded-md sm:overflow-hidden">
          <div class="px-4 py-5 bg-white space-y-4 sm:p-6 divide-y divide-gray-200">
            <riepilogue-table-row class="text-md" title="Numero" :value="phone">
              <path
                d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </riepilogue-table-row>
            <div class="flex justify-between pt-4" v-if="!isLoading && !isUpdating">
              <div class="flex-shrink">
                <span class="text-md text-gray-900">
                  Importo
                </span>
              </div>
              <div class="flex-grow flex flex-row justify-end">
                <div class="flex flex-col space-y-3 ">
                  <div class="flex items-center cursor-pointer" v-for="voipItem in items" :key="voipItem.id">
                    <input :id="voipItem.code" name="method" :value="voipItem.code" type="radio" v-model="item"
                      class="h-4 w-4 focus:ring-gray-600 text-gray-600" />
                    <label :for="voipItem.code"
                      class="ml-2 block text-md font-bold text-right text-gray-600 w-full cursor-pointer">
                      {{ (voipItem.price * 1.22).toFixed(0) }} €
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="flex pt-4">
              <base-spinner-mini></base-spinner-mini>
              <span class="ml-3">Carico importi</span>
            </div>
          </div>
          <div class="px-4 py-3 bg-gray-50 sm:px-6 flex">
            <!-- actions -->
            <div class="flex-grow flex justify-end">
              <base-button @click.prevent="addToCart()" label="Aggiungi al carrello"></base-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import RiepilogueTableRow from "./../../../../../ui/services/RiepilogueTableRow";

export default {
  name: "VoipRecharge",
  props: ["id"],
  components: {
    RiepilogueTableRow,
  },
  data() {
    return {
      isLoading: true,
      isUpdating: true,
      item: null,
    };
  },
  computed: {
    service() {
      return this.$store.getters["services/loadedService"];
    },
    phone() {
      return this.service.details.number;
    },
    items() {
      return this.$store.getters["services/rechargeItems"];
    },
  },
  async mounted() {
    this.setBreadcrumbs();
    this.item = "RIC20";

    await this.loadRechargeItems();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Servizi", link: "/services" },
        {
          title: this.service.item.category.name,
          link: "/services/voip/" + this.id,
        },
        {
          title: "Ricarica",
          link: "/services/voip/" + this.id + "/recharge",
        },
      ]);
    },
    getItem(itemCode) {
      return this.items.find((item) => {
        return item.code == itemCode;
      });
    },
    addToCart() {
      let item = this.getItem(this.item);
      item.quantity = 1;
      item.details = this.service;
      item.details.invoice_notes =
        "Ricarica telefonica numero " + item.details.details.number;

      try {
        this.$store.commit("payments/addToCart", {
          items: [item],
        });
        this.$router.replace("/payments/checkout");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async loadRechargeItems() {
      if (this.items.length == 0) {
        this.isLoading = true;
        this.isUpdating = true;
      } else {
        this.isLoading = false;
        this.isUpdating = true;
      }
      try {
        await this.$store.dispatch("services/getRechargeItems");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
      this.isUpdating = false;
    },
  },
};
</script>
