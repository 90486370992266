export default {
  services(state) {
    return state.services;
  },
  loadedService(state) {
    return state.loadedService;
  },
  rechargeItems(state) {
    return state.rechargeItems;
  },
  cronoItems(state) {
    return state.cronoItems;
  },
  serviceStatus(state) {
    const status = {
      textual: '',
      enum: 0
    }
    switch (state.loadedService.status) {
      case '0':
        status.textual = 'Disattivo';
        status.enum = 0;
        break;
      default:
        status.textual = 'Attivo';
        status.enum = state.loadedService.status;
        break;
    }
    return status;
  },
  serviceAddress(state) {
    return state.loadedService.address.address + ' ' + state.loadedService.address.civic + ', ' + state.loadedService.address.city + ' ' + state.loadedService.address.cap + ' (' + state.loadedService.address.province + ')';
  },
  serviceBillingFrequency(state) {
    const frequency = {
      textual: '',
      month: 0
    }
    switch (state.loadedService.billing_frequency) {
      case 2:
        frequency.textual = 'Bimestrale';
        frequency.month = 2;
        break;
      case 6:
        frequency.textual = 'Semestrale';
        frequency.month = 6;
        break;
      case 12:
        frequency.textual = 'Annuale';
        frequency.month = 12;
        break;
      default:
        frequency.textual = 'Non definito';
        frequency.month = 0;
        break;
    }
    return frequency;
  }
};