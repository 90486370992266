<template>
    <main-layout>
        <div>
            <slot class="hidden md:block" name="header"></slot>

            <div class="flex flex-cols">
                <div class="flex-grow flex flex-col text-left">
                    <!-- Mobile only -->
                    <mobile-secondary-menu
                        @toggle="toggleSecondaryMenu"
                        @toggleDown="toggleDownSecondaryMenu"
                        :menu="secondaryMenu"
                    >
                        <slot name="secondaryMenu"></slot>
                    </mobile-secondary-menu>
                </div>
            </div>
        </div>
        <div>
            <div
                :class="isOpen ? 'just-blur' : 'just-blur-back'"
                class="rounded-lg w-full mt-1 sm:mt-3 md:mt-4 lg:mt-5 xl:mt-6"
            >
                <div class="flex flex-row">
                    <div class="hidden md:block flex-shrink">
                        <slot name="menu"></slot>
                    </div>
                    <div class="flex-grow py-2 md:py-0">
                        <slot name="main"></slot>
                    </div>
                </div>
            </div>
        </div>
    </main-layout>
</template>

<script>
import MobileSecondaryMenu from "@/components/menu/MobileSecondaryMenu";

export default {
    name: "BaseLayout",
    components: { MobileSecondaryMenu },
    props: ["secondaryMenu"],
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        toggleSecondaryMenu() {
            this.isOpen = !this.isOpen;
        },
        toggleDownSecondaryMenu() {
            this.isOpen = false;
        },
    },
};
</script>

<style scoped>
.just-blur {
    opacity: 0.25;
    filter: blur(3px);
    transition: 0.4s ease-out;
}

.just-blur-back {
    opacity: 1;
    filter: blur(0px);
    transition: 0.4s ease-in;
}
</style>
