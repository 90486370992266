<template>
  <div>
    <action-title
      title="Metodi di pagamento"
      label="Modifica"
      destination="/settings/payment-methods/edit"
      :tooltip="
        canEdit
          ? 'Modifica metodo di pagamento'
          : 'Richiesta di modifica del metodo di pagamento'
      "
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
    /></action-title>

    <div v-show="!isLoading">
      <transition name="slide-top-blur-2">
        <div
          v-if="!isLoading"
          class="flex flex-col space-y-4 divide-y divide-gray-200 bg-gray-50 shadow-xl rounded-md px-4 py-3 mb-8"
        >
          <riepilogue-table-row title="Metodo in uso" :value="paymethod">
            <path
              fill-rule="evenodd"
              d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
              clip-rule="evenodd"
            />
          </riepilogue-table-row>
        </div>
      </transition>

      <!-- se è Bonifico -->
      <transition name="slide-top-blur-2">
        <div class="mt-12" v-if="isBO">
          <div
            class="bg-gray-50 text-gray-400 shadow-inner rounded-md p-4 flex border border-gray-200 flex-col space-y-1 sm:space-y-2"
          >
            <h2
              class="text-md sm:text-lg pb-3 text-gray-500 uppercase font-bold"
            >
              Nostre coordinate bancarie
            </h2>

            <p class="text-sm sm:text-md">
              <strong>Banca :</strong> Banca di Cesena
            </p>
            <p class="text-sm sm:text-md">
              <strong>Codice IBAN :</strong>
              IT95L0707067701000000847084
            </p>
            <p class="text-sm sm:text-md">
              <strong>Intestato a :</strong> Nibble S.r.l.
            </p>
            <p class="text-sm sm:text-md">
              <strong>Causale :</strong> Pagamento fattura n. #
            </p>
          </div>
        </div>
      </transition>

      <!-- se è Carta di credito -->
      <div class="flex flex-col space-y-8 mt-12">
        <cards-list v-if="!isLoading"></cards-list>
        <subscriptions-list v-if="isCC"></subscriptions-list>
      </div>

      <div class="mt-24 flex text-center">
        <div class="flex-grow flex items-center justify-center text-gray-500">
          <div
            content="Nibble mette i suoi clienti al centro del proprio business, che diventano quindi la risorsa più importante da proteggere. Scopri di più su cosa facciamo per proteggere la tua privacy."
            v-tippy="{ placement: 'top', arrow: true }"
            class="flex flex-row items-center py-2 px-4 bg-gray-300 rounded-sm shadow-sm hover:bg-gray-200 cursor-pointer hover:shadow-lg"
          >
            <span class="text-lg"
              >Informazioni sul trattamento dei dati bancari sensibili</span
            >
            <i-information-circle classes="w-5 h-5 ml-2"></i-information-circle>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoading">
      <base-spinner-mini></base-spinner-mini>
    </div>
  </div>
</template>

<script>
import RiepilogueTableRow from "./../../../ui/services/RiepilogueTableRow";
import CardsList from "./payments/CardsList";
import SubscriptionsList from "./payments/SubscriptionsList";

export default {
  name: "PaymentMethods",
  components: {
    RiepilogueTableRow,
    CardsList,
    SubscriptionsList,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    paymentMethod() {
      return this.$store.getters["user/loadedClient"].payment;
    },
    paymethod() {
      switch (this.paymentMethod) {
        case "AS":
          return "Assegno";
        case "BO":
          return "Bonifico bancario";
        case "CC":
          return "Carta di Credito";
        case "CO":
          return "Contanti";
        case "PAY":
          return "PayPal";
        case "PP":
          return "PayPal";
        case "RIDRD":
          return "RID Data Fattura";
        case "RB30":
          return "RIBA 30gg";
        default:
          return "carico...";
      }
    },
    canEdit() {
      switch (this.paymentMethod) {
        case "AS":
        case "BO":
        case "CO":
          return true;
        default:
          return false;
      }
    },
    isCC() {
      switch (this.paymentMethod) {
        case "CC":
        case "PP":
        case "PAY":
          return true;
        default:
          return false;
      }
    },
    isBO() {
      return this.paymentMethod == "BO" ? true : false;
    },
  },
  async mounted() {
    this.setBreadcrumbs();

    await this.loadUserProfile();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Impostazioni", link: "/settings" },
        {
          title: "Metodi di pagamento",
          link: "/settings/payment-methods",
        },
      ]);
    },
    async loadUserProfile() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("user/setClient");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      this.isLoading = false;
    },
  },
};
</script>
