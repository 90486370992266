<template>
  <div>
    <base-dialog :show="!!error" :title="errorTitle" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>
    <base-spinner v-if="isLoading"></base-spinner>
    <div
      class="
        min-h-screen
        flex
        items-center
        justify-center
        bg-gray-100
        py-12
        px-4
        sm:px-6
        lg:px-8
      "
    >
      <div class="max-w-md w-full space-y-8">
        <div :class="isLoading ? 'blur-scale-2' : ''">
          <img
            class="
              mx-auto
              h-16
              w-auto
              py-3
              px-1
              border-transparent
              rounded-2xl
              bg-gray-900
              shadow-xl
            "
            src="/assets/logos/nibble.svg"
            alt="logo"
          />
          <h2 class="mt-6 text-center text-2xl font-normal text-gray-900">
            Accedi a myNibble
          </h2>
          <!-- <p class="mt-2 text-center text-sm text-gray-600">
                                O
                                <a
                                    href="/"
                                    class="font-medium text-yellow-500 hover:text-yellow-400"
                                >
                                    torna indietro sul sito Nibble
                                </a>
                            </p> -->
        </div>
        <form class="mt-8 space-y-6" @submit.prevent="submitForm">
          <input type="hidden" name="remember" value="true" />
          <div
            :class="isLoading ? 'blur-scale-3' : ''"
            class="rounded-md shadow-sm -space-y-px"
          >
            <div>
              <label for="email-address" class="sr-only">Email address</label>
              <input
                id="email-address"
                name="email"
                type="email"
                autocomplete="email"
                required
                v-model.trim="email"
                class="
                  appearance-none
                  rounded-none
                  relative
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-900
                  rounded-t-md
                  focus:outline-none
                  focus:ring-yellow-500
                  focus:border-yellow-500
                  focus:z-10
                  sm:text-sm
                "
                placeholder="Email address"
              />
            </div>
            <div>
              <label for="password" class="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required
                v-model.trim="password"
                class="
                  appearance-none
                  rounded-none
                  relative
                  block
                  w-full
                  px-3
                  py-2
                  border border-gray-300
                  placeholder-gray-500
                  text-gray-900
                  rounded-b-md
                  focus:outline-none
                  focus:ring-yellow-500
                  focus:border-yellow-500
                  focus:z-10
                  sm:text-sm
                "
                placeholder="Password"
              />
            </div>
          </div>

          <div
            :class="isLoading ? 'blur-scale-4' : ''"
            class="flex items-center justify-between"
          >
            <div class="flex items-center">
              <input
                id="remember_me"
                name="remember_me"
                type="checkbox"
                class="
                  h-4
                  w-4
                  text-yellow-500
                  focus:ring-indigo-500
                  border-gray-300
                  rounded
                "
              />
              <label
                for="remember_me"
                class="ml-2 mt-1 block text-sm text-gray-900"
              >
                Rimani collegato
              </label>
            </div>

            <div class="text-sm">
              <router-link to="/forgot-password">
                <a
                  href="#"
                  class="font-medium mt-1 text-yellow-500 hover:text-yellow-400"
                >
                  Password dimenticata?
                </a>
              </router-link>
            </div>
          </div>

          <div
            :class="isLoading ? 'blur-scale-3' : ''"
            v-if="!formIsValid"
            class="
              text-sm text-red-600
              border-l-4 border-red-500
              bg-red-100
              p-3
            "
          >
            Inserisci un indirizzo email e una password validi.
          </div>
          <div :class="isLoading ? 'blur-scale-4' : ''">
            <button
              type="submit"
              class="
                group
                relative
                w-full
                flex
                justify-center
                py-2
                px-4
                border border-transparent
                text-sm
                font-medium
                rounded-md
                text-white
                bg-yellow-500
                hover:bg-yellow-700
              "
            >
              <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <!-- Heroicon name: lock-closed -->
                <svg
                  class="h-5 w-5 text-yellow-400 group-hover:text-yellow-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              Accedi
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      formIsValid: true,
      mode: "login",
      isLoading: false,
      error: null,
      errorTitle: "An error occurred",
    };
  },
  methods: {
    async submitForm() {
      this.formIsValid = true;
      if (
        this.email === "" ||
        !this.email.includes("@") ||
        this.password.length < 6
      ) {
        this.formIsValid = false;
        return;
      }

      this.isLoading = true;

      const actionPayload = {
        email: this.email,
        password: this.password,
      };

      try {
        // do Login
        await this.$store.dispatch("login", actionPayload);
        const redirectUrl = "/" + (this.$route.query.redirect || "services");
        this.$router.replace(redirectUrl);
      } catch (err) {
          this.error = err.message || "Failed to authenticate, try later.";
          this.errorTitle = err.title || "An error occurred";
      }

      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
  },
};
</script>

<style scoped>
.blur-scale-2 {
  opacity: 0.8;
  filter: blur(4px);
  transform: scale(0.9);
  transition: all 0.2s linear;
}
.blur-scale-3 {
  opacity: 0.8;
  filter: blur(4px);
  transform: scale(0.9);
  transition: all 0.3s linear;
}
.blur-scale-4 {
  opacity: 0.8;
  filter: blur(4px);
  transform: scale(0.9);
  transition: all 0.4s linear;
}
</style>
