<template>
  <base-layout :secondaryMenu="secondaryMenu">
    <template #header>
      <router-view name="pageTitle"></router-view>
    </template>

    <template #secondaryMenu>
      <router-view name="secondaryTitle"></router-view>
    </template>

    <template #menu>
      <side-menu></side-menu>
    </template>

    <template #main>
      <router-view name="default"> </router-view>
    </template>
  </base-layout>
</template>

<script>
import SideMenu from "./modules/SideMenu";

export default {
  name: "PaymentsView",
  components: { SideMenu },
  data() {
    return {
      secondaryMenu: [
        {
          label: "Le tue fatture",
          link: this.relativeRoute("invoices"),
        },
      ],
    };
  },
  methods: {
    relativeRoute(relative = "") {
      return "/payments/" + relative;
    },
  },
};
</script>
