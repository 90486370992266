export default {
  async getUserFiles(context) {
    let url =
      `${process.env.VUE_APP_API_URL}/api/user/files`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getUserFiles', responseData);

    context.commit('setUserFiles', responseData.files);
  },

  async storeFiles(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/api/user/files/store`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
      body: JSON.stringify({
        filename: payload.filename,
        description: payload.description,
        filelist: payload.filelist,
        model: payload.model,
        id: payload.id
      })
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('storeFiles', responseData);

    context.commit('setUserFiles', responseData.files);
  },

  async downloadFile(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/api/user/files/download`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
      body: JSON.stringify({
        path: payload.file.path
      })
    })

    // manage data
    const arrayBuffer = await response.arrayBuffer();
    var blob = new Blob([arrayBuffer], { type: "application/" + payload.file.ext });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    
    // manage filename
    let filename = payload.file.filename + '.' + payload.file.ext;
    if (payload.file.filename.includes(`.${payload.file.ext}`)) filename = payload.file.filename;
    link.download = filename;

    // show download window
    link.click();
  },
};
