import Cookies from 'js-cookie'
import router from '@/router'

export default {
  async getUser(context) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/user`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getUser', responseData);

    context.commit('setUser', responseData.user);
  },

  async setClient(context, payload) {

    const old = Cookies.get('clientId');

    let url =
      `${process.env.VUE_APP_API_URL}/user/setClient`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
      body: JSON.stringify({
        clientId: payload.clientId
      })
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('setClient', responseData);
    Cookies.set('clientId', responseData.client.id, { expires: 365 });
    // Cookies.set('clientId', responseData.client.id, { expires: 365, domain: 'my.nibble.it', secure: true });


    if (old != responseData.client.id) router.push("/");

    context.commit('setUserProfile', responseData.client);
  },


  async editPayment(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/api/user/edit-payment`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
      body: JSON.stringify({
        method: payload.method
      })
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('editPayment', responseData);

    return responseData.url;
    // context.commit('setUserProfile', responseData.user);
  },

};
