import TheHeader from './../../layout/TheHeader'
import TheFooter from './../../layout/TheFooter'
import BlankView from './../../views/misc/BlankView'
// import BlankTitle from './../../views/misc/BlankTitle'
// import SampleView from './../../views/misc/SampleView'

import SettingsView from './../../views/settings/SettingsView'
import ProfileInfo from './../../views/settings/modules/ProfileInfo'
import EditPassword from './../../views/settings/modules/EditPassword'
import PaymentMethods from './../../views/settings/modules/PaymentMethods'
import NewCard from './../../views/settings/modules/NewCard'
import EditPaymethod from './../../views/settings/modules/EditPaymethod'
import Notifications from './../../views/settings/modules/Notifications'

// Settings Titles
import GenericSettingsTitle from './../../views/settings/pageTitles/GenericSettingsTitle'
import ProfileInfoTitle from './../../views/settings/pageTitles/ProfileInfoTitle'
import EditPasswordTitle from './../../views/settings/pageTitles/EditPasswordTitle'
import PaymentMethodsTitle from './../../views/settings/pageTitles/PaymentMethodsTitle'
import NotificationsTitle from './../../views/settings/pageTitles/NotificationsTitle'

export default [
    {
        path: '/settings',
        name: 'settings',
        components: {
            header: TheHeader,
            default: SettingsView,
            footer: TheFooter
        },
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                redirect: '/settings/profile'
            },
            {
                path: 'profile',
                name: 'profile',
                components: {
                    pageTitle: GenericSettingsTitle,
                    secondaryTitle: ProfileInfoTitle,
                    default: ProfileInfo
                }
            },
            {
                path: 'edit-password',
                name: 'edit-password',
                components: {
                    pageTitle: GenericSettingsTitle,
                    secondaryTitle: EditPasswordTitle,
                    default: EditPassword
                }
            },
            {
                path: 'payment-methods',
                components: {
                    pageTitle: GenericSettingsTitle,
                    secondaryTitle: PaymentMethodsTitle,
                    default: BlankView
                },
                children: [
                    {
                        path: '',
                        name: 'payment-methods-list',
                        component: PaymentMethods
                    },
                    {
                        path: 'edit',
                        name: 'edit-paymethod',
                        component: EditPaymethod
                    },
                    {
                        path: 'new-card',
                        name: 'new-card',
                        component: NewCard
                    }
                ]
            },
            {
                path: 'notifications',
                name: 'notifications',
                components: {
                    pageTitle: GenericSettingsTitle,
                    secondaryTitle: NotificationsTitle,
                    default: Notifications
                }
            }
        ]
    }
]