export default {
    setCards(state, payload) {
        state.cards = payload;
    },
    setSubscriptions(state, payload) {
        state.subscriptions = payload;
    },
    setNewCardSetupIntent(state, payload) {
        state.newCard.setupIntent = payload;
    },
};