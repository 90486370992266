<template>
  <div v-if="hasTickets">
    <div>
      <div
        class="flex flex-row justify-between mb-4 sm:items-center p-2"
      >
        <h2 class="text-xl font-medium text-gray-900">
          Aperti / In lavorazione
          <span class="ml-2 text-gray-400">{{ tickets.open.length }}</span>
        </h2>
        <base-refresh-string
          timePointer="tickets/updateDatetime"
          @refresh="loadTickets()"
          :isUpdating="isUpdating"
        ></base-refresh-string>
      </div>
      <div v-for="ticket in tickets.open" :key="ticket.id" class="mb-8">
        <transition name="slide-top-blur-2">
          <div v-if="!isLoading">
            <base-ticket
              :isUpdating="isUpdating"
              :ticket="ticket"
              :subcategory="getSubcategory(ticket.subcategory_id)"
            ></base-ticket>
          </div>
        </transition>
      </div>
      <div
        v-if="tickets.open.length == 0"
        class="bg-gray-100 pb-4 text-gray-500"
      >
        Nessun ticket aperto al momento
      </div>
    </div>

    <div class="mt-10" v-show="tickets.closed.length > 0">
      <div
        class="flex justify-between mb-3 hover:shadow-md cursor-pointer hover:border-b border-gray-300 rounded p-2"
        @click="toggleClosed"
      >
        <h2 class="text-xl font-medium text-gray-900">
          Risolti
          <span class="ml-2 text-gray-400">{{ tickets.closed.length }}</span>
        </h2>
        <div v-if="!showClosed" class="flex items-center text-gray-600">
          <span> Mostra </span>
          <i-chevron-down classes="w-6 h-6"></i-chevron-down>
        </div>
        <div v-else class="flex items-center text-gray-600">
          <span> Nascondi </span>
          <i-chevron-up classes="w-6 h-6"></i-chevron-up>
        </div>
      </div>
      <div v-for="ticket in tickets.closed" :key="ticket.id" class="mb-8">
        <transition name="slide-top-blur-2">
          <div v-if="!isLoading && showClosed">
            <base-ticket
              :isUpdating="isUpdating"
              :ticket="ticket"
              :subcategory="getSubcategory(ticket.subcategory_id)"
            ></base-ticket>
          </div>
        </transition>
      </div>
    </div>
  </div>
  <div v-else-if="isLoading">
    <skeleton-internet-service
      class="hidden md:block"
    ></skeleton-internet-service>
    <skeleton-internet-service-mobile
      class="md:hidden"
    ></skeleton-internet-service-mobile>
  </div>
  <div v-else class="flex flex-col mx-auto">
    <img src="/assets/undraw/empty.svg" class="md:w-1/2 mx-auto px-10" alt="" />
    <div class="mx-auto p-4 md:p-10 flex-flex-col space-y-4">
      <h1 class="text-gray-600 text-2xl md:text-3xl text-center">
        Non ci sono ticket aperti!
      </h1>
      <h1 class="text-gray-400 pext-lg md:text-xl text-center">
        Se hai un problema puoi aprire una segnalazione da qui
      </h1>
    </div>
    <router-link to="/support/tickets/new" class="mx-auto pb-10 md:pb-20">
      <base-button label="Nuovo ticket" :isLight="false" :hasIcon="true">
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
          clip-rule="evenodd"
        />
      </base-button>
    </router-link>
  </div>
</template>

<script>
import SkeletonInternetService from "@/ui/skeleton/services/SkeletonInternetService";
import SkeletonInternetServiceMobile from "@/ui/skeleton/services/SkeletonInternetServiceMobile";
import BaseTicket from "@/ui/tickets/BaseTicket";

export default {
  name: "TicketsList",
  components: {
    SkeletonInternetService,
    SkeletonInternetServiceMobile,
    BaseTicket,
  },
  data() {
    return {
      isLoading: true,
      isUpdating: true,
      showClosed: false,
    };
  },
  computed: {
    tickets() {
      return this.$store.getters["tickets/tickets"];
    },
    hasTickets() {
      return this.tickets.open.length > 0 || this.tickets.closed.length > 0;
    },
    subcategories() {
      return this.$store.getters["tickets/subcategories"];
    },
  },
  async mounted() {
    this.setBreadcrumbs();

    if (this.$store.getters["tickets/subcategories"].length == 0) {
      await this.loadSubcategories();
    }

    await this.loadTickets();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Supporto", link: "/support" },
        { title: "I tuoi ticket", link: "/support/tickets" },
      ]);
    },
    async loadTickets() {
      if (this.tickets.open.length == 0 && this.tickets.closed.length == 0) {
        this.isLoading = true;
        this.isUpdating = true;
      } else {
        this.isLoading = false;
        this.isUpdating = true;
      }

      try {
        await this.$store.dispatch("tickets/getTickets");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      this.isLoading = false;
      this.isUpdating = false;
    },
    async loadSubcategories() {
      this.isUpdating = true;
      try {
        await this.$store.dispatch("tickets/getSubcategories");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isUpdating = false;
    },
    getSubcategory(id) {
      return this.subcategories.find((category) => {
        return category.id == id;
      });
    },
    toggleClosed() {
      this.showClosed = !this.showClosed;
    },
  },
};
</script>
