<template>
    <div :class="isSmall ? 'text-sm' : 'text-md'" class="pt-1">
        <div class="flex flex-col sm:flex-row">
            <div :class="isDark ? 'text-gray-200' : 'text-gray-700'" class="flex-grow sm:flex-1 text-left">{{ title }}</div>
            <div :class="isDark ? 'text-gray-400' : 'text-gray-500'" class="flex-grow sm:flex-1 sm:text-right">
                <div class="flex sm:justify-end items-center">
                    <div class="mr-2 sm:mr-0 sm:ml-2 sm:order-last">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            :class="isDark ? 'text-gray-300' : 'text-gray-400'"
                            class="w-4"
                            fill="currentColor"
                        >
                            <slot></slot>
                        </svg>
                    </div>
                    <div :class="valueClass" class="sm:order-first text-sm sm:text-md overflow-ellipsis overflow-hidden">
                        {{ value }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RiepilogueTableRow',
    props: {
        title: String, 
        value: String,
        isDark: {
            type: Boolean,
            default: false
        },
        isSmall: {
            type: Boolean,
            default: false
        },
        valueClass: {
            type: String,
            default: ''
        }
    }
}
</script>