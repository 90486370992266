<template>
  <div>
    <transition name="slide-top-blur">
      <div v-if="!isLoading">
        <div class="">
          <div class="mt-5 md:mt-0">
            <form @submit.prevent="submitForm" @reset.prevent="resetForm">
              <div class="shadow sm:rounded-md sm:overflow-hidden">
                <div class="px-4 py-5 flex flex-col bg-white space-y-6 sm:p-6">
                  <div class="grid grid-cols-4">
                    <div class="col-span-4 md:col-span-3 xl:col-span-2">
                      <label
                        for="subcategory"
                        class="block text-sm font-medium text-gray-700"
                        >Categoria</label
                      >
                      <select
                        id="subcategory"
                        name="subcategory"
                        required="required"
                        v-model="newTicket.subcategory"
                        autocomplete="subcategory"
                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 text-sm"
                      >
                        <option
                          v-for="category in subcategories"
                          :key="category.id"
                          :value="category.id"
                          :selected="category.id == newTicket.subcategory"
                        >
                          {{ category.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <!-- <div class="text-gray-400 text-sm ">
                  <p>
                    {{ categoryDescription }}
                  </p>
                </div> -->

                  <div>
                    <div
                      for="service"
                      class="flex flex-row items-center space-x-2"
                    >
                      <p class="text-sm font-medium text-gray-700">Servizio</p>
                      <svg
                        content="Se vuoi puoi selezionare il servizio per la quale stai richiedendo supporto"
                        v-tippy="{ placement: 'top', arrow: false }"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 text-gray-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div v-if="!serviceLock">
                      <div
                        v-for="(service, index) in services"
                        :key="index"
                        @click="newTicket.service = service.id"
                        class="flex flex-row items-center my-2 py-2 px-1 rounded cursor-pointer hover:shadow"
                      >
                        <input
                          :id="service.id"
                          name="service"
                          v-model="newTicket.service"
                          :value="service.id"
                          type="radio"
                          class="h-4 w-4 focus:ring-gray-600 text-gray-600"
                        />
                        <div class="flex-shrink">
                          <div
                            :for="service.id"
                            class="ml-3 text-md text-gray-600 flex flex-row items-center space-x-2"
                          >
                            <div class="flex-shrink flex justify-center">
                              <div
                                :class="{
                                  'bg-green-400 shadow-lg':
                                    newTicket.service == service.id,
                                  'bg-gray-300':
                                    newTicket.service != service.id,
                                }"
                                class="rounded-md px-1.5 py-0.5 flex flex-row space-x-1 items-center min-w-max"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-4 w-4 text-gray-50"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    v-if="service.type == 'Voip'"
                                    d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
                                  />
                                  <path
                                    v-if="service.type == 'Internet'"
                                    fill-rule="evenodd"
                                    d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                                <p class="text-gray-50 text-xs">
                                  {{ service.item.category.name }}
                                </p>
                              </div>
                            </div>
                            <div class="flex-grow grid lg:grid-cols-2">
                              <div class="text-sm sm:text-base">
                                {{ service.item.name }}
                              </div>
                              <div class="hidden lg:block">
                                {{ service.details.number }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <p class="text-sm">
                        Servizio preselezionato:
                        <strong
                          >{{ getServiceNameFromId(serviceId) }} ({{
                            getServiceCategoryFromId(serviceId)
                          }})</strong
                        >
                      </p>
                    </div>
                  </div>

                  <div class="">
                    <div class="">
                      <label
                        for="subject"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Oggetto
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="subject"
                          required="required"
                          v-model.trim="newTicket.subject"
                          id="subject"
                          class="focus:ring-yellow-500 focus:border-yellow-500 flex-1 rounded-none rounded-md text-sm border-gray-300"
                          placeholder="Un breve riassunto del ticket"
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label
                      for="text"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Messaggio
                    </label>
                    <div class="mt-1">
                      <textarea
                        id="text"
                        name="text"
                        required="required"
                        v-model="newTicket.text"
                        rows="3"
                        class="shadow-sm focus:ring-yellow-500 focus:border-yellow-500 mt-1 block w-full text-sm border-gray-300 rounded-md"
                        placeholder="Descrivi il problema o la necessità che hai"
                      ></textarea>
                    </div>
                    <p class="mt-2 text-sm text-gray-500">
                      Descrizione della problematica che ti ha portato ad aprire
                      il ticket.
                    </p>
                  </div>
                </div>
                <div class="flex px-4 py-3 bg-gray-50 justify-between sm:px-6">
                  <base-button label="Reset" type="reset" :isLight="true">
                  </base-button>
                  <base-button
                    label="Nuovo ticket"
                    type="submit"
                    v-if="!submitted"
                  >
                  </base-button>
                  <base-spinner-button v-else></base-spinner-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </transition>
    <div v-if="isLoading" class="flex items-center gap-2">
      <base-spinner-mini></base-spinner-mini> Carico le categorie
    </div>
  </div>
</template>

<script>
const defaultSubcategory = 3;

export default {
  name: "NewTicketForm",
  props: {
    serviceLock: {
      type: Boolean,
      required: false,
      default: false,
    },
    serviceId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoading: true,
      submitted: false,
      newTicket: {
        subject: "",
        subcategory: defaultSubcategory,
        service: null,
        text: "",
      },
      returned: null,
    };
  },
  computed: {
    subcategories() {
      return this.$store.getters["tickets/subcategories"];
    },
    services() {
      return this.$store.getters["services/services"].filter((srv) => {
        return srv.subordinate == 0;
      });
    },
    categoryDescription() {
      return this.subcategories.find((sbc) => {
        return sbc.id == this.newTicket.subcategory;
      }).description;
    },
  },
  async mounted() {
    if (this.$store.getters["tickets/subcategories"].length == 0) {
      await this.loadSubcategories();
    }
    if (this.$store.getters["services/services"].length == 0) {
      await this.loadServices();
    }

    if (this.serviceLock) this.newTicket.service = this.serviceId;

    this.isLoading = false;
  },
  methods: {
    async loadSubcategories() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("tickets/getSubcategories");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
    async loadServices() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("services/getServices");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isLoading = false;
    },
    async submitForm() {
      this.submitted = true;
      try {
        this.returned = await this.$store.dispatch(
          "tickets/newTicket",
          this.newTicket
        );

        this.$router.replace("/support/tickets/" + this.returned.id);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    resetForm() {
      let srv = null;
      if (this.serviceLock) srv = this.serviceId;

      this.newTicket = {
        subject: "",
        subcategory: defaultSubcategory,
        service: srv,
        text: "",
      };
    },
    getServiceNameFromId(id) {
      return this.services.filter((srv) => {
        return srv.id == id;
      })[0].item.name;
    },
    getServiceCategoryFromId(id) {
      return this.services.filter((srv) => {
        return srv.id == id;
      })[0].item.category.name;
    },
  },
};
</script>

<style scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffffff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.slide-top-blur-enter-active {
  -webkit-animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-top-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-top-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
