<template>
    <simple-title title="Apri ticket"></simple-title>
    <new-ticket-form></new-ticket-form>
</template>

<script>
import NewTicketForm from "./../../../../components/forms/NewTicketForm";

export default {
    name: "NewTicket",
    components: { NewTicketForm },
    mounted() {
        this.setBreadcrumbs();
    },
    methods: {
        setBreadcrumbs() {
            this.$store.dispatch("main/setBreadcrumbs", [
                { title: "Supporto", link: "/support" },
                { title: "Nuovo ticket", link: "/support/tickets/new" },
            ]);
        }
    },
};
</script>