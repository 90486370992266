<template>
  <transition name="slide-top-blur-3">
    <div v-if="!isLoading">
      <action-title
        title="Carte di credito e di debito"
        :keepTheTitle="true"
        label="Aggiungi"
        destination="/settings/payment-methods/new-card"
        tooltip="Aggiungi nuova carta"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
      /></action-title>

      <div
        class="flex flex-col divide-y divide-gray-200 bg-gray-50 shadow-lg rounded-md px-4 py-1 mb-3"
      >
        <div
          v-for="(card, index) in userCards"
          :key="index"
          class="flex flex-col text-sm py-2"
        >
          <!-- uprow -->
          <div class="flex flex-grow">
            <div class="flex-grow flex space-x-3 items-center text-left">
              <div class="">
                <img
                  :src="'/assets/img/' + card.card.brand + '.png'"
                  class="w-10"
                  alt=""
                />
              </div>
              <div class="hidden sm:flex flex-grow space-x-12">
                <div>
                  <span class="capitalize font-bold">
                    {{ card.card.brand }}
                  </span>
                  •••• {{ card.card.last4 }}
                </div>
                <div>
                  <span>
                    {{ card.card.exp_month }} /
                    {{ card.card.exp_year }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="!isUpdating"
              class="flex-shrink flex items-center justify-end"
            >
              <div
                v-if="userCards.length < 2"
                content="Non eliminare puoi il metodo predefinito"
                v-tippy="{ placement: 'top', arrow: false }"
              >
                <i-trash classes="w-5 ml-4 text-gray-200"></i-trash>
              </div>
              <div
                v-else
                @click="deleteCard(card.id)"
                content="Rimuovi carta"
                v-tippy="{ placement: 'top', arrow: false }"
                class="cursor-pointer text-gray-500 hover:text-red-400"
              >
                <i-trash classes="w-5 ml-4"></i-trash>
              </div>
            </div>
            <div class="flex-shrink flex items-center" v-else>
              <base-spinner-mini></base-spinner-mini>
            </div>
          </div>

          <!-- downrow -->
          <div class="sm:hidden flex-grow">
            <div class="flex items-bottom justify-between">
              <div class="flex-1">
                <span class="capitalize font-bold"> {{ card.card.brand }} </span
                ><br />
                •••• {{ card.card.last4 }}
              </div>
              <div class="flex-1 flex items-end justify-end">
                <span>
                  {{ card.card.exp_month }} /
                  {{ card.card.exp_year }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="userCards.length == 0"
          class="flex text-sm py-2 text-md text-gray-400"
        >
          Non ci sono ancora carte registrate
        </div>
      </div>
    </div>
  </transition>
  <div v-if="isLoading" class="flex py-4">
    <base-spinner-mini></base-spinner-mini>
    <span class="ml-2 text-gray-500">
      Carico metodi di pagamento salvati
    </span>
  </div>
</template>

<script>
export default {
  name: "CardsList",
  data() {
    return {
      isLoading: true,
      isUpdating: true,
    };
  },
  watch: {
    async loadedClient() {
      await this.loadUserCards();
    },
  },
  computed: {
    userCards() {
      return this.$store.getters["settings/cards"];
    },
    loadedClient() {
      return this.$store.getters["user/loadedClient"];
    },
  },
  async mounted() {
    await this.loadUserCards();
  },
  methods: {
    async loadUserCards() {
      if (this.userCards && this.userCards.length == 0) {
        this.isLoading = true;
        this.isUpdating = true;
      } else {
        this.isLoading = false;
        this.isUpdating = true;
      }

      try {
        await this.$store.dispatch("settings/getCardsSaved");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      this.isLoading = false;
      this.isUpdating = false;
    },
    async setDefault(id) {
      this.isUpdating = true;
      try {
        await this.$store.dispatch("settings/setDefaultCard", {
          id: id,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isUpdating = false;
    },
    async deleteCard(id) {
      this.isUpdating = true;
      try {
        await this.$store.dispatch("settings/deleteCard", {
          id: id,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isUpdating = false;
    },
  },
};
</script>
