<template>
  <div>
    <simple-title title="In scadenza"></simple-title>

    <transition name="slide-top-blur-2">
      <div class="mt-5 md:mt-0" v-if="!isLoading">
        <div class="shadow sm:rounded-md sm:overflow-hidden">
          <div class="px-4 py-5 bg-white sm:p-6 flex flex-col">
            <!-- main service riepilogue -->
            <div class="flex flex-row items-center">
              <div class="hidden sm:block flex-shrink bg-gradient-to-r from-yellow-400 to-yellow-500 rounded-md mr-4">
                <i-globe classes="w-12 h-12 p-1 text-gray-900"></i-globe>
              </div>
              <div class="flex-grow flex flex-col justify-start">
                <div class="text-left text-gray-900">
                  <span class="uppercase font-bold text-md">
                    {{ service.item.name }}
                  </span>
                </div>
                <div class="text-gray-400 font-light">
                  {{ service.billing_frequency }} mesi -
                  {{ parseInvoiceNotes(service) }}
                </div>
              </div>
              <div class="flex-shrink text-gray-500 flex items-center">
                <span class="text-gray-900 text-lg">{{
        price(service.item.price)
      }}</span>
              </div>
            </div>

            <!-- additional services riepilogue -->
            <div class="flex mt-4" v-if="additional">
              <!-- <div
                                class="hidden sm:flex flex-shrink w-12 p-1 mr-4"
                            ></div> -->
              <div class="flex-grow flex flex-col justify-start text-left text-gray-500">
                <span class="uppercase font-bold text-md">
                  Servizi opzionali
                </span>

                <div class="flex text-sm py-1 justify-between" v-for="additionalService in additionalServices"
                  :key="additionalService.id">
                  <span class="uppercase">{{
        additionalService.item.name
      }}</span>
                  <span class="flex-none ml-3">{{
          price(additionalService.item.price)
        }}</span>
                </div>
              </div>
            </div>

            <!-- total riepilogue -->
            <div class="flex flex-col mt-8">
              <div v-show="service.discount != 0"
                class="flex-grow flex flex-row items-end border-t border-gray-400 pt-4">
                <div class="text-lg flex-grow text-gray-600 ">
                  Subtotale
                </div>
                <div class="uppercase text-lg text-gray-600 ">
                  {{ granSubTotal }}
                </div>
              </div>
              <div v-show="service.discount != 0" class="flex-grow flex flex-row items-end">
                <div class="text-lg flex-grow text-gray-600 ">
                  Sconto
                </div>
                <div class="uppercase text-lg text-gray-600 ">
                  {{ price(discount) }}
                </div>
              </div>
              <div :class="{ 'border-t border-gray-400': service.discount == 0 }"
                class="flex-grow flex flex-row items-end pt-4">
                <div class="text-xl flex-grow text-gray-700 font-bold">
                  Totale
                </div>
                <div class="uppercase text-xl text-gray-900 font-bold">
                  {{ granTotal }}
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 py-3 bg-gray-50 sm:px-6 flex">
            <!-- actions -->
            <div class="flex-grow flex justify-end">
              <base-button @click.prevent="addToCart()" label="Aggiungi al carrello"></base-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "InternetExpiring",
  props: ["id"],
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    service() {
      return this.$store.getters["services/loadedService"];
    },
    services() {
      return this.$store.getters["services/services"];
    },
    client() {
      return this.$store.getters["user/loadedClient"];
    },
    vat() {
      return this.$gf.getVATbyCode(this.client.vat_code)['multiplier']
    },
    additionalServices() {
      return this.$store.getters["services/services"].filter((el) => {
        return el.main_id == this.id;
      });
    },
    additional() {
      return this.$store.getters["services/services"].filter((el) => {
        return el.main_id == this.id;
      }).length > 0
        ? true
        : false;
    },
    subtotal() {
      let price = 0;

      // adding service price
      price += this.service.item.price * this.service.billing_frequency;

      // adding subservices price
      this.additionalServices.forEach((element) => {
        price += element.item.price * element.billing_frequency;
      });

      return price;
    },
    granSubTotal() {
      return this.price(this.subtotal);
    },
    discount() {
      let discount = 0;

      // adding service price
      if (this.service.discount != 0) {
        discount +=
          (this.service.item.price *
            this.service.billing_frequency *
            this.service.discount) /
          100;
      }

      // adding subservices price
      this.additionalServices.forEach((element) => {
        if (element.discount != 0) {
          discount +=
            (element.item.price *
              element.billing_frequency *
              element.discount) /
            100;
        }
      });

      return discount;
    },
    granTotal() {
      if (this.service.discount == 0) return this.price(this.subtotal);
      else {
        return this.price(
          parseFloat(this.subtotal) - parseFloat(this.discount)
        );
      }
    },
  },
  mounted() {
    this.setBreadcrumbs();
    this.isLoading = false;
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Servizi", link: "/services" },
        {
          title: this.service.item.category.name,
          link: "/services/internet/" + this.id,
        },
        {
          title: "In scadenza",
          link: "/services/internet/" + this.id + "/expiring",
        },
      ]);
    },
    price(price) {
      return (this.vat * price).toFixed(2) + " €";
    },
    parseInvoiceNotes(service) {
      let notes = service.invoice_notes;
      notes = notes.replace(
        "[DATINI]",
        moment(service.next_invoice_date).format("DD/MM/YYYY")
      );
      notes = notes.replace(
        "[DATFIN]",
        moment(service.next_invoice_date)
          .add(service.billing_frequency, "M")
          .subtract(1, "days")
          .format("DD/MM/YYYY")
      );
      notes = notes.replace("[DISCOUNT]", service.discount.toString());

      return notes;
    },
    addToCart() {
      try {
        this.$store.commit("payments/addToCart", {
          services: [this.service, ...this.additionalServices],
        });
        this.$router.replace("/payments/checkout");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
};
</script>
