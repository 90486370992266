<template>
    <div>
        <simple-title title="Apri ticket"></simple-title>
        <new-ticket-form :serviceLock="true" :serviceId="service.id"></new-ticket-form>
    </div>
</template>

<script>
import NewTicketForm from "./../../../../../components/forms/NewTicketForm";

export default {
    name: 'VoipTicket',
    components: { NewTicketForm },
    computed: {
        service() {
            return this.$store.getters["services/loadedService"];
        }
    },
    mounted() {
        this.setBreadcrumbs();
    },
    methods: {
        setBreadcrumbs() {
            this.$store.dispatch("main/setBreadcrumbs", [
                { title: "Servizi", link: "/services" },
                {
                    title: this.service.item.category.name,
                    link: "/services/voip/" + this.service.id,
                },
                {
                    title: "Apri Ticket",
                    link: "/services/voip/" + this.service.id + "/newTicket",
                },
            ]);
        }
    }
};
</script>