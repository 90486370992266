<template>
  <div>
    <teleport to="body">
      <div
        v-if="show"
        class="z-50 fixed top-0 left-0 bg-gray-700 bg-opacity-40 w-screen h-screen"
      ></div>
      <transition name="dialog">
        <dialog
          class="shadow-xl bg-gray-50 rounded-md backdrop-filter backdrop-blur-lg"
          open
          v-show="show"
        >
          <header
            class="z-50 bg-gray-600 text-gray-100 text-xl flex flex-row items-center space-x-2"
          >
            <slot name="header">
              <h2>Aggiungi carta</h2>
            </slot>
          </header>
          <new-card-form @back="show = false"></new-card-form>
        </dialog>
      </transition>
    </teleport>
  </div>

  <div class="px-4 py-5 sm:p-6 flex flex-col">
    <div class="flex flex-col">
      <div
        class="flex-grow flex justify-between items-start border-b border-gray-200 mb-5 pb-1"
      >
        <span class="text-gray-900 text-xl"> Metodo di pagamento </span>
        <div
          content="Aggiungi nuovo metodo di pagamento"
          v-tippy="{ placement: 'top', arrow: false }"
          @click="show = true"
          class="flex pb-4 items-center space-x-1 text-gray-400 hover:text-yellow-400 cursor-pointer"
        >
          <span class="uppercase text-sm pt-1">Aggiungi</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="space-y-4" v-if="!isLoading">
        <div
          v-for="(card, index) in userCards"
          :key="index"
          @click="selectMethod(card)"
          class="flex items-center text-sm hover:bg-gray-100 rounded-md cursor-pointer px-2"
        >
          <input
            id="push_everything"
            name="push_notifications"
            type="radio"
            :value="card.id"
            v-model="paymethod"
            @change="selectMethod(card)"
            class="focus:ring-white h-4 w-4 text-blue-600 border-gray-300 mr-4"
          />
          <div class="flex flex-grow">
            <div class="hidden sm:flex flex-grow sm:space-x-12 items-center">
              <div class="flex space-x-3 items-center text-left">
                <div class="">
                  <img
                    :src="'/assets/img/' + card.card.brand + '.png'"
                    class="w-10"
                    alt=""
                  />
                </div>
                <div class="flex flex-grow sm:space-x-12">
                  <div>
                    <span class="capitalize font-bold">
                      {{ card.card.brand }}
                    </span>
                    <span class="text-gray-400">
                      termina con
                      {{ card.card.last4 }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex flex-grow justify-start">
                <span>
                  {{ card.billing_details.name }}
                </span>
              </div>
              <div class="flex justify-end">
                <span>
                  {{ card.card.exp_month }} /
                  {{ card.card.exp_year }}
                </span>
              </div>
            </div>

            <!-- mobile management  -->
            <div class="sm:hidden flex-grow">
              <div class="flex items-center justify-between">
                <div class="flex space-x-2">
                  <div>
                    <img
                      :src="'/assets/img/' + card.card.brand + '.png'"
                      class="w-10"
                      alt=""
                    />
                  </div>
                  <div class="flex-1">
                    <span class="capitalize font-bold">
                      {{ card.card.brand }} </span
                    ><br />
                    •••• {{ card.card.last4 }}
                  </div>
                </div>

                <div class="flex-1 flex items-end justify-end">
                  <span>
                    {{ card.card.exp_month }} /
                    {{ card.card.exp_year }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <base-spinner-mini></base-spinner-mini>
      </div>
      <div
        v-if="userCards.length == 0 && !isLoading"
        class="text-sm py-2 text-md text-gray-400"
      >
        <span>
          Non ci sono ancora carte registrate. Prima di procedere al checkout è
          necessario
        </span>
        <span
          @click="show = true"
          class="text-indigo-300 hover:text-indigo-500 cursor-pointer"
        >
          aggiungere un metodo di pagamento
        </span>
        valido.
      </div>
    </div>
  </div>
</template>

<script>
import NewCardForm from "@/components/forms/NewCardForm";
export default {
  name: "PaymethodSelection",
  components: { NewCardForm },
  emits: ["changeMethod"],
  data() {
    return {
      isLoading: true,
      isUpdating: true,
      show: false,
      paymethod: null,
    };
  },
  computed: {
    userCards() {
      return this.$store.getters["settings/cards"];
    },
    cart() {
      return this.$store.getters["payments/cart"];
    },
    paymethodStored() {
      return this.$store.getters["payments/paymethod"];
    },
  },
  async mounted() {
    if (this.paymethodStored && this.paymethodStored.length > 0)
      this.paymethod = this.paymethodStored;

    await this.loadUserCards();

    if (!this.paymethodStored && this.userCards.length > 0)
      this.selectMethod(this.userCards[0]);
  },
  methods: {
    async loadUserCards() {
      if (this.userCards && this.userCards.length == 0) {
        this.isLoading = true;
        this.isUpdating = true;
      } else {
        this.isLoading = false;
        this.isUpdating = true;
      }

      try {
        await this.$store.dispatch("settings/getCardsSaved");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      this.isLoading = false;
      this.isUpdating = false;
    },
    selectMethod(card) {
      this.paymethod = card.id;
      this.$store.commit("payments/setPaymethod", { id: this.paymethod });
    },
  },
};
</script>

<style scoped>
dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

header {
  width: 100%;
  padding: 1rem;
}

header h2 {
  margin: 0;
}

section {
  padding: 1rem;
}

menu {
  padding: 1rem;
  margin: 0;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
