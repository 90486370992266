import TheHeader from './../../layout/TheHeader'
import TheFooter from './../../layout/TheFooter'
import BlankView from './../../views/misc/BlankView'
import BlankTitle from './../../views/misc/BlankTitle'
import BlankSecondaryTitle from './../../views/misc/BlankSecondaryTitle'

import SupportView from './../../views/support/SupportView'
import TicketsList from './../../views/support/modules/tickets/TicketsList'
import NewTicket from './../../views/support/modules/tickets/NewTicket'
import TheTicket from './../../views/support/modules/tickets/TheTicket'
import Guides from './../../views/support/modules/Guides'
import BaseFileUpload from './../../ui/BaseFileUpload'


// Support Titles
import TicketsListTitle from './../../views/support/pageTitles/TicketsListTitle'
import TicketsListSecondaryTitle from './../../views/support/pageTitles/TicketsListSecondaryTitle'
import NewTicketTitle from './../../views/support/pageTitles/NewTicketTitle'
import NewTicketSecondaryTitle from './../../views/support/pageTitles/NewTicketSecondaryTitle'
import GuidesTitle from './../../views/support/pageTitles/GuidesTitle'
import GuidesSecondaryTitle from './../../views/support/pageTitles/GuidesSecondaryTitle'
import TheTicketTitle from './../../views/support/pageTitles/TheTicketTitle'
import TheTicketSecondaryTitle from './../../views/support/pageTitles/TheTicketSecondaryTitle'

export default [
    {
        path: '/support',
        name: 'Support',
        components: {
            header: TheHeader,
            default: SupportView,
            footer: TheFooter
        },
        meta: { requiresAuth: true },
        children: [
            {
                path: '',
                name: 'support-default',
                redirect: '/support/tickets'
            },
            {
                path: 'tickets/new',
                name: 'new-ticket',
                components: {
                    pageTitle: NewTicketTitle,
                    secondaryTitle: NewTicketSecondaryTitle,
                    default: NewTicket
                }
            },
            {
                path: 'tickets',
                name: 'tickets',
                components: {
                    pageTitle: BlankTitle,
                    secondaryTitle: BlankSecondaryTitle,
                    default: BlankView
                },
                children: [
                    {
                        path: '',
                        components: {
                            pageTitle: TicketsListTitle,
                            secondaryTitle: TicketsListSecondaryTitle,
                            default: TicketsList
                        }
                    },
                    {
                        path: ':id',
                        props: true,
                        name: 'ticket',
                        components: {
                            pageTitle: TheTicketTitle,
                            secondaryTitle: TheTicketSecondaryTitle,
                            default: TheTicket
                        }
                    },
                    {
                        path: ':id/file-upload',
                        props: true,
                        name: 'message-file',
                        meta: {
                            model: 'message',
                            title: 'Invia immagine o documento'
                        },
                        components: {
                            pageTitle: TheTicketTitle,
                            secondaryTitle: TheTicketSecondaryTitle,
                            default: BaseFileUpload
                        }
                    }
                ]
            },
            {
                path: 'guides',
                name: 'guides',
                components: {
                    pageTitle: GuidesTitle,
                    secondaryTitle: GuidesSecondaryTitle,
                    default: Guides
                }
            }
        ]
    }
]