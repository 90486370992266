import { createRouter, createWebHistory } from 'vue-router';

import routeSectionAuth from './modules/auth.js'
import routeSectionServices from './modules/services.js'
import routeSectionPayments from './modules/payments.js'
import routeSectionSupport from './modules/support.js'
import routeSectionSettings from './modules/settings.js'
import routeSectionInformations from './modules/informations.js'
import routeSectionFiles from './modules/files.js'

import store from './../store/index.js';

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes: [
    { path: '/', redirect: '/services' },
    ...routeSectionAuth,            // login, forgot-password, reset-password
    ...routeSectionServices,        // services/*
    ...routeSectionPayments,        // payments/*
    ...routeSectionSupport,         // support/*
    ...routeSectionSettings,        // settings/*
    ...routeSectionInformations,    // informations/*
    ...routeSectionFiles,           // files/*
    {
      path: '/default',
      redirect: '/login'
    },
    { path: '/:notFound(.*)', redirect: '/services' }
  ],
});

router.beforeEach(function (to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next('/default');
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next('/default');
  } else {
    next();
  }
});

export default router;
