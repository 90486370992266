import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';
import json from './init.json';

export default {
  state: json,
  mutations,
  actions,
  getters
};