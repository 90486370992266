<template>
    <div class="pb-20">
        <div class="max-w-7xl mx-auto p-4 sm:px-6 lg:px-10">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "MainLayout",
};
</script>