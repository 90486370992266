export default {
  async getServices(context) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/services`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getServices', responseData);

    context.commit('setServices', responseData.services);
  },

  async getService(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/services/${payload.id}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getService ' + payload.id, responseData);

    context.commit('setLoadedService', responseData.service);
  },

  async getRechargeItems(context) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/items/recharge-voip`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getRechargeItems', responseData);

    context.commit('setRechargeItems', responseData.items);
  },

  async getCronoItems(context) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/items/purchase-crono`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getCronoItems', responseData);

    context.commit('setCronoItems', responseData.items);
  },

  async getCredit(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/services/voip/${payload.id}/get-credit`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getCredit', responseData);
    return responseData.credit;
    // context.commit('setRechargeItems', responseData.items);
  },

  async getTraffic(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/services/voip/${payload.id}/get-traffic`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      },
      body: JSON.stringify({
        year: payload.year,
        month: payload.month
      })
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getTraffic', responseData);
    return responseData.traffic;
    // context.commit('setRechargeItems', responseData.items);
  },

  async getCronoOrders(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/services/internet/${payload.id}/get-crono-orders`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getCronoOrders', responseData);
    return responseData.cronos;
    // context.commit('setRechargeItems', responseData.items);
  },
  
  async getVoipOrders(context, payload) {
    let url =
      `${process.env.VUE_APP_API_URL}/user/services/voip/${payload.id}/get-orders`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + context.rootGetters.token,
      }
    });

    const responseData = await response.json();

    if (responseData.status_code != '200') {
      const error = new Error(
        responseData.message || 'Failed to authenticate. Check your login data.'
      );
      throw error;
    }

    // console.log('getVoipOrders', responseData);
    return responseData.recharges;
    // context.commit('setRechargeItems', responseData.items);
  },
};
