<template>
  <div class="flex flex-col pr-8">
    <router-link :to="relativeRoute('riepilogue')">
      <pills-button
        class="mr-1 lg:mr-4 mt-1 mainButton min-w-max"
        title="Riepilogo del servizio"
      >
        <i-bookmark
          classes="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"
        ></i-bookmark>
      </pills-button>
    </router-link>

    <router-link :to="relativeRoute('expiring')" v-if="!isCrono">
      <pills-button
        class="mr-1 lg:mr-4 mt-3 mainButton min-w-max"
        title="In scadenza"
      >
        <i-clock classes="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"></i-clock>
      </pills-button>
    </router-link>

    <router-link :to="relativeRoute('purchase')" v-if="isCrono">
      <pills-button
        class="mr-1 lg:mr-4 mt-3 mainButton min-w-max"
        title="Acquista"
      >
        <i-shopping-bag
          classes="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"
        ></i-shopping-bag>
      </pills-button>
    </router-link>

    <router-link :to="relativeRoute('orders')" v-if="isCrono">
      <pills-button
        class="mr-1 lg:mr-4 mt-3 mainButton min-w-max"
        title="I tuoi ordini"
      >
        <i-collection
          classes="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"
        ></i-collection>
      </pills-button>
    </router-link>

    <router-link :to="relativeRoute('additional')" v-if="additional">
      <pills-button
        class="mr-1 lg:mr-4 mt-3 mainButton min-w-max"
        title="Servizi opzionali"
      >
        <i-color-swatch
          classes="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"
        ></i-color-swatch>
      </pills-button>
    </router-link>

    <router-link :to="relativeRoute('newTicket')">
      <pills-button
        class="mr-1 lg:mr-4 mt-3 mainButton min-w-max"
        title="Apri ticket"
      >
        <i-ticket classes="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"></i-ticket>
      </pills-button>
    </router-link>

    <router-link :to="relativeRoute('ticketList')">
      <pills-button
        class="mr-1 lg:mr-4 mt-3 mainButton min-w-max"
        title="Ticket"
      >
        <i-inbox classes="w-5 lg:w-6 mr-1 lg:mr-2 text-gray-700"></i-inbox>
      </pills-button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  props: {
    additional: {
      type: Boolean,
      default: false,
    },
    isCrono: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    relativeRoute(relative = "") {
      return relative;
    },
  },
};
</script>

<style scoped>
.router-link-active .mainButton {
  @apply bg-gray-200;
  @apply rounded-md;
  @apply shadow-md;
}

.mainButton:hover {
  @apply bg-opacity-10;
  @apply bg-gray-200;
  @apply rounded-md;
}
</style>
