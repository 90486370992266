<template>
  <div>
    <teleport to="body">
      <div
        v-if="show"
        class="z-50 fixed top-0 left-0 bg-gray-700 bg-opacity-40 w-screen h-screen"
      ></div>
      <transition name="dialog">
        <dialog
          class="shadow-xl bg-gray-50 rounded-md backdrop-filter backdrop-blur-lg"
          open
          v-show="show"
        >
          <header
            class="z-50 bg-yellow-600 text-gray-100 text-xl flex flex-row items-center space-x-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <slot name="header">
              <h2>{{ title }}</h2>
            </slot>
          </header>
          <section>
            <slot></slot>
          </section>
          <menu
            v-if="!fixed"
            :class="{
              'flex justify-between': isConfirmation,
              'flex justify-end': !isConfirmation,
            }"
          >
            <base-button
              v-if="isConfirmation"
              @click="cancel"
              :isLight="true"
              label="Annulla"
            ></base-button>
            <base-button @click="tryClose" :label="buttonLabel"> </base-button>
          </menu>
        </dialog>
      </transition>
    </teleport>
  </div>
</template>

<script>
import BaseButton from "@/ui/buttons/BaseButton";

export default {
  components: {
    BaseButton,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      buttonLabel: "Chiudi",
      isConfirmation: false,
    };
  },
  emits: ["close", "cancel"],
  mounted() {
    if (this.title == "Esci") {
      this.isConfirmation = !this.isConfirmation;
      this.buttonLabel = "Esci";
    }
    if (this.title == "Verifica indirizzo") {
      this.isConfirmation = !this.isConfirmation;
      this.buttonLabel = "Verifica";
    }
  },
  methods: {
    tryClose() {
      if (this.fixed || !this.show) {
        return;
      }
      this.$emit("close");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
dialog {
  position: fixed;
  top: 20vh;
  left: 5%;
  width: 90%;
  z-index: 100;
  border: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

header {
  width: 100%;
  padding: 1rem;
}

header h2 {
  margin: 0;
}

section {
  padding: 1rem;
}

menu {
  padding: 1rem;
  margin: 0;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
