<template>
  <div>
    <div v-if="hasTickets">
      <div>
        <div
          class="flex flex-col sm:flex-row justify-between mb-4 sm:items-center"
        >
          <h2 class="text-xl font-medium text-gray-900">
            Aperti / In lavorazione
            <span class="ml-2 text-gray-400">{{ openTickets.length }}</span>
          </h2>
          <base-refresh-string
            timePointer="tickets/updateDatetime"
            @refresh="loadTickets()"
            :isUpdating="isUpdating"
          ></base-refresh-string>
        </div>
        <div v-for="ticket in openTickets" :key="ticket.id" class="mb-8">
          <transition name="slide-top-blur-2">
            <div v-if="!isLoading">
              <base-ticket
                :isUpdating="isUpdating"
                :ticket="ticket"
                :subcategory="getSubcategory(ticket.subcategory_id)"
              ></base-ticket>
            </div>
          </transition>
        </div>
        <div
          v-if="openTickets.length == 0"
          class="bg-gray-100 pb-4 text-gray-500"
        >
          Nessun ticket aperto al momento
        </div>
      </div>

      <div class="mt-10" v-show="closedTickets.length > 0">
        <div class="flex justify-between mb-4">
          <h2 class="text-xl font-medium text-gray-900">
            Chiusi
            <span class="ml-2 text-gray-400">{{ closedTickets.length }}</span>
          </h2>
          <div
            v-if="!showClosed"
            @click="toggleClosed"
            class="flex items-center text-gray-600 cursor-pointer"
          >
            <span> Mostra </span>
            <i-chevron-down classes="w-6"></i-chevron-down>
          </div>
          <div
            v-else
            @click="toggleClosed"
            class="flex items-center text-gray-600 cursor-pointer"
          >
            <span> Nascondi </span>
            <i-chevron-up classes="w-6"></i-chevron-up>
          </div>
        </div>
        <div v-for="ticket in closedTickets" :key="ticket.id" class="mb-8">
          <transition name="slide-top-blur-2">
            <div v-if="!isLoading && showClosed">
              <base-ticket
                :isUpdating="isUpdating"
                :ticket="ticket"
                :subcategory="getSubcategory(ticket.subcategory_id)"
              ></base-ticket>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div v-else-if="isLoading">
      <skeleton-internet-service
        class="hidden md:block"
      ></skeleton-internet-service>
      <skeleton-internet-service-mobile
        class="md:hidden"
      ></skeleton-internet-service-mobile>
    </div>
    <div v-else>
      <div class="flex flex-col mx-auto">
        <div>
          <img
            src="/assets/undraw/empty.svg"
            class="md:w-1/2 h-auto mx-auto px-10"
            alt=""
          />
        </div>
        <div class="mx-auto p-4 md:p-10 flex flex-col space-y-4">
          <h1 class="text-gray-600 text-2xl md:text-3xl text-center">
            Non ci sono ticket aperti!
          </h1>
          <h1 class="text-gray-400 pext-lg md:text-xl text-center">
            Se hai un problema puoi aprire una segnalazione da qui
          </h1>
        </div>
        <router-link :to="newTicketRoute()" class="mx-auto pb-10 md:pb-20">
          <base-button label="Nuovo ticket" :isLight="false" :hasIcon="true">
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
              clip-rule="evenodd"
            />
          </base-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonInternetService from "@/ui/skeleton/services/SkeletonInternetService";
import SkeletonInternetServiceMobile from "@/ui/skeleton/services/SkeletonInternetServiceMobile";
import BaseTicket from "@/ui/tickets/BaseTicket";

export default {
  name: "ServiceTicketsList",
  components: {
    SkeletonInternetService,
    SkeletonInternetServiceMobile,
    BaseTicket,
  },
  data() {
    return {
      isLoading: true,
      isUpdating: true,
      showClosed: false,
    };
  },
  computed: {
    service() {
      return this.$store.getters["services/loadedService"];
    },
    tickets() {
      return this.$store.getters["tickets/tickets"];
    },
    openTickets() {
      if (this.tickets.open.length > 0) {
        return this.tickets.open.filter((tck) => {
          return tck.ticketable_id == this.service.id;
        });
      } else return [];
    },
    closedTickets() {
      if (this.tickets.closed.length > 0) {
        return this.tickets.closed.filter((tck) => {
          return tck.ticketable_id == this.service.id;
        });
      } else return [];
    },
    hasTickets() {
      return this.openTickets.length > 0 || this.closedTickets.length > 0;
    },
    subcategories() {
      return this.$store.getters["tickets/subcategories"];
    },
  },
  async mounted() {
    this.setBreadcrumbs();

    if (this.$store.getters["tickets/subcategories"].length == 0) {
      await this.loadSubcategories();
    }

    await this.loadTickets();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch("main/setBreadcrumbs", [
        { title: "Servizi", link: "/services" },
        {
          title: this.service.item.category.name,
          link:
            "/services/" +
            this.service.type.toLowerCase() +
            "/" +
            this.service.id,
        },
        {
          title: "Ticket",
          link:
            "/services/" +
            this.service.type.toLowerCase() +
            "/" +
            this.service.id +
            "/ticketList",
        },
      ]);
    },
    async loadTickets() {
      if (this.openTickets.length == 0 && this.closedTickets.length == 0) {
        this.isLoading = true;
        this.isUpdating = true;
      } else {
        this.isLoading = false;
        this.isUpdating = true;
      }

      try {
        await this.$store.dispatch("tickets/getTickets");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }

      this.isLoading = false;
      this.isUpdating = false;
    },
    async loadSubcategories() {
      this.isUpdating = true;
      try {
        await this.$store.dispatch("tickets/getSubcategories");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
      this.isUpdating = false;
    },
    getSubcategory(id) {
      return this.subcategories.find((category) => {
        return category.id == id;
      });
    },
    toggleClosed() {
      this.showClosed = !this.showClosed;
    },
    newTicketRoute() {
      return "newTicket";
    },
  },
};
</script>
