<template>
  <div
    :class="
      isFromUser(message)
        ? 'justify-end slide-left-blur-enter-active'
        : 'justify-start slide-right-blur-enter-active'
    "
    class="flex"
  >
    <div
      class="mt-2 md:mt-10 max-w-xl w-5/6 sm:w-3/4"
      @mouseenter="enterDate"
      @mouseleave="leaveDate"
    >
      <div
        :class="
          isFromUser(message)
            ? 'bg-white text-gray-900'
            : 'bg-indigo-500 text-white'
        "
        class="p-1 sm:p-2 mt-3 md:mt-0 grid shadow rounded-md"
      >
        <div class="grid">
          <div class="break-all flex flex-col text-base leading-5">
            <span
              :class="message.file_id && message.text != null ? 'mb-2' : ''"
            >
              {{ message.text }}
            </span>
            <div
              v-if="message.file_id"
              class="mb-4 border-l-4 border-gray-500 bg-opacity-25 bg-gray-100 hover:bg-gray-300 hover:bg-opacity-25 flex items-center rounded-r-lg text-sm md:text-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-10 text-gray-500 ml-2 mr-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              <div class="flex-grow flex flex-col md:flex-row">
                <span class="flex-grow">{{ message.file.filename }} </span>
                <div
                  class="flex text-blue-400 items-start mr-2 cursor-pointer"
                  @click="download(message.file)"
                  content="Scarica il documento sul tuo dispositivo"
                  v-tippy="{ placement: 'top', arrow: false }"
                >
                  <span> Scarica </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="w-5 ml-2"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center sm:items-end space-x-8 mt-4">
          <span class="text-xs whitespace-nowrap">
            <div>
              <div v-if="isHover">
                {{ messageDateFormatted(message) }}
              </div>
              <div v-else>
                {{ messageTimeFormatted(message) }}
              </div>
            </div>
          </span>
          <span
            v-if="!isFromUser(message)"
            class="flex-grow justify-end flex items-end text-md"
          >
            <div class="grid grid-cols-1 text-right text-xs sm:text-md">
              <div
                v-if="message.admin"
                class="text-gray-300 pb-1 whitespace-nowrap"
              >
                {{ message.admin.name }}
              </div>
            </div>
            <div class="flex p-1 justify-center overflow-hidden">
              <img
                class="inline-block h-4 sm:h-6 rounded-full ring-0 bg-transparent"
                src="/assets/logos/nibble.svg"
                alt=""
              />
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "BaseMessage",
  props: ["message"],
  data() {
    return {
      isHover: false,
    };
  },
  computed: {
    loadedClient() {
      return this.$store.getters["user/loadedClient"];
    },
  },
  methods: {
    isFromUser(message) {
      if (message.client_id == this.loadedClient.id) return true;
      else return false;
    },
    messageTimeFormatted(message) {
      return moment(message.created_at).format("DD MMM HH:mm");
    },
    messageDateFormatted(message) {
      return moment(message.created_at).format("DD/MM/YYYY HH:mm");
    },
    enterDate() {
      this.isHover = true;
    },
    leaveDate() {
      this.isHover = false;
    },
    async download(file) {
      try {
        await this.$store.dispatch("files/downloadFile", {
          file: file,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
};
</script>

<style scoped>
.slide-left-blur-enter-active {
  -webkit-animation: slide-left-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-left-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
.slide-right-blur-enter-active {
  -webkit-animation: slide-right-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-right-blur 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
@-webkit-keyframes slide-left-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px) scale(0.9);
    transform: translateX(50px) scale(0.9);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }
}
@keyframes slide-left-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px) scale(0.9);
    transform: translateX(50px) scale(0.9);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }
}

@-webkit-keyframes slide-right-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px) scale(0.9);
    transform: translateX(-50px) scale(0.9);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }
}
@keyframes slide-right-blur {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px) scale(0.9);
    transform: translateX(-50px) scale(0.9);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1);
  }
}
</style>
