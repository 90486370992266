export default {
    cards(state) {
        return state.cards;
    },
    subscriptions(state) {
        return state.subscriptions;
    },
    newCardSetupIntent(state) {
        return state.newCard.setupIntent;
    }
};