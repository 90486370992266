<template>
  <div>
    <div class="transition duration-500 ease-in-out bg-gray-900">
      <div class="max-w-7xl mx-auto transition duration-500 ease-in-out">
        <div class="z-10 lg:w-full transition duration-500 ease-in-out">
          <div class="hidden md:block relative p-4 sm:px-6 lg:px-8">
            <nav
              class="relative flex items-center justify-between"
              aria-label="Global"
            >
              <div class="flex-grow flex">
                <div class="flex items-center pr-3">
                  <div
                    class="flex items-center justify-between w-full md:w-auto"
                  >
                    <a href="#">
                      <span class="sr-only">Workflow</span>
                      <img
                        class="h-8 w-auto sm:h-10"
                        src="/assets/logos/nibble.svg"
                      />
                    </a>
                  </div>
                </div>
                <span
                  class="text-2xl font-bold text-gradient bg-gradient-to-r from-yellow-500 via-gray-400 to-gray-400"
                >
                  myNibble
                </span>
                <!-- <input type="text" placeholder="Cerca" class="p-2 px-4 w-96 rounded-md bg-gray-700 focus:bg-gray-300 text-gray-100 focus:text-gray-900 border border-gray-700"> -->
              </div>
              <div class="flex items-center space-x-3">
                <div class="flex items-center justify-between w-full md:w-auto">
                  <profile-button></profile-button>
                </div>
                <cart-button></cart-button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div
      style="background: linear-gradient(rgb(229, 231, 235), rgb(243, 244, 246), rgba(255, 255, 255, 0))"
      class="transition duration-500 ease-in-out overflow-hidden bg-opacity-90"
    >
      <div
        class="z-10 max-w-7xl mx-auto transition duration-500 ease-in-out md:my-2"
      >
        <div class="z-10 transition duration-500 ease-in-out">
          <mobile-menu></mobile-menu>
          <breadcrumbs :path="pagePath"></breadcrumbs>
          <desktop-menu></desktop-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs";

import MobileMenu from "../components/menu/MobileMenu";
import DesktopMenu from "../components/menu/DesktopMenu";
import ProfileButton from "../ui/buttons/ProfileButton";
import CartButton from "../ui/buttons/CartButton";

export default {
  name: "TheHeader",
  components: {
    DesktopMenu,
    MobileMenu,
    ProfileButton,
    CartButton,
    Breadcrumbs,
  },
  computed: {
    pagePath() {
      return this.$store.getters["main/breadcrumbs"] || [];
    },
    cart() {
      return this.$store.getters["payments/cart"] || [];
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch("logout");
      this.$router.replace("/login");
    },
  },
};
</script>

<style scoped>
@layer utilities {
  .text-gradient {
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
