<template>
    <simple-title :title="$route.meta.title"></simple-title>
    <upload-file-form :model="$route.meta.model" :id="id"></upload-file-form>
</template>

<script>
import UploadFileForm from "./../components/forms/UploadFileForm";
export default {
    name: "BaseFileUpload",
    props: ["id"],
    components: {
        UploadFileForm,
    },
};
</script>