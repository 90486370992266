// UI Icons

/* Filled */
import User from './components/filled/User'
import Plus from './components/filled/Plus'
import Additional from './components/filled/Additional'
import Filter from './components/filled/Filter'
import Globe from './components/filled/Globe'
import Phone from './components/filled/Phone'
import Pencil from './components/filled/Pencil'
import Trash from './components/filled/Trash'
import Check from './components/filled/Check'
import Times from './components/filled/Times'
import Clock from './components/filled/Clock'
import Refresh from './components/filled/Refresh'
import Calendar from './components/filled/Calendar'
import Cash from './components/filled/Cash'
import Collection from './components/filled/Collection'
import Ticket from './components/filled/Ticket'
import Photograph from './components/filled/Photograph'
import Mail from './components/filled/Mail'
import Cube from './components/filled/Cube'
import Code from './components/filled/Code'
import Router from './components/filled/Router'
import Map from './components/filled/Map'
import Search from './components/filled/Search'
import Cart from './components/filled/Cart'
import Bookmark from './components/filled/Bookmark'
import Inbox from './components/filled/Inbox'
import Bell from './components/filled/Bell'
import Key from './components/filled/Key'
import Folder from './components/filled/Folder'
import Link from './components/filled/Link'
import Identification from './components/filled/Identification'
import ClipboardList from './components/filled/ClipboardList'
import ClipboardCheck from './components/filled/ClipboardCheck'
import UserGroup from './components/filled/UserGroup'
import ChevronUp from './components/filled/ChevronUp'
import ChevronDown from './components/filled/ChevronDown'
import ChevronRight from './components/filled/ChevronRight'
import ChevronLeft from './components/filled/ChevronLeft'
import ChevronDoubleRight from './components/filled/ChevronDoubleRight'
import ReceiptTax from './components/filled/ReceiptTax'
import ViewGridAdd from './components/filled/ViewGridAdd'
import LocationMarker from './components/filled/LocationMarker'
import CubeTransparent from './components/filled/CubeTransparent'
import ExternalLink from './components/filled/ExternalLink'
import ColorSwatch from './components/filled/ColorSwatch'
import StatusOnline from './components/filled/StatusOnline'
import StatusOffline from './components/filled/StatusOffline'
import ShieldCheck from './components/filled/ShieldCheck'
import CreditCard from './components/filled/CreditCard'
import ShoppingBag from './components/filled/ShoppingBag'
import PaperAirplane from './components/filled/PaperAirplane'
import ArrowNarrowRight from './components/filled/ArrowNarrowRight'
import ArrowDown from './components/filled/ArrowDown'
import CheckCircle from './components/filled/CheckCircle'
import PlusCircle from './components/filled/PlusCircle'

/* Outlined */
import ArrowCircleUp from './components/outlined/ArrowCircleUp'
import ArrowCircleDown from './components/outlined/ArrowCircleDown'
import CheckCircleOutlined from './components/outlined/CheckCircle'
import InformationCircle from './components/outlined/InformationCircle'
import MinusCircle from './components/outlined/MinusCircle'
import CalendarOutlined from './components/outlined/Calendar'
import ClockOutlined from './components/outlined/Clock'
import TicketOutlined from './components/outlined/Ticket'

/* Logos */
import Mastercard from './components/logos/Mastercard'
import Visa from './components/logos/Visa'
import AmericanExpress from './components/logos/AmericanExpress'
import Stripe from './components/logos/Stripe'

/* Animated */
import Menu2Times from './components/animated/Menu2Times'
import MiniSpinner from './components/animated/MiniSpinner'

const Icons = {
    install(Vue) {
        /* Filled */
        Vue.component('i-user', User);
        Vue.component('i-plus', Plus);
        Vue.component('i-additional', Additional);
        Vue.component('i-filter', Filter);
        Vue.component('i-globe', Globe);
        Vue.component('i-phone', Phone);
        Vue.component('i-pencil', Pencil);
        Vue.component('i-trash', Trash);
        Vue.component('i-check', Check);
        Vue.component('i-times', Times);
        Vue.component('i-clock', Clock);
        Vue.component('i-refresh', Refresh);
        Vue.component('i-calendar', Calendar);
        Vue.component('i-cash', Cash);
        Vue.component('i-collection', Collection);
        Vue.component('i-ticket', Ticket);
        Vue.component('i-photograph', Photograph);
        Vue.component('i-mail', Mail);
        Vue.component('i-cube', Cube);
        Vue.component('i-code', Code);
        Vue.component('i-router', Router);
        Vue.component('i-map', Map);
        Vue.component('i-search', Search);
        Vue.component('i-cart', Cart);
        Vue.component('i-bookmark', Bookmark);
        Vue.component('i-inbox', Inbox);
        Vue.component('i-bell', Bell);
        Vue.component('i-key', Key);
        Vue.component('i-folder', Folder);
        Vue.component('i-link', Link);
        Vue.component('i-identification', Identification);
        Vue.component('i-clipboard-list', ClipboardList);
        Vue.component('i-clipboard-check', ClipboardCheck);
        Vue.component('i-user-group', UserGroup);
        Vue.component('i-chevron-up', ChevronUp);
        Vue.component('i-chevron-down', ChevronDown);
        Vue.component('i-chevron-right', ChevronRight);
        Vue.component('i-chevron-left', ChevronLeft);
        Vue.component('i-chevron-double-right', ChevronDoubleRight);
        Vue.component('i-receipt-tax', ReceiptTax);
        Vue.component('i-view-grid-add', ViewGridAdd);
        Vue.component('i-location-marker', LocationMarker);
        Vue.component('i-cube-transparent', CubeTransparent);
        Vue.component('i-external-link', ExternalLink);
        Vue.component('i-color-swatch', ColorSwatch);
        Vue.component('i-status-online', StatusOnline);
        Vue.component('i-status-offline', StatusOffline);
        Vue.component('i-shield-check', ShieldCheck);
        Vue.component('i-credit-card', CreditCard);
        Vue.component('i-shopping-bag', ShoppingBag);
        Vue.component('i-paper-airplane', PaperAirplane);
        Vue.component('i-arrow-narrow-right', ArrowNarrowRight);
        Vue.component('i-arrow-down', ArrowDown);
        Vue.component('i-check-circle', CheckCircle);
        Vue.component('i-plus-circle', PlusCircle);

        /* Outlined */
        Vue.component('i-arrow-circle-up', ArrowCircleUp);
        Vue.component('i-arrow-circle-down', ArrowCircleDown);
        Vue.component('i-check-circle-o', CheckCircleOutlined);
        Vue.component('i-information-circle', InformationCircle);
        Vue.component('i-minus-circle', MinusCircle);
        Vue.component('i-calendar-o', CalendarOutlined);
        Vue.component('i-clock-o', ClockOutlined);
        Vue.component('i-ticket-o', TicketOutlined);

        /* Logos */
        Vue.component('i-card-mastercard', Mastercard);
        Vue.component('i-card-visa', Visa);
        Vue.component('i-card-american-express', AmericanExpress);
        Vue.component('i-stripe', Stripe);

        /* Animated */
        Vue.component('i-menu-to-times', Menu2Times);
        Vue.component('i-mini-spinner', MiniSpinner);
    }
};

export default Icons;
