<template>
    <transition name="slide-top-blur-2">
        <div v-if="!isLoading">
            <div class="grid">
                <div class="mt-5 md:mt-0">
                    <div class="shadow sm:rounded-md sm:overflow-hidden">
                        <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                            <riepilogue-table-row
                                class="text-md"
                                title="Attuale metodo di pagamento"
                                :value="paymethod"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                                    clip-rule="evenodd"
                                />
                            </riepilogue-table-row>
                            <div
                                class="flex w-full border-t border-gray-300"
                            ></div>
                            <riepilogue-table-row
                                class="mt-8 font-bold uppercase text-gray-500"
                                title="Altri metodi disponibili"
                            ></riepilogue-table-row>
                            <span class="text-gray-400 text-sm"
                                >Seleziona un metodo per proseguire</span
                            >
                            <div v-if="!isLoading && !isUpdating">
                                <div v-if="openRequest.length == 0">
                                    <div
                                        v-if="!isCC"
                                        class="flex items-top mt-4"
                                    >
                                        <div class="flex-shrink">
                                            <input
                                                :disabled="
                                                    openRequest.length > 0
                                                "
                                                :class="
                                                    openRequest.length > 0
                                                        ? 'text-transparent'
                                                        : 'text-gray-600'
                                                "
                                                id="credit_card"
                                                name="method"
                                                v-model="method"
                                                value="CC"
                                                type="radio"
                                                class="h-4 w-4 focus:ring-gray-600"
                                            />
                                        </div>
                                        <div class="flex-grow">
                                            <label
                                                for="credit_card"
                                                class="ml-3 block text-md font-bold text-gray-600"
                                            >
                                                Carta di credito
                                            </label>
                                            <div
                                                class="ml-3 block text-sm font-light text-gray-500 w-full"
                                            >
                                                <span
                                                    class="font-medium text-green-400"
                                                    >Modifica instantanea</span
                                                >
                                                •
                                                <span class=""
                                                    >Pagamenti automatici
                                                    disponibili</span
                                                >
                                                <br />
                                                <span class="text-xs"
                                                    >NOTA: Se in futuro vorrai
                                                    tornare indietro, ti basterà
                                                    fare richiesta direttamente
                                                    da questa pagina.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="!isBO"
                                        class="flex items-top my-4"
                                    >
                                        <div class="flex-shrink">
                                            <input
                                                :disabled="
                                                    openRequest.length > 0
                                                "
                                                :class="
                                                    openRequest.length > 0
                                                        ? 'text-transparent'
                                                        : 'text-gray-600'
                                                "
                                                id="bonifico"
                                                name="method"
                                                v-model="method"
                                                value="BO"
                                                type="radio"
                                                class="h-4 w-4 focus:ring-gray-600"
                                            />
                                        </div>

                                        <div class="flex-grow">
                                            <label
                                                for="bonifico"
                                                class="ml-3 block text-md font-bold text-gray-600"
                                            >
                                                Bonifico
                                            </label>
                                            <div
                                                class="ml-3 block text-sm font-light text-gray-500 w-full"
                                            >
                                                <span
                                                    class="font-medium text-yellow-400"
                                                    >3/4 gg lavorativi</span
                                                >
                                                <br />
                                                <span class="text-xs"
                                                    >NOTA: La richiesta di
                                                    passaggio a pagamento con
                                                    bonifico viene eseguita
                                                    entro 4 gg lavorativi.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex items-top my-4">
                                        <div class="flex-shrink">
                                            <input
                                                :disabled="
                                                    openRequest.length > 0
                                                "
                                                :class="
                                                    openRequest.length > 0
                                                        ? 'text-transparent'
                                                        : 'text-gray-600'
                                                "
                                                id="contanti"
                                                name="method"
                                                v-model="method"
                                                value="CO"
                                                type="radio"
                                                class="h-4 w-4 focus:ring-gray-600"
                                            />
                                        </div>
                                        <div class="flex-grow">
                                            <label
                                                for="contanti"
                                                class="ml-3 block text-md font-bold text-gray-600"
                                            >
                                                Contanti
                                            </label>
                                            <div
                                                class="ml-3 block text-sm font-light text-gray-500 w-full"
                                            >
                                                <span
                                                    class="font-medium text-yellow-400"
                                                    >3/4 gg lavorativi</span
                                                >
                                                •
                                                <span class=""
                                                    >Non sempre
                                                    disponibile</span
                                                >
                                                <br />
                                                <span class="text-xs"
                                                    >NOTA: Richiesta approvata
                                                    entro max 4 gg lavorativi.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="openRequest.length > 0"
                                    class="text-sm text-blue-600 border-l-4 border-blue-500 bg-blue-100 p-3"
                                >
                                    Hai già una
                                    <router-link
                                        class="text-blue-600 font-bold"
                                        :to="
                                            '/support/tickets/' +
                                            openRequest[0].id
                                        "
                                        >richiesta</router-link
                                    >
                                    in corso. <br />
                                    Per poter effettuare una nuova modifica del
                                    metodo di pagamento è necessario che non ci
                                    siano richieste pendenti. (Non devono
                                    esserci ticket aperti con categoria
                                    "Modifica metodo di pagamento") modifica in
                                    attesa di completamento.
                                    <router-link
                                        class="text-blue-600 font-bold"
                                        to="/support/tickets"
                                        >Clicca qui per verificare</router-link
                                    >
                                    .
                                </div>
                            </div>
                            <div v-else>
                                <base-spinner-mini></base-spinner-mini>
                            </div>
                        </div>
                        <div
                            class="flex px-4 py-3 bg-gray-50 justify-between sm:px-6"
                        >
                            <router-link to="/settings/payment-methods/">
                                <base-button
                                    label="Annulla"
                                    :isLight="true"
                                ></base-button>
                            </router-link>

                            <base-spinner-button
                                v-if="submitted"
                            ></base-spinner-button>
                            
                            <div
                                v-else-if="method == null"
                                class="py-1 sm:py-2 px-2 sm:px-4 text-right"
                            >
                                Seleziona un metodo
                            </div>

                            <base-button
                                v-else
                                label="Conferma"
                                @click="submitForm()"
                            ></base-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import RiepilogueTableRow from "./../../ui/services/RiepilogueTableRow";

export default {
    components: { RiepilogueTableRow },
    data() {
        return {
            isLoading: true,
            isUpdating: true,
            submitted: false,
            method: null,
            url: null,
        };
    },
    computed: {
        paymentMethod() {
            return this.$store.getters["user/paymethod"];
        },
        paymethod() {
            switch (this.paymentMethod) {
                case "AS":
                    return "Assegno";
                case "BO":
                    return "Bonifico bancario";
                case "CC":
                    return "Carta di Credito";
                case "CO":
                    return "Contanti";
                case "PAY":
                    return "PayPal";
                case "PP":
                    return "PayPal";
                case "RIDRD":
                    return "RID Data Fattura";
                case "RB30":
                    return "RIBA 30gg";
                default:
                    return "carico...";
            }
        },
        isCC() {
            switch (this.paymentMethod) {
                case "CC":
                case "PP":
                case "PAY":
                    return true;
                default:
                    return false;
            }
        },
        isBO() {
            return this.paymentMethod != "BO" ? false : true;
        },
        tickets() {
            return this.$store.getters["tickets/tickets"].open;
        },
        openRequest() {
            if (this.tickets && this.tickets.length > 0) {
                return this.tickets.filter((ticket) => {
                    return (
                        ticket.subcategory_id == "7" && ticket.status == "open"
                    );
                });
            } else {
                return [];
            }
        },
    },
    async mounted() {
        await this.loadPaymentMethod();
    },
    methods: {
        async loadPaymentMethod() {
            if (
                (this.paymentMethod && this.paymentMethod.length == 0) ||
                this.paymentMethod == undefined
            ) {
                this.isLoading = true;
                this.isUpdating = true;
            } else {
                this.isLoading = false;
                this.isUpdating = true;
            }

            try {
                await this.$store.dispatch("user/setClient");
                await this.$store.dispatch("tickets/getTickets");
            } catch (error) {
                this.error = error.message || "Something went wrong!";
            }

            this.isLoading = false;
            this.isUpdating = false;
        },
        async submitForm() {
            this.submitted = true;

            try {
                this.url = await this.$store.dispatch("user/editPayment", {
                    method: this.method,
                });
                this.$router.replace(this.url);
            } catch (error) {
                this.error = error.message || "Something went wrong!";
            }
        },
    },
};
</script>