<template>
    <div
        :class="keepTheTitle ? 'flex' : 'hidden md:flex'"
        class="justify-start md:justify-between items-start"
    >
        <h2 class="pb-4 md:pl-4 text-md text-gray-500 font-bold uppercase">
            {{ title }}
        </h2>
        <base-spinner-mini v-if="isUpdating"></base-spinner-mini>
    </div>
</template>

<script>
export default {
    name: "BaseSimpleTitle",
    props: {
        title: {
            type: String,
            default: "",
        },
        keepTheTitle: {
            type: Boolean,
            default: false,
        },
        isUpdating: {
            type: Boolean,
            default: false,
        },
    },
};
</script>