export default {
  setTickets(state, payload) {
    state.tickets = payload;
  },
  setSubcategories(state, payload) {
    state.subcategories = payload;
  },
  setLoadedTicket(state, payload) {
    state.loadedTicket = payload.ticket;
  },
  updateDatetime(state) {
    state.updateDatetime = new Date();
  },
};